import React, { useState } from "react";
import { FontAwesome } from "@expo/vector-icons";
import theme from "../../../theme";
import { OnboardingPage } from "../../../components/OnboardingPage";
import { RootStackParamList } from "../../../routes/auth.routes";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useOnboarding } from "../../../hooks/useOboarding";
import { useAuth } from "../../../hooks/useAuth";
import { LoadingPage } from "../../../components/LoadingPage";
type LoginScreenNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  "PasswordLoginScreen"
>;
type LoginScreenProps = {
  navigation: LoginScreenNavigationProp;
};
export const PasswordLoginScreen: React.FC<LoginScreenProps> = ({
  navigation,
}) => {
  const [password, setPassword] = useState("");
  const { loginEmail, loadingAuth, loginEmailError, setLoginEmailError } = useAuth();
  return (
    <>
      <OnboardingPage
        title="Agora digite sua senha"
        caption="Digite sua senha para acessar o app."
        headerButtonAction={() => {
          setLoginEmailError("");
          navigation.goBack();
        }}
        headerButtonIcon={
          <FontAwesome
            name="chevron-left"
            size={24}
            color={theme.palette.secondaryOrange}
          />
        }
        buttonAction={() => loginEmail(password)}
        textInputProps={{
          keyboardType: "visible-password",
          secureTextEntry: true,
        }}
        disabled={password.length < 8}
        buttonText="Entrar"
        value={password}
        onChangeText={(text) => setPassword(text)}
        alertText={loginEmailError}
      />
      <LoadingPage visible={loadingAuth} />
    </>
  );
};
