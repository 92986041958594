import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { AppRoutes } from "./app.routes";
import { useAuth } from "../hooks/useAuth";
import { AuthRoutes } from "./auth.routes";
import { LoadSplash } from "../screens/LoadSplash";

export function Routes() {
  const { user, splashLoading } = useAuth();
  if (!splashLoading) {
    return (
      <NavigationContainer>
        {user ? <AppRoutes /> : <AuthRoutes />}
      </NavigationContainer>
    );
  }

  return <LoadSplash />;
}
