import React from "react";

import { Svg, Path } from "react-native-svg";
interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
}
export const MoreIcon = (props: IconProps) => {
  return (
    <Svg
      width="6"
      height="25"
      viewBox="0 0 6 25"
      fill="none"
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.0979 22.506C5.0979 23.8852 3.98302 25.0001 2.60376 25.0001C1.2245 25.0001 0.109623 23.8852 0.109623 22.506C0.109623 21.1267 1.2245 19.9994 2.60376 19.9994C3.98302 19.9994 5.0979 21.1043 5.0979 22.481L5.0979 22.506ZM5.0979 12.5052C5.0979 13.8845 3.98302 14.9994 2.60376 14.9994C1.2245 14.9994 0.109623 13.8845 0.109623 12.5052C0.109623 11.126 1.2245 10.0011 2.60376 10.0011C3.98302 10.0011 5.0979 11.1035 5.0979 12.4828L5.0979 12.5052ZM5.0979 2.50673C5.0979 3.88599 3.98302 5.00087 2.60376 5.00087C1.2245 5.00087 0.109624 3.88599 0.109624 2.50673C0.109624 1.12747 1.2245 0.000121901 2.60376 0.000121961C3.98302 0.000122022 5.0979 1.10503 5.0979 2.48429L5.0979 2.50673Z"
        fill="white"
      />
    </Svg>
  );
};
