import { LinearGradient } from "expo-linear-gradient";
import React, { ReactNode, useRef } from "react";
import {
  KeyboardAvoidingView,
  SafeAreaView,
  TouchableOpacity,
  View,
  Text,
  TextInput,
  StyleSheet,
  Platform,
  Pressable,
  TextInputProps,
} from "react-native";
import theme from "../theme";
import { FontAwesome } from "@expo/vector-icons";
import { GradientButton } from "./GradientButton";
import { LoadingPage } from "./LoadingPage";

interface Props {
  title: string;
  caption: string;
  headerButtonAction: () => void;
  headerButtonIcon: ReactNode;
  inputElement?: ReactNode;
  buttonAction: () => void;
  buttonText: string;
  value?: string;
  onChangeText?: (text: string) => void;
  textInputProps?: TextInputProps;
  disabled?: boolean;
  alertText?: string;
  loading?: boolean;
}

export const OnboardingPage: React.FC<Props> = (props) => {
  const InputRef = useRef<TextInput>(null);
  return (
    <LinearGradient
      colors={[theme.palette.white, theme.palette.littleOrange]}
      style={styles.container}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 1 }}
    >
      <SafeAreaView style={styles.safeAreaContainer}>
        <KeyboardAvoidingView
          style={styles.keyboardAvoidingContainer}
          behavior={Platform.OS === "ios" ? "padding" : undefined}
        >
          <View style={styles.header}>
            <TouchableOpacity onPress={() => props.headerButtonAction()}>
              {props.headerButtonIcon}
            </TouchableOpacity>
          </View>
          <Pressable
            onPress={() => InputRef.current?.focus()}
            style={styles.body}
          >
            <Text style={styles.appTitle}>{props.title}</Text>
            <Text style={styles.caption}>{props.caption}</Text>
            <View style={styles.selectContainer}>
              {props.inputElement ? (
                props.inputElement
              ) : (
                <TextInput
                  returnKeyType="next"
                  onSubmitEditing={() =>
                    !props.disabled && props.buttonAction()
                  }
                  style={styles.input}
                  placeholderTextColor="rgba(0,0,0,0.2)"
                  onChangeText={(text) => {
                    props.onChangeText && props.onChangeText(text);
                  }}
                  value={props.value || ""}
                  onLayout={() => {
                    if (InputRef.current) {
                      {
                        InputRef.current?.focus();
                      }
                    }
                  }}
                  ref={InputRef}
                  {...props.textInputProps}
                />
              )}
            </View>
            {props.alertText && (
              <View style={styles.alertContainer}>
                <FontAwesome
                  name="exclamation-circle"
                  size={16}
                  color={theme.palette.secondaryOrange}
                />
                <Text style={styles.alertText}>{props.alertText}</Text>
              </View>
            )}
          </Pressable>
          <GradientButton
            disabled={props.disabled}
            buttonAction={() => props.buttonAction()}
            buttonText={props.buttonText}
          />
        </KeyboardAvoidingView>
        <LoadingPage visible={props.loading || false} />
      </SafeAreaView>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  safeAreaContainer: {
    flex: 1,
  },
  keyboardAvoidingContainer: {
    flex: 1,
    // justifyContent: "space-between",
  },
  header: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    marginTop: 20,
    paddingHorizontal: 20,
    ...Platform.select({
      android: {
        marginTop: 60,
      },
    }),
  },
  body: {
    paddingHorizontal: 40,
    flex: 1,
  },
  caption: {
    color: theme.palette.gray1,
    fontSize: 14,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15,
    ...Platform.select({
      android: {
        fontSize: 16,
      },
    }),
  },
  appTitle: {
    fontSize: 20,
    color: theme.light.colors.text,
    fontWeight: "bold",
    marginBottom: 10,
    marginTop: 24,
    ...Platform.select({
      android: {
        fontSize: 22,
      },
    }),
  },
  input: {
    height: 40,
    width: "100%",
    paddingHorizontal: 0,
    fontSize: 18,
    color: theme.palette.gray1,
  },
  selectContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: 10,
  },
  alertContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  alertText: {
    color: theme.palette.secondaryOrange,
    fontSize: 14,
    marginLeft: 5,
  },
});
