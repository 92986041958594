// api.js
import AsyncStorage from "@react-native-async-storage/async-storage";
import Axios, { AxiosRequestHeaders } from "axios";

const api = Axios.create({
  baseURL: "https://bepetappbff-production.up.railway.app/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Authorization",
    "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
    "Content-Type": "application/json;charset=UTF-8",
  },
});

api.interceptors.request.use(async (config) => {
  let token = "";
  try {
    const value = await AsyncStorage.getItem("@bepet-user-data");
    if (value !== null) {
      token = JSON.parse(value).accessToken;
      if (!config) {
        config = {
          headers: {} as AxiosRequestHeaders,
        };
      }
      if (!config.headers) {
        config.headers = {} as AxiosRequestHeaders;
      }
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (e) {
    // error reading value
    return config;
  }
});

// api.interceptors.response.use(
//   (response) => {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;

//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const access_token = await refreshToken(); // Add your refresh token function here

//       if (!access_token) {
//         logout(); // Add your logout function here
//         return Promise.reject(error);
//       }

//       api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
//       return api(originalRequest);
//     }

//     return Promise.reject(error);
//   }
// );


export default api;
