import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  SafeAreaView,
  TextInput,
  TouchableHighlight,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
import { FontAwesome } from "@expo/vector-icons";
import { useAuth } from "../../hooks/useAuth";
import theme from "../../theme";

export const LoginPhoneConfirmation = ({ navigation }) => {
  const { login } = useAuth();
  return (
    <LinearGradient
      colors={[theme.palette.white, theme.palette.littleOrange]}
      style={styles.container}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 1 }}
    >
      <SafeAreaView style={styles.safeAreaContainer}>
        <KeyboardAvoidingView
          style={styles.keyboardAvoidingContainer}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
          <View style={styles.header}>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <FontAwesome name="chevron-left" size={24} color={theme.palette.secondaryOrange} />
            </TouchableOpacity>
          </View>
          <View style={styles.body}>
            <Text style={styles.appTitle}>
              Digite o código recebido por SMS
            </Text>
            <Text style={styles.caption}>99 9999-9999</Text>
            <TextInput
              keyboardType="number-pad"
              style={styles.input}
              placeholder="000000"
              placeholderTextColor="rgba(0,0,0,0.3)"
              maxLength={6}
            />
          </View>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {}}
          >
            <LinearGradient
              colors={[theme.palette.secondaryOrange, theme.palette.primaryOrange]}
              style={styles.buttonGradient}
              start={{ x: 1, y: 1 }}
              end={{ x: 0, y: 0 }}
            >
              <Text style={styles.buttonText}>Avançar</Text>
            </LinearGradient>
          </TouchableOpacity>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  safeAreaContainer: {
    flex: 1,
  },
  keyboardAvoidingContainer: {
    flex: 1,
    // justifyContent: "space-between",
  },
  header: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    marginTop: 20,
    paddingHorizontal: 20,
    ...Platform.select({
      android: {
        marginTop: 60,
      },
    }),
  },
  body: {
    paddingHorizontal: 40,
    flex: 1,
  },
  caption: {
    color: theme.palette.gray1,
    fontSize: 14,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15,
    ...Platform.select({
      android: {
        fontSize: 16,
      },
    }),
  },
  appTitle: {
    fontSize: 20,
    color: theme.palette.black,
    fontWeight: "bold",
    marginBottom: 10,
    marginTop: 24,
    ...Platform.select({
      android: {
        fontSize: 22,
      },
    }),
  },
  input: {
    height: 40,
    width: "100%",
    paddingHorizontal: 0,
    fontSize: 30,
    color: theme.palette.black,
    justifyContent: "center",
    textAlign: "center",
    letterSpacing: 20,
  },
  button: {
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
    backgroundColor: "#71479d",
    shadowColor: "#000",
    marginHorizontal: 40,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 5,
    overflow: "hidden",
    marginBottom: 20,
  },
  buttonGradient: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
  },
});
