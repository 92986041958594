import React from "react";

import { Svg, Path, Mask, G } from "react-native-svg";
interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: number;
}
export const EditSquareIcon = (props: IconProps) => {
  return (
    <Svg
      width={props.size || 24}
      height={(24 / 24) * (props.size || 24)}
      fill="none"
      viewBox="0 0 24 24"
    >
      <G id="Iconly/Regular/Outline/Edit Square">
        <G id="Edit Square">
          <Path
            id="Fill 1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.577 22.3687H7.753C4.312 22.3687 2 19.9537 2 16.3597V8.04574C2 4.45174 4.312 2.03674 7.753 2.03674H11.492C11.906 2.03674 12.242 2.37274 12.242 2.78674C12.242 3.20074 11.906 3.53674 11.492 3.53674H7.753C5.169 3.53674 3.5 5.30674 3.5 8.04574V16.3597C3.5 19.0987 5.169 20.8687 7.753 20.8687H16.577C19.161 20.8687 20.831 19.0987 20.831 16.3597V12.3317C20.831 11.9177 21.167 11.5817 21.581 11.5817C21.995 11.5817 22.331 11.9177 22.331 12.3317V16.3597C22.331 19.9537 20.018 22.3687 16.577 22.3687Z"
            fill={"#222222" || props.color}
          />
          <Path
            id="Fill 3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.86762 15.4284H11.8446C12.2246 15.4284 12.5806 15.2814 12.8496 15.0124L20.3586 7.50337C20.6666 7.19537 20.8366 6.78537 20.8366 6.34937C20.8366 5.91237 20.6666 5.50137 20.3586 5.19337L19.1416 3.97637C18.5046 3.34137 17.4686 3.34137 16.8306 3.97637L9.35762 11.4494C9.09862 11.7084 8.95162 12.0524 8.94262 12.4174L8.86762 15.4284ZM11.8446 16.9284H8.09862C7.89662 16.9284 7.70262 16.8464 7.56162 16.7014C7.42062 16.5574 7.34362 16.3624 7.34862 16.1594L7.44262 12.3804C7.46162 11.6284 7.76462 10.9214 8.29662 10.3884H8.29762L15.7706 2.91537C16.9926 1.69537 18.9796 1.69537 20.2016 2.91537L21.4186 4.13237C22.0116 4.72437 22.3376 5.51137 22.3366 6.34937C22.3366 7.18737 22.0106 7.97337 21.4186 8.56437L13.9096 16.0734C13.3586 16.6244 12.6246 16.9284 11.8446 16.9284Z"
            fill={"#222222" || props.color}
          />
          <Path
            id="Fill 5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.7308 9.91673C19.5388 9.91673 19.3468 9.84373 19.2008 9.69673L14.6348 5.13073C14.3418 4.83773 14.3418 4.36273 14.6348 4.06973C14.9278 3.77673 15.4018 3.77673 15.6948 4.06973L20.2608 8.63673C20.5538 8.92973 20.5538 9.40373 20.2608 9.69673C20.1148 9.84373 19.9228 9.91673 19.7308 9.91673Z"
            fill={"#222222" || props.color}
          />
        </G>
      </G>
    </Svg>
  );
};
