import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  SafeAreaView,
  KeyboardAvoidingView,
  Platform,
  Dimensions,
  ImageBackground,
  ActivityIndicator,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import theme from "../../../theme";
import { BackIcon, CameraIcon, CatIcon, DogIcon } from "../../../assets/icons";
import * as ImagePicker from "expo-image-picker";
import { usePets } from "../../../hooks/usePets";
import api from "../../../services/api";

interface Props {
  navigation: any;
}
export const CreatePetPhotoOnboardScreen: React.FC<Props> = ({
  navigation,
}) => {
  const { pets, getPets } = usePets();
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled) {
      if (Platform.OS === "web") {
        setImage(result.uri);
      } else {
        setImage(result.assets[0].uri);
      }
    }
  };

  async function send() {
    const data = new FormData();
    if (Platform.OS === "web") {
      const response = await fetch(image);
      const blob = await response.blob();
      data.append("file", blob, image.split("/")[image.split("/").length - 1]);
    } else {
      data.append("file", {
        uri: image,
        name: image.split("/")[image.split("/").length - 1],
        type: "image/jpeg",
      });
    }
    setLoading(true);
    api
      .post(`/v1/pet/photo/upload/${pets[0]?.id}/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        getPets()
        navigation.navigate("AppStack");
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {}, []);

  return (
    <LinearGradient
      colors={[theme.palette.white, theme.palette.littleOrange]}
      style={styles.container}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 1 }}
    >
      <SafeAreaView style={styles.safeAreaContainer}>
        <KeyboardAvoidingView
          style={styles.keyboardAvoidingContainer}
          behavior={Platform.OS === "ios" ? "padding" : undefined}
        >
          <View style={styles.header}>
            <TouchableOpacity
              style={styles.buttonBack}
              onPress={() => navigation.navigate("AppStack" as never)}
            >
              <BackIcon size={10} />
              <Text style={styles.headerTextButton}>Registrar depois</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.body}>
            <Text style={styles.appTitle}>
              Que tal tirar uma foto bem linda do(a) {pets[0]?.name} agora ?
            </Text>
            <Text style={styles.caption}>
              Abra a camera ou selecione uma foto da galeria.
            </Text>
            <ImageBackground
              source={{ uri: image || undefined }}
              style={[styles.petSelectButton]}
            >
              {!image && (
                <DogIcon color={theme.palette.secondaryBlue} size={80} />
              )}
            </ImageBackground>
            <View style={styles.buttonContainer}>
              <TouchableOpacity style={styles.buttonCamera} onPress={pickImage}>
                <CameraIcon size={25} color={theme.palette.secondaryOrange} />
              </TouchableOpacity>
            </View>
          </View>

          <TouchableOpacity
            style={[
              styles.button,
              {
                opacity: !image ? 0.5 : 1,
              },
            ]}
            onPress={() => send()}
            disabled={loading || !image}
          >
            <LinearGradient
              colors={[
                theme.palette.secondaryOrange,
                theme.palette.primaryOrange,
              ]}
              style={styles.buttonGradient}
              start={{ x: 1, y: 1 }}
              end={{ x: 0, y: 0 }}
            >
              {loading ? (
                <ActivityIndicator color={theme.palette.white} />
              ) : (
                <Text style={styles.buttonText}>Salvar</Text>
              )}
            </LinearGradient>
          </TouchableOpacity>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  safeAreaContainer: {
    flex: 1,
  },
  keyboardAvoidingContainer: {
    flex: 1,
  },
  header: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    marginTop: 20,
    paddingHorizontal: 20,
    ...Platform.select({
      android: {
        marginTop: 60,
      },
    }),
  },
  buttonBack: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
  },
  headerTextButton: {
    color: theme.palette.gray2,
    fontSize: 16,
  },
  body: {
    paddingHorizontal: 40,
    flex: 1,
  },
  caption: {
    color: theme.palette.gray1,
    fontSize: 14,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15,
    ...Platform.select({
      android: {
        fontSize: 16,
      },
    }),
  },
  appTitle: {
    fontSize: 20,
    color: theme.light.colors.text,
    fontWeight: "bold",
    marginBottom: 10,
    marginTop: 24,
    ...Platform.select({
      android: {
        fontSize: 22,
      },
    }),
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30,
  },
  buttonCamera: {
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: theme.palette.white,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 20,
    shadowColor: theme.palette.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    elevation: 5,
  },
  button: {
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
    shadowColor: "#000",
    marginHorizontal: 40,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 5,
    overflow: "hidden",
    marginBottom: 20,
  },
  buttonGradient: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
  },
  selectContainer: {
    width: "100%",
    gap: 14,
  },
  petSelectButton: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
    gap: 10,
    borderRadius: 10,
    width: "100%",
    height: Dimensions.get("window").width - 80,
    backgroundColor: theme.palette.gray3,
    resizeMode: "cover",
    overflow: "hidden",
  },
  petSelectButtonText: {
    color: theme.palette.gray2,
    fontSize: 16,
    fontWeight: "600",
  },
});
