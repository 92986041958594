import { Picker } from "@react-native-picker/picker";
import React from "react";
import { Platform } from "react-native";
import { View, Text, StyleSheet, Modal, TouchableOpacity } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import theme from "../theme";
import { Keyboard } from "react-native";

interface SelectProps {
  itens: {
    label: string;
    value: string;
  }[];
  onChange?: (value: string) => void;
  defaultValue?: string;
}

export const Select: React.FC<SelectProps> = ({
  itens,
  onChange,
  defaultValue,
}) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [selected, setSelected] = React.useState(itens[0]?.value || "");
  const selectRef = React.useRef<TouchableOpacity>(null);
  function handleChange(value: string) {
    onChange && onChange(value);
  }
  React.useEffect(() => {
    setSelected(defaultValue || itens[0]?.value || "");
    defaultValue && handleChange(defaultValue);
  }, [defaultValue]);

  return (
    <>
      {Platform.OS === "ios" ? (
        <TouchableOpacity
          style={Platform.OS === "ios" ? styles.selectButton : {}}
          onPress={() => {
            setModalVisible(!modalVisible);
            Keyboard.dismiss();
          }}
        >
          <>
            <Text
              style={[
                styles.selectText,
                {
                  color:
                    selected.length > 0
                      ? theme.palette.black
                      : theme.palette.gray2,
                },
              ]}
            >
              {itens.find((item) => item.value === selected)?.label}
            </Text>
            <FontAwesome
              name="chevron-down"
              size={14}
              color={theme.palette.black}
            />
          </>
        </TouchableOpacity>
      ) : (
        <View style={styles.picker}>
          {Platform.OS === "android" && (
            <>
              <Text
                style={[
                  styles.selectText,
                  {
                    color:
                      selected.length > 0
                        ? theme.palette.black
                        : theme.palette.gray2,
                  },
                ]}
              >
                {itens.find((item) => item.value === selected)?.label}
              </Text>
              <FontAwesome
                name="chevron-down"
                size={14}
                color={theme.palette.black}
              />
            </>
          )}
          <Picker
            style={[
              styles.selectPicker,
              {
                color:
                  selected.length > 0
                    ? theme.palette.black
                    : theme.palette.gray2,
              },
            ]}
            selectedValue={selected}
            onFocus={() => {
              Keyboard.dismiss();
            }}
            onValueChange={(itemValue, itemIndex) => {
              handleChange(itemValue);
              setSelected(itemValue);
            }}
          >
            {itens.map((item) => (
              <Picker.Item
                key={item.value}
                label={item.label}
                value={item.value}
              />
            ))}
          </Picker>
        </View>
      )}

      {Platform.OS === "ios" && (
        <Modal
          transparent
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}
          animationType="fade"
        >
          <View style={styles.modalOverlay}>
            <TouchableOpacity
              style={styles.outside}
              onPress={() => {
                setModalVisible(!modalVisible);
              }}
            ></TouchableOpacity>
            <View style={styles.content}>
              <Picker
                selectedValue={selected}
                onValueChange={(itemValue, itemIndex) => {
                  handleChange(itemValue);
                  setSelected(itemValue);
                }}
              >
                {itens.map((item) => (
                  <Picker.Item
                    key={item.value}
                    label={item.label}
                    value={item.value}
                  />
                ))}
              </Picker>
            </View>
          </View>
        </Modal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.2)",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  content: {
    backgroundColor: "#fff",
    width: "100%",
  },
  outside: {
    flex: 1,
    width: "100%",
  },
  selectButton: {
    padding: 10,
    paddingLeft: 0,
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    flexDirection: "row",
  },
  selectText: {
    fontFamily: theme.fonts.nunito[500],
    fontSize: 16,
    marginRight: 10,
  },
  picker: {
    width: "100%",
    height: 40,
    backgroundColor: "transparent",
    borderWidth: 0,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectPicker: {
    // color: "#fff",
    backgroundColor: "transparent",
    fontSize: 16,
    marginRight: 10,
    borderWidth: 0,
    width: "100%",
    ...Platform.select({
      android: {
        opacity: 0,
        position: "absolute",
      },
    }),
  },
});
