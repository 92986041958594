import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  Modal,
  ScrollView,
  RefreshControl,
  ImageBackground,
} from "react-native";
import theme from "../../theme";
import { LinearGradient } from "expo-linear-gradient";
import Constants from "expo-constants";
import {
  BackIcon,
  DogIcon,
  EditSquareIcon,
  SwapIcon,
} from "../../assets/icons";
import React from "react";
import { useFocusEffect } from "@react-navigation/native";
import { usePets } from "../../hooks/usePets";
import { Pet } from "../../types";
import { FlatList } from "react-native";
import moment from "moment";

const CARD_SIZE = Dimensions.get("window").width - 40;
export const PetCard = ({ navigation }: any) => {
  const { pets, getPets } = usePets();
  const [selectedPet, setSelectedPet] = React.useState<Pet | null>(null);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);
  function onRefresh() {
    setRefreshing(true);
    getPets()
      .then((petsResponse) => {
        const petss = petsResponse as Pet[];
        if (petss.length === 0) {
          navigation.navigate("CreatePet");
        } else {
          setSelectedPet(petss[0]);
        }
      })
      .finally(() => setRefreshing(false));
  }

  useFocusEffect(
    React.useCallback(() => {
      onRefresh();
    }, [])
  );
  return (
    <LinearGradient
      colors={[theme.palette.littleOrange, theme.palette.white]}
      style={styles.container}
    >
      <View style={styles.header}>
        <TouchableOpacity
          style={styles.buttonBack}
          onPress={() => navigation.navigate("AppStack")}
        >
          <BackIcon size={10} />
          <Text style={styles.headerTextButton}>Voltar</Text>
        </TouchableOpacity>
        <Text style={styles.headerTitle}>Meu Pet</Text>
      </View>
      <ScrollView
        style={styles.content}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        <View style={styles.card}>
          <View style={styles.cardColum}>
            <Text style={styles.label}>Nome</Text>
            <Text style={styles.answer}>{selectedPet?.name}</Text>
            <Text style={styles.label}>Spécie</Text>
            <Text style={styles.answer}>
              {selectedPet?.breed_detail.specie.name === "Cachorro"
                ? "Canina"
                : "Felina"}
            </Text>
            <Text style={styles.label}>Raça</Text>
            <Text style={styles.answer}>{selectedPet?.breed_detail.name}</Text>
          </View>
          <View style={styles.cardColum}>
            <Text style={styles.label}>Cor</Text>
            <Text style={styles.answer}>{selectedPet?.color}</Text>
            <Text style={styles.label}>Sexo</Text>
            <Text style={styles.answer}>{selectedPet?.sex}</Text>
            <Text style={styles.label}>Nascimento</Text>
            <Text style={styles.answer}>
              {moment(selectedPet?.birth_date).format("DD/MM/YYYY")}
            </Text>
          </View>
          <View style={styles.cardColum}>
            <ImageBackground
              source={{ uri: selectedPet?.photo || undefined }}
              style={{
                width: (CARD_SIZE - 60)/3,
                height: (CARD_SIZE - 60)/3 * 1.2,
                borderRadius: 8,
                overflow: "hidden",
              }}
            />
          </View>
        </View>
        <View style={styles.buttons}>
          <TouchableOpacity
            style={styles.button}
            onPress={() => setModalVisible(true)}
          >
            <SwapIcon size={20} />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              navigation.navigate("UpdatePetScreen", {
                pet: selectedPet?.id,
              });
            }}
          >
            <EditSquareIcon size={20} />
          </TouchableOpacity>
        </View>
      </ScrollView>
      <Modal
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
        animationType="fade"
        transparent={true}
        visible={modalVisible}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "flex-end",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
        >
          <TouchableOpacity
            style={{
              flex: 1,
              width: "100%",
            }}
            onPress={() => setModalVisible(false)}
          ></TouchableOpacity>
          <View
            style={{
              backgroundColor: theme.palette.white,
              width: "100%",
              height: "50%",
              padding: 20,
            }}
          >
            <Text
              style={{
                color: theme.palette.black,
                fontSize: 16,
                fontFamily: theme.fonts.nunito[600],
                marginBottom: 20,
              }}
            >
              Selecione seu pet
            </Text>

            <FlatList
              data={pets}
              style={{ flex: 1, height: "100%" }}
              renderItem={({ item }) => (
                <TouchableOpacity
                  key={item.id}
                  onPress={() => setSelectedPet(item)}
                  style={{
                    borderRadius: 8,
                    borderColor: theme.palette.gray3,
                    borderWidth: 2,
                    padding: 20,
                    flex: 1,
                    flexDirection: "row",
                    marginBottom: 20,
                  }}
                >
                  <View>
                    <DogIcon size={32} color={theme.palette.secondaryBlue} />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      marginLeft: 20,
                    }}
                  >
                    <Text
                      style={{
                        color: theme.palette.black,
                        fontSize: 16,
                        fontFamily: theme.fonts.nunito[600],
                      }}
                    >
                      {item.name}
                    </Text>
                    <Text
                      style={{
                        color: theme.palette.black,
                        fontSize: 14,
                        opacity: 0.5,
                        fontFamily: theme.fonts.nunito[600],
                      }}
                    >
                      {item.breed_detail.name}
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
            />
          </View>
        </View>
      </Modal>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  keyboardAvoidingContainer: {
    flex: 1,
  },
  container: {
    flex: 1,
    backgroundColor: theme.palette.littleOrange,
  },
  headerSuccessButton: {
    color: theme.palette.secondaryOrange,
    fontSize: 16,
    fontFamily: theme.fonts.nunito[600],
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingTop: Constants.statusBarHeight + 10,
    paddingBottom: 20,
  },
  headerTitle: {
    color: theme.palette.black,
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: theme.fonts.asap["700Italic"],
  },
  buttonBack: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerTextButton: {
    color: theme.palette.black,
    fontSize: 14,
    marginLeft: 8,
    fontFamily: theme.fonts.nunito[500],
  },
  content: {
    flex: 1,
    paddingHorizontal: 20,
  },
  card: {
    backgroundColor: theme.palette.primaryOrange,
    borderRadius: 8,
    padding: 20,
    width: CARD_SIZE,
    height: CARD_SIZE / 1.7,
    flexDirection: "row",
    gap: 10,
  },
  cardColum: {
    flex: 1,
    width: (CARD_SIZE - 60)/3,
  },
  buttons: {
    flexDirection: "row",
    justifyContent: "center",
    gap: 20,
    alignItems: "center",
    marginTop: 20,
  },
  button: {
    backgroundColor: theme.palette.secondaryBlue,
    borderRadius: 20,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    color: theme.palette.white,
    fontSize: 16,
    fontFamily: theme.fonts.nunito[600],
  },
  label: {
    color: theme.palette.white,
    fontSize: 14,
    fontFamily: theme.fonts.asap["700Italic"],
    marginTop: 10,
  },
  answer: {
    color: theme.palette.white,
    fontSize: 16,
    fontFamily: theme.fonts.nunito[400],
  },
});
