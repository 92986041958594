import React from "react";

import { Svg, Path, Mask, G } from "react-native-svg";
interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: number;
}
export const SearchIcon = (props: IconProps) => {
  return (
    <Svg
      width={props.size || 20}
      height={props.size || 20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.40854 0C14.6001 0 18.8171 4.21612 18.8171 9.40655C18.8171 11.8512 17.878 14.0833 16.3365 15.7485L19.3664 18.76C19.6499 19.0434 19.6499 19.504 19.3664 19.7874C19.2246 19.9291 19.0474 20 18.8525 20C18.6753 20 18.4804 19.9291 18.3387 19.7874L15.2734 16.7405C13.661 18.0337 11.6234 18.7954 9.40854 18.7954C4.21701 18.7954 0 14.5793 0 9.38884C0 4.19841 4.21701 0 9.40854 0ZM9.40854 1.43489C5.01434 1.43489 1.45292 4.99557 1.45292 9.38884C1.45292 13.7821 5.01434 17.3428 9.40854 17.3428C13.785 17.3428 17.3642 13.7821 17.3642 9.38884C17.3642 4.99557 13.785 1.43489 9.40854 1.43489Z"
        fill={props.color || "#FF3800"}
      />
    </Svg>
  );
};
