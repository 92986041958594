import React from "react";

import { Svg, Path } from "react-native-svg";
interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: number;
}
export const UserIcon = (props: IconProps) => {
  return (
    <Svg
      width={props.size || 56}
      height={(69 / 56) * (props.size || 69)}
      viewBox="0 0 56 69"
      fill="none"
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.963 43.3985H28.4091H29.376C37.3335 43.4715 56 44.1279 56 56.2357C56 67.4683 40.68 68.9271 28.7066 69H26.6243C18.6668 68.9271 0.000258474 68.2706 0.000258474 56.1628C-0.0741104 44.7844 15.9152 43.3985 27.963 43.3985ZM27.963 48.6501C12.9404 48.6501 5.28043 51.203 5.28043 56.1628C5.28043 61.1956 12.9404 63.7484 27.963 63.7484C42.9855 63.7484 50.6455 61.1956 50.6455 56.2357C50.6455 51.203 43.0598 48.6501 27.963 48.6501ZM27.963 0C38.3746 0 46.8527 8.31501 46.8527 18.5264C46.8527 28.7378 38.3746 37.0528 27.963 37.0528H27.8886C22.8315 37.0528 18.1463 35.0835 14.5765 31.5824C11.0068 28.0814 9.07323 23.4133 9.07323 18.4535C9.07323 8.315 17.5513 0 27.963 0ZM27.963 5.17865C20.4517 5.17865 14.3534 11.1596 14.3534 18.5264C14.3534 22.1004 15.7665 25.3827 18.295 27.9355C20.8235 30.4154 24.2445 31.8742 27.8142 31.8742L27.8886 34.4271V31.8742C35.3999 31.8742 41.4981 25.8932 41.4981 18.5264C41.4981 11.1596 35.4742 5.17865 27.963 5.17865Z"
        fill={props.color || "#222222"}
      />
    </Svg>
  );
};
