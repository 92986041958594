import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  KeyboardAvoidingView,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  Image,
  RefreshControl,
} from "react-native";
import theme from "../../../theme";
import { LinearGradient } from "expo-linear-gradient";
import Constants from "expo-constants";
import { BackIcon, CameraIcon } from "../../../assets/icons";
import { ScrollView } from "react-native-gesture-handler";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/useAuth";
import { Select } from "../../../components/Select";
import { TextInputMask } from "react-native-masked-text";
import { useFocusEffect } from "@react-navigation/native";
import React from "react";
import * as ImagePicker from "expo-image-picker";
import { LoadingPage } from "../../../components/LoadingPage";
import { User } from "../../../types/user";

const masks = [
  {
    label: "BR +55",
    value: "99 99999-9999",
  },
  {
    label: "US +1",
    value: "999 999-9999",
  },
];

interface Props {
  navigation: any;
}

export const EditProfile: React.FC<Props> = ({ navigation }) => {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [cpf, setCpf] = useState("");
  const [mask, setMask] = useState(masks[0].value);
  const { logOut, getUser } = useAuth();
  const [refreshing, setRefreshing] = React.useState(false);
  function updateUser() {
    setRefreshing(true);
    getUser()
      .then((response) => {
        setUser(response);
        setUsername(response.username);
        setName(response.first_name + " " + response.last_name);
        setPhone(
          response?.phone?.split("+55")[1] || response?.phone?.split("+1")[1] || ""
        );
        setCpf(response?.tutor?.cpf as string);
        setImage(response?.tutor?.photo as string || "");
      })
      .finally(() => {
        setRefreshing(false);
      });
  }

  const [image, setImage] = useState("");

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    if (!result.canceled) {
      const data = new FormData();
      if (Platform.OS === "web") {
        const response = await fetch(result.uri);
        const blob = await response.blob();
        data.append(
          "file",
          blob,
          result.uri.split("/")[result.uri.split("/").length - 1]
        );
      } else {
        data.append("file", {
          uri: result.assets[0].uri,
          name: result.assets[0].uri.split("/")[
            result.assets[0].uri.split("/").length - 1
          ],
          type: "image/jpeg",
        });
      }
      setLoading(true);
      api
        .post("/v1/customer/photo/upload/", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          getUser();
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data.detail);
        })
        .finally(() => {
          setLoading(false);
        });
      setImage(result.assets[0].uri);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      updateUser();
    }, [])
  );

  function sendEditUser() {
    setLoading(true);
    api
      .patch("/v1/users/", {
        username: username,
        first_name: name.split(" ")[0],
        last_name: name.split(" ", 2)[1],
        phone: mask + phone,
        cpfCnpj: cpf,
      })
      .then((response) => {
        console.log(response.data);
        getUser();
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  
  return (
    <LinearGradient
      colors={[theme.palette.littleOrange, theme.palette.white]}
      style={styles.container}
    >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <KeyboardAvoidingView
          style={styles.keyboardAvoidingContainer}
          behavior={Platform.OS === "ios" ? "padding" : undefined}
        >
          <View style={styles.header}>
            <TouchableOpacity
              style={styles.buttonBack}
              onPress={() => navigation.navigate("AppStack")}
            >
              <BackIcon size={10} />
              <Text style={styles.headerTextButton}>Voltar</Text>
            </TouchableOpacity>
            <Text style={styles.headerTitle}>Editar perfil</Text>
            <TouchableOpacity
              onPress={() => {
                sendEditUser();
              }}
            >
              <Text style={styles.headerSuccessButton}>Feito</Text>
            </TouchableOpacity>
          </View>
          <ScrollView
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={updateUser} />
            }
          >
            <View style={styles.content}>
              <TouchableOpacity style={styles.photo} onPress={pickImage}>
                {image && (
                  <Image
                    style={styles.photoImage}
                    source={{
                      uri: image,
                    }}
                  />
                )}
                <View style={styles.photoIcon}>
                  <CameraIcon size={24} color={theme.palette.secondaryOrange} />
                </View>
              </TouchableOpacity>
              <View style={styles.fomControl}>
                <Text style={styles.label}>Email</Text>
                <TextInput
                  style={[styles.input, { color: theme.palette.gray1 }]}
                  value={user?.email}
                  editable={false}
                />
              </View>
              <View style={styles.fomControl}>
                <Text style={styles.label}>Username</Text>
                <TextInput
                  style={styles.input}
                  value={username}
                  onChangeText={(text) => setUsername(text)}
                  placeholder="Nome"
                />
              </View>
              <View style={styles.fomControl}>
                <Text style={styles.label}>Nome</Text>
                <TextInput
                  style={styles.input}
                  value={name}
                  onChangeText={(text) => setName(text)}
                  placeholder="Nome"
                />
              </View>
              <View style={styles.fomControl}>
                <Text style={styles.label}>Telefone</Text>
                <View style={styles.input}>
                  <View style={styles.selectInput}>
                    <Select
                      itens={masks}
                      onChange={(value) => {
                        setMask(value);
                        setPhone("");
                      }}
                    />
                  </View>
                  <TextInputMask
                    keyboardType="number-pad"
                    placeholder={mask}
                    placeholderTextColor="rgba(0,0,0,0.2)"
                    type={"custom"}
                    options={{
                      mask: mask,
                    }}
                    onChangeText={(text) => {
                      setPhone(text);
                    }}
                    value={phone}
                    // ref={inputRef}
                  />
                </View>
              </View>
              <View style={styles.fomControl}>
                <Text style={styles.label}>CPF</Text>
                <TextInputMask
                  keyboardType="number-pad"
                  type={"cpf"}
                  value={cpf}
                  onChangeText={(text) => setCpf(text)}
                  style={styles.input}
                  placeholder="000.000.000-00"
                />
              </View>
            </View>
          </ScrollView>
          <LoadingPage visible={loading} />
        </KeyboardAvoidingView>
      </TouchableWithoutFeedback>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  keyboardAvoidingContainer: {
    flex: 1,
  },
  selectInput: {
    width: 100,
  },
  container: {
    flex: 1,
    backgroundColor: theme.palette.littleOrange,
  },
  headerSuccessButton: {
    color: theme.palette.secondaryOrange,
    fontSize: 16,
    fontFamily: theme.fonts.nunito[600],
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingTop: Constants.statusBarHeight + 10,
    paddingBottom: 20,
  },
  headerTitle: {
    color: theme.palette.black,
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: theme.fonts.asap["700Italic"],
    marginRight: 20,
  },
  buttonBack: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerTextButton: {
    color: theme.palette.black,
    fontSize: 14,
    marginLeft: 8,
    fontFamily: theme.fonts.nunito[500],
  },
  photo: {
    width: 130,
    height: 130,
    borderRadius: 10,
    backgroundColor: theme.palette.gray3,
    marginBottom: 20,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    elevation: 5,
    position: "relative",
  },
  photoImage: {
    width: 130,
    height: 130,
    borderRadius: 10,
    resizeMode: "cover",
  },
  photoIcon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: theme.palette.white,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 5,
    right: 5,
  },
  content: {
    flex: 1,
    alignItems: "center",
  },
  fomControl: {
    width: "100%",
    marginBottom: 20,
  },
  label: {
    color: theme.palette.black,
    fontSize: 14,
    marginBottom: 8,
    fontFamily: theme.fonts.nunito[600],
    marginLeft: 20,
  },
  input: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    height: 50,
    backgroundColor: theme.palette.white,
    paddingHorizontal: 20,
    fontFamily: theme.fonts.nunito[600],
    borderBottomColor: theme.palette.gray3,
    borderBottomWidth: 1,
  },
});
