const theme = {
  palette: {
    black: "#222222",
    gray1: "#666666",
    gray2: "#B7B7B7",
    gray3: "#EAEAEA",
    white: "#ffffff",
    primaryOrange: "#FF6300",
    secondaryOrange: "#FF3800",
    littleOrange: "#FFF2E3",
    littleBlue: "#D7EFF6",
    primaryBlue: "#03E2FF",
    secondaryBlue: "#35BEE6",
    yellow: "#FFC700",
    green: "#3EC16B",

    backgroundGradient: "linear-gradient(180deg, #FFF2E3 0%, #FFFFFF 82.24%)",
    orangeGradient: "linear-gradient(180deg, #FF6300 0%, #FF3800 100%)",
    blueGradient: "linear-gradient(180deg, #03E2FF 0%, #35BEE6 100%)",
  },
  light: {
    colors: {
      Background: "#ffffff",
      text: "#222222",
      textUnfocused: "#666666",
      headerLabelSelected: "#35BEE6",
      headerLabelUnSelected: "#666666",
    },
  },
  fonts: {
    nunito: {
      800: "Nunito_800ExtraBold",
      400: "Nunito_400Regular",
      500: "Nunito_500Medium",
      600: "Nunito_600SemiBold",
      700: "Nunito_700Bold",
    },
    asap: {
      "700Italic": "Asap_700Bold_Italic",
      "700": "Asap_700Bold",
    },
  },
};

export default theme;
