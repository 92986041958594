import React from "react";
import { View, Text, TouchableOpacity, TouchableOpacityProps } from "react-native";
import { style } from "./SignInSocialButton.styles";

interface Props extends TouchableOpacityProps{
    title: string;
    svg?: React.ReactNode;
}

export function SignInSocialButton({title, svg:Svg, ...rest}:Props){
    return(
        <TouchableOpacity style={style.Button} {...rest}>
            <View style={style.ImageContainer}>
                {Svg && Svg}
            </View>
            <Text style={style.Text}>
                {title}
            </Text>
        </TouchableOpacity>
    )
}