import React from "react";

import { Svg, Path, Mask, G } from "react-native-svg";
interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: number;
}
export const SwapIcon = (props: IconProps) => {
  return (
    <Svg
      width={props.size || 20}
      height={(18 / 20) * (props.size || 18)}
      fill="none"
      viewBox="0 0 20 18"
    >
      <Path
        d="M14.8396 17.1643V3.54657"
        stroke={"#222222" || props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M18.9173 13.0682L14.8395 17.1649L10.7617 13.0682"
        stroke={"#222222" || props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M4.91115 0.833008V14.4508"
        stroke={"#222222" || props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M0.833496 4.92913L4.91127 0.832458L8.98905 4.92913"
        stroke={"#222222" || props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};
