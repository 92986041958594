import React from "react";

import { Svg, Path, Mask, G } from "react-native-svg";
interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: number;
}
export const BoxCheckedIcon = (props: IconProps) => {
  return (
    <Svg
      width={props.size || 24}
      height={(24 / 24) * (props.size || 24)}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Path
        id="Fill 1"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.665 3.50012C5.135 3.50012 3.5 5.23312 3.5 7.91612V16.0841C3.5 18.7671 5.135 20.5001 7.665 20.5001H16.333C18.864 20.5001 20.5 18.7671 20.5 16.0841V7.91612C20.5 5.23312 18.864 3.50012 16.334 3.50012H7.665ZM16.333 22.0001H7.665C4.276 22.0001 2 19.6221 2 16.0841V7.91612C2 4.37812 4.276 2.00012 7.665 2.00012H16.334C19.723 2.00012 22 4.37812 22 7.91612V16.0841C22 19.6221 19.723 22.0001 16.333 22.0001Z"
        fill={props.color || "#222222"}
      />
      <Path
        id="Fill 3"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8134 15.1231C10.6224 15.1231 10.4294 15.0501 10.2834 14.9031L7.90945 12.5301C7.61645 12.2371 7.61645 11.7631 7.90945 11.4701C8.20245 11.1771 8.67645 11.1771 8.96945 11.4701L10.8134 13.3121L15.0294 9.09707C15.3224 8.80407 15.7964 8.80407 16.0894 9.09707C16.3824 9.39007 16.3824 9.86407 16.0894 10.1571L11.3434 14.9031C11.1974 15.0501 11.0054 15.1231 10.8134 15.1231Z"
        fill={props.color || "#222222"}
      />
    </Svg>
  );
};
