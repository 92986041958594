import React, { useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  SafeAreaView,
  TextInput,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import theme from "../../../theme";
import { BackIcon, CatIcon, DogIcon } from "../../../assets/icons";
import { Select } from "../../../components/Select";
import { SelectDate } from "../../../components/SelectDate";
import { useNavigation } from "@react-navigation/native";
import { useAuth } from "../../../hooks/useAuth";
import api from "../../../services/api";
import { LoadingPage } from "../../../components/LoadingPage";
import moment from "moment";
import { usePets } from "../../../hooks/usePets";
import { useFocusEffect } from "@react-navigation/native";

const iconsMap = (selected: boolean) => ({
  Cachorro: (
    <DogIcon
      color={selected ? theme.palette.secondaryOrange : theme.palette.gray2}
      size={24}
    />
  ),
  Gato: (
    <CatIcon
      color={selected ? theme.palette.secondaryOrange : theme.palette.gray2}
      size={24}
    />
  ),
});

export const CreatePetOnboardScreen = () => {
  const navigation = useNavigation();
  const [petName, setPetName] = useState<string>();
  const [specie, setSpecie] = useState<string>();
  const [breed, setBreed] = useState<string>();
  const [sex, setSex] = useState<string>();
  const [color, setColor] = useState<string>();
  const [colorSelect, setColorSelect] = useState<string>();
  const [date, setDate] = useState<Date>();
  const [loading, setLoading] = useState<boolean>(false);
  const { user, logOut } = useAuth();
  const { getPets, fetchBreeds, fetchSpecies, breeds, species } = usePets();
  const textInputRef = useRef<TextInput>(null);
  function onLayout(e: any) {
    textInputRef.current?.focus();
  }

  function sendForm() {
    if (petName && breed && date && (color || colorSelect) && sex) {
      setLoading(true);
      api
        .post("v1/pet/", {
          name: petName,
          breed: breed,
          birth_date: moment(date).format("YYYY-MM-DD"),
          sex,
          color: colorSelect !== "Outra" ? colorSelect : color,
        })
        .then((response) => {
          getPets();
          navigation.navigate("CreatePetPhoto" as never);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.status === 401) {
            logOut();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      navigation.setOptions({
        swipeEnabled: false,
      });
      fetchSpecies().finally(() => {
        setLoading(false);
      });
    }, [])
  );
  return (
    <LinearGradient
      colors={[theme.palette.white, theme.palette.littleOrange]}
      style={styles.container}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 1 }}
    >
      <SafeAreaView style={styles.safeAreaContainer}>
        <KeyboardAvoidingView
          style={styles.keyboardAvoidingContainer}
          behavior={Platform.OS === "ios" ? "padding" : undefined}
        >
          <View style={styles.header}>
            <TouchableOpacity
              style={styles.buttonBack}
              onPress={() => navigation.navigate("AppStack" as never)}
            >
              <BackIcon size={10} />
              <Text style={styles.headerTextButton}>Registrar depois</Text>
            </TouchableOpacity>
          </View>
          <ScrollView style={styles.body}>
            <Text style={styles.appTitle}>
              Que legal{" "}
              {user &&
                user.first_name &&
                user?.first_name[0].toUpperCase() + user?.first_name.slice(1)}
              , agora vamos registrar seu Pet!
            </Text>
            <Text style={styles.caption}>Qual pet voce quer registrar?</Text>
            <View style={styles.petSelectContainer}>
              {species.map((item) => (
                <TouchableOpacity
                  key={item.id}
                  style={[
                    styles.petSelectButton,
                    {
                      borderColor:
                        specie === item.id
                          ? theme.palette.secondaryOrange
                          : theme.palette.gray2,
                    },
                  ]}
                  onPress={() => {
                    setSpecie(item.id);
                    fetchBreeds(item.id);
                  }}
                >
                  {
                    iconsMap(specie === item.id)[
                      item.name as "Cachorro" | "Gato"
                    ]
                  }
                  {/* <DogIcon color={theme.palette.secondaryOrange} size={24} /> */}
                  <Text
                    style={[
                      styles.petSelectButtonText,
                      {
                        color:
                          specie === item.id
                            ? theme.palette.secondaryOrange
                            : theme.palette.gray2,
                      },
                    ]}
                  >
                    {item.name}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
            {specie && (
              <View style={styles.selectContainer}>
                <TextInput
                  keyboardType="default"
                  placeholder="Qual nome do seu pet?"
                  style={styles.input}
                  placeholderTextColor={theme.palette.gray2}
                  onChangeText={(text) => {
                    setPetName(text);
                  }}
                  value={petName}
                  onLayout={onLayout}
                  ref={textInputRef}
                />
                <View style={styles.input}>
                  <SelectDate
                    label="Quando ele nasceu ?"
                    value={date}
                    onChange={(date) => setDate(date)}
                  />
                </View>
                <View style={styles.input}>
                  <Select
                    onChange={(value) => {
                      setBreed(value);
                    }}
                    itens={breeds.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                  />
                </View>
                <View style={styles.input}>
                  <Select
                    onChange={(value) => {
                      setSex(value);
                    }}
                    itens={[
                      { label: "Qual o sexo do seu pet ?", value: "" },
                      { label: "Macho", value: "M" },
                      { label: "Fêmea", value: "F" },
                    ]}
                  />
                </View>
                <View style={styles.input}>
                  <Select
                    onChange={(value) => {
                      setColorSelect(value);
                    }}
                    itens={[
                      { label: "Qual a cor  do seu pet ?", value: "" },
                      { label: "Preto", value: "Preto" },
                      { label: "Branco", value: "Branco" },
                      { label: "Marrom", value: "Marrom" },
                      { label: "Caramelo", value: "Caramelo" },
                      { label: "Dourado", value: "Dourado" },
                      { label: "Cinza", value: "Cinza" },
                      { label: "Preto e branco", value: "Preto e branco" },
                      { label: "Marrom e branco", value: "Marrom e branco" },
                      { label: "Cinza e branco", value: "Cinza e branco" },
                      { label: "Creme", value: "Creme" },
                      { label: "Fulvo", value: "Fulvo" },
                      { label: "Tigrado", value: "Tigrado" },
                      { label: "Azul", value: "Azul" },
                      { label: "Ruão", value: "Ruão" },
                      { label: "Champanhe", value: "Champanhe" },
                      { label: "Chocolate", value: "Chocolate" },
                      { label: "Outra", value: "Outra" },
                    ]}
                  />
                </View>
                {colorSelect === "Outra" && (
                  <TextInput
                    placeholder="Qual cor?"
                    style={styles.input}
                    placeholderTextColor={theme.palette.gray2}
                    onChangeText={(text) => {
                      setColor(text);
                    }}
                    value={color}
                  />
                )}
              </View>
            )}
          </ScrollView>
          <TouchableOpacity
            style={[
              styles.button,
              {
                opacity:
                  !specie || !petName || !colorSelect || !sex || !breed || !date
                    ? 0.2
                    : 1,
              },
            ]}
            onPress={sendForm}
            disabled={
              !specie || !petName || !colorSelect || !sex || !breed || !date
            }
          >
            <LinearGradient
              colors={[
                theme.palette.secondaryOrange,
                theme.palette.primaryOrange,
              ]}
              style={styles.buttonGradient}
              start={{ x: 1, y: 1 }}
              end={{ x: 0, y: 0 }}
            >
              <Text style={styles.buttonText}>Avançar</Text>
            </LinearGradient>
          </TouchableOpacity>
        </KeyboardAvoidingView>
      </SafeAreaView>
      <LoadingPage visible={loading} />
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  safeAreaContainer: {
    flex: 1,
  },
  keyboardAvoidingContainer: {
    flex: 1,
  },
  buttonBack: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
  },
  headerTextButton: {
    color: theme.palette.gray2,
    fontSize: 16,
  },
  header: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    marginTop: 20,
    paddingHorizontal: 20,
    ...Platform.select({
      android: {
        marginTop: 60,
      },
    }),
  },
  body: {
    paddingHorizontal: 40,
    flex: 1,
  },
  caption: {
    color: theme.palette.gray1,
    fontSize: 14,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15,
    ...Platform.select({
      android: {
        fontSize: 16,
      },
    }),
  },
  appTitle: {
    fontSize: 20,
    color: theme.light.colors.text,
    fontWeight: "bold",
    marginBottom: 10,
    marginTop: 24,
    ...Platform.select({
      android: {
        fontSize: 22,
      },
    }),
  },
  input: {
    height: 40,
    width: "100%",
    paddingHorizontal: 16,
    fontSize: 16,
    color: theme.palette.gray1,
    backgroundColor: theme.palette.gray3,
    borderRadius: 10,
  },
  button: {
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
    shadowColor: "#000",
    marginHorizontal: 40,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 5,
    overflow: "hidden",
    marginBottom: 20,
  },
  buttonGradient: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
  },
  selectContainer: {
    width: "100%",
    gap: 14,
  },
  petSelectContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gap: 10,
    marginBottom: 20,
  },
  petSelectButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
    gap: 10,
    borderRadius: 10,
    width: "40%",
    borderColor: theme.palette.gray3,
    borderWidth: 1,
  },
  petSelectButtonText: {
    color: theme.palette.gray2,
    fontSize: 16,
    fontWeight: "600",
  },
});
