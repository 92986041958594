import { LinearGradient } from "expo-linear-gradient";
import {
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  StatusBar,
  Image,
  Platform,
  TouchableHighlight,
} from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import Constants from "expo-constants";
import theme from "../theme";
import { MoreIcon } from "../assets/icons/more";

interface Props {
  title: string;
  navigation: any;
}
// const LogoImage = require("../../assets/logo-white.png");
export const Header: React.FC<Props> = ({ title, navigation }) => {
  return (
    <SafeAreaView style={styles.container}>
      <LinearGradient
        // Background Linear Gradient
        colors={[theme.palette.secondaryOrange, theme.palette.primaryOrange]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
        style={styles.headerGradient}
      />
      <View style={styles.headerInfo}>
        <View>
          <TouchableOpacity
            onPress={() => {
              navigation.openDrawer();
            }}
            style={styles.rightHeader}
          >
            <MoreIcon />
          </TouchableOpacity>
        </View>
        {/* <Image source={LogoImage} style={styles.image} /> */}
        {/* <TouchableHighlight
          onPress={() => {
            navigation.openDrawer();
          }}
          underlayColor="#01408C"
          style={styles.rightHeader}
        >
          <>
            <View>
              <Text style={styles.textHeader}>Matheus Santos</Text>
              <Text style={styles.cpfHeader}>610.***.***-28</Text>
            </View>
            <View style={styles.userIcon}>
              <FontAwesome name="user-circle" size={31} color="#A0AEC0" />
            </View>
          </>
        </TouchableHighlight> */}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme.palette.primaryOrange,
    ...Platform.select({
      android: {
        paddingTop: StatusBar.currentHeight,
      },
    }),
  },
  backButton: {
    position: "absolute",
    left: 0,
    paddingLeft: 20,
    height: 60,
    justifyContent: "center",
  },
  title: {},
  headerGradient: {
    position: "absolute",
    left: 0,
    right: 0,
    top: Constants.statusBarHeight,
  },
  image: {
    width: 71,
    height: 48,
    position: "relative",
  },
  headerInfo: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    paddingHorizontal: 18,
    paddingVertical: 10,
    paddingTop: 0,
  },
  userIcon: {
    borderColor: "#fff",
    backgroundColor: "#fff",
    borderRadius: 22.5,
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
  },
  rightHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    gap: 5,
    padding: 5,
    width: 40,
  },
  textHeader: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "500",
  },
  cpfHeader: {
    color: "#fff",
    fontSize: 12,
    fontWeight: "400",
  },
});
