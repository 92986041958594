import { StatusBar } from "expo-status-bar";
import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  ImageBackground,
  TouchableOpacity,
  Platform,
  Modal,
  Switch,
  TouchableHighlight,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Feather } from "@expo/vector-icons";
import Constants from "expo-constants";
import { Dimensions } from "react-native";
import theme from "../../theme";
import { CalendarIcon, LocationIcon, StarIcon } from "../../assets/icons";
import moment, { Moment } from "moment";
import { ButtonFooter } from "../../components/ButtonFooter";
import { ScreenFlowHeader } from "../../components/ScreenFlowHeader";
import { SelectablePreview } from "../../components/SelectablePreview";
import { useService } from "../../hooks/useService";
import { currency } from "../../utils";
interface Props {
  navigation: any;
}

export const ServiceResum: React.FC<Props> = ({ navigation }) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [selectedDate, setSelectedDate] = useState("");
  const daysInMonth = currentMonth.daysInMonth();
  const [isEnabled, setIsEnabled] = useState(false);
  const firstDayOfMonth = moment(currentMonth).startOf("month").format("d");
  const { selectedService, cancelService } = useService();
  return (
    <View style={styles.container}>
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
      <ScreenFlowHeader
        title="Resumo do serviço"
        buttonAction={() => navigation.goBack()}
      />
      <ScrollView style={styles.content}>
        <View style={styles.professionalContainer}>
          <View style={styles.professionalPhotoContainer}></View>
          <View style={styles.professionalInfoContainer}>
            <Text style={styles.professionalName}>
              {selectedService?.employee_detail[0].name}
            </Text>
            <Text style={styles.professionalJob}>
              {selectedService?.employee_detail[0].office}
            </Text>
          </View>
        </View>
        {selectedService && (
          <View style={styles.serviceInfoContainer}>
            <ImageBackground
              style={styles.serviceInfoPhoto}
              source={{
                uri: selectedService?.photo || undefined,
              }}
            ></ImageBackground>
            <View style={styles.serviceInfo}>
              <Text style={styles.serviceName}>{selectedService.name}</Text>
              <Text style={styles.servicePrice}>
                {currency(selectedService.prices[0]?.price || 0)}
              </Text>
            </View>
            <TouchableOpacity
              style={styles.serviceRemove}
              onPress={() => cancelService(navigation.navigate("Home"))}
            >
              <Feather
                name="trash"
                size={16}
                color={theme.palette.secondaryOrange}
              />
            </TouchableOpacity>
          </View>
        )}
        {/* <View style={styles.SwitchContainer}>
          <Switch
            trackColor={{
              false: theme.palette.gray2,
              true: theme.palette.primaryOrange,
            }}
            thumbColor={
              isEnabled ? theme.palette.littleOrange : theme.palette.gray3
            }
            ios_backgroundColor={theme.palette.gray2}
            onValueChange={(prev) => {
              setIsEnabled(prev);
            }}
            style={styles.switch}
            value={isEnabled}
          />
          <Text style={styles.switchText}>Táxi pet</Text>
        </View>
        {isEnabled && (
          <SelectablePreview
            title="Rua das Flores, 123"
            subText="Centro . apt 1206"
            icon={<LocationIcon />}
            headerText="Buscar pet em"
          />
        )} */}
      </ScrollView>
      <ButtonFooter
        buttonText="Continuar"
        buttonAction={() => navigation.navigate("ServicePayment")}
      >
        <View style={styles.totalContainer}>
          <Text style={styles.totalText}>Total</Text>
          <Text style={styles.totalPrice}>
            {currency(selectedService?.prices[0]?.price || 0)}
          </Text>
        </View>
      </ButtonFooter>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: theme.palette.littleOrange,
    position: "relative",
  },
  content: {
    paddingHorizontal: 14,
  },
  professionalContainer: {
    flexDirection: "row",
    paddingBottom: 10,
    marginBottom: 10,
  },
  professionalPhotoContainer: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: theme.palette.gray2,
    marginRight: 5,
  },
  professionalInfoContainer: {
    justifyContent: "center",
  },
  professionalName: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.black,
    fontFamily: theme.fonts.asap["700"],
  },
  professionalJob: {
    fontSize: 12,
    color: theme.palette.gray1,
    fontFamily: theme.fonts.nunito["400"],
  },
  serviceInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 10,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray3,
    paddingBottom: 20,
  },
  serviceInfoPhoto: {
    width: 50,
    height: 50,
    borderRadius: 12,
    backgroundColor: theme.palette.gray2,
    marginRight: 5,
  },
  serviceInfo: {
    flex: 1,
    height: "100%",
  },
  serviceName: {
    fontSize: 15,
    color: theme.palette.black,
    fontFamily: theme.fonts.nunito[400],
  },
  servicePrice: {
    fontSize: 13,
    color: theme.palette.black,
    fontWeight: "bold",
    fontFamily: theme.fonts.nunito[700],
  },
  serviceRemove: {
    width: 30,
    height: 30,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 5,
    shadowColor: theme.palette.black,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.12,
    shadowRadius: 4,
    elevation: 5,
    backgroundColor: theme.palette.white,
  },
  SwitchContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  switch: {
    ...Platform.select({
      ios: {
        transform: [{ scaleX: 0.7 }, { scaleY: 0.7 }],
      },
    }),
  },
  switchText: {
    fontSize: 14,
    color: theme.palette.black,
    fontFamily: theme.fonts.nunito[600],
  },
  adressContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  adressIcon: {
    width: 30,
    height: 30,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    backgroundColor: theme.palette.gray3,
  },
  adressInfo: {
    flex: 1,
  },
  adressTitle: {
    fontSize: 13,
    color: theme.palette.gray1,
    fontFamily: theme.fonts.nunito[600],
  },
  adressText: {
    fontSize: 15,
    color: theme.palette.black,
    fontFamily: theme.fonts.nunito[700],
    fontWeight: "bold",
  },
  adressComplement: {
    fontSize: 14,
    color: theme.palette.gray1,
    fontFamily: theme.fonts.nunito[400],
  },
  adressButton: {},
  adressButtonText: {
    fontSize: 14,
    color: theme.palette.secondaryOrange,
    fontFamily: theme.fonts.nunito[700],
    fontWeight: "bold",
  },
  totalContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  totalText: {
    fontSize: 14,
    color: theme.palette.gray1,
    fontFamily: theme.fonts.nunito[600],
  },
  totalPrice: {
    fontSize: 14,
    color: theme.palette.black,
    fontFamily: theme.fonts.nunito[700],
    fontWeight: "bold",
  },
});
