import React, { createContext, ReactNode, useContext, useState } from "react";
import { useAuth } from "./useAuth";
interface OnboardingProviderProps {
  children: ReactNode;
}

interface OnboardingContextData {
  sendName: (name: string, next: () => void) => void;
  sendEmail: (email: string, next: () => void) => void;
  sendPhone: (phone: string, next: () => void) => void;
  sendIndicatedCode: (indicatedCode: string, next: () => void) => void;
  sendPassword: (password: string, next: () => void) => void;
  name: string;
}

const OnboardingContext = createContext({} as OnboardingContextData);

function OnboardingProvider({ children }: OnboardingProviderProps) {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [indicatedCode, setIndicatedCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const {createAccount} =  useAuth()

  const sendName = (name: string, next: () => void) => {
    setName(name);
    next();
  };

  const sendEmail = (email: string, next: () => void) => {
    setEmail(email);
    next();
  };

  const sendPhone = (phone: string, next: () => void) => {
    setPhone(phone);
    next();
  };

  const sendIndicatedCode = (indicatedCode: string, next: () => void) => {
    setIndicatedCode(indicatedCode);
    next();
  };

  const sendPassword = (password: string, next: () => void) => {
    setPassword(password);
    createAccount({
      first_name: name.split(" ")[0] || "",
      last_name: name.split(" ", 2)[1] || "",
      email: email,
      phone: phone,
      password: password,
      code: indicatedCode,
    });
  };

  return (
    <OnboardingContext.Provider
      value={{
        sendName,
        sendEmail,
        sendPhone,
        sendIndicatedCode,
        sendPassword,
        name,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
}

function useOnboarding() {
  const context = useContext(OnboardingContext);
  return context;
}

export { OnboardingProvider, useOnboarding };
