import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  KeyboardAvoidingView,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  Image,
  RefreshControl,
} from "react-native";
import theme from "../../../theme";
import { LinearGradient } from "expo-linear-gradient";
import Constants from "expo-constants";
import { BackIcon, CameraIcon } from "../../../assets/icons";
import { ScrollView } from "react-native-gesture-handler";
import { useCallback, useState } from "react";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/useAuth";
import { Select } from "../../../components/Select";
import { useFocusEffect } from "@react-navigation/native";
import React from "react";
import * as ImagePicker from "expo-image-picker";
import { LoadingPage } from "../../../components/LoadingPage";
import { SelectDate } from "../../../components/SelectDate";
import { Pet } from "../../../types/pets";
import { usePets } from "../../../hooks/usePets";
import moment from "moment";
interface Props {
  navigation: any;
  route: any;
}

export const UpdatePetScreen: React.FC<Props> = ({ navigation, route }) => {
  const [pet, setPet] = useState<Pet>();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [color, setColor] = useState<string>();
  const [colorSelect, setColorSelect] = useState<string>();
  const { logOut, getUser } = useAuth();
  const [refreshing, setRefreshing] = React.useState(false);
  const [sex, setSex] = useState("");
  const [date, setDate] = useState<Date>();
  const [specie, setSpecie] = useState<string>();
  const [breed, setBreed] = useState<string>();
  const { species, fetchSpecies, fetchBreeds, breeds, fetchPetById } =
    usePets();

  function Onrefresh() {
    fetchSpecies();
    updatePet(route.params.pet);
  }

  function sendEditForm() {
    setLoading(true);
    api
      .patch(`v1/pets/${pet?.id}`, {
        name,
        sex,
        color: colorSelect === "Outra" ? color : colorSelect,
        birth_date: moment(date).format("YYYY-MM-DD"),
        breed_id: breed,
      })
      .then((response) => {
        console.log(response.data);
        navigation.goBack();
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.status === 401) {
          logOut();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const updatePet = useCallback((petId: string) => {
    setRefreshing(true);
    fetchPetById(petId)
      .then((response) => {
        setPet(response);
        setImage(response.photo);
        setName(response.name);
        setColor(response.color);
        setSex(response.sex);
        setColorSelect(response.color);
        setSpecie(response.breed_detail.specie.id);
        setDate(new Date(response.birth_date));
        setBreed(response.breed);
        fetchBreeds(response.breed_detail.specie.id);
      })
      .finally(() => {
        setRefreshing(false);
      });
  }, []);

  const [image, setImage] = useState("");

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    if (!result.canceled) {
      const data = new FormData();
      if (Platform.OS === "web") {
        const response = await fetch(result.uri);
        const blob = await response.blob();
        data.append(
          "file",
          blob,
          result.uri.split("/")[result.uri.split("/").length - 1]
        );
      } else {
        data.append("file", {
          uri: result.assets[0].uri,
          name: result.assets[0].uri.split("/")[
            result.assets[0].uri.split("/").length - 1
          ],
          type: "image/jpeg",
        });
      }
      setLoading(true);
      api
        .post(`/v1/pet/photo/upload/${pet?.id}/`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          updatePet(route.params.pet);
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data.detail);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      Onrefresh();
    }, [])
  );
  return (
    <LinearGradient
      colors={[theme.palette.littleOrange, theme.palette.white]}
      style={styles.container}
    >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <KeyboardAvoidingView
          style={styles.keyboardAvoidingContainer}
          behavior={Platform.OS === "ios" ? "padding" : undefined}
        >
          <View style={styles.header}>
            <TouchableOpacity
              style={styles.buttonBack}
              onPress={() => navigation.goBack()}
            >
              <BackIcon size={10} />
              <Text style={styles.headerTextButton}>Voltar</Text>
            </TouchableOpacity>
            <Text style={styles.headerTitle}>Editar Pet</Text>
            <TouchableOpacity onPress={() => sendEditForm()}>
              <Text style={styles.headerSuccessButton}>Feito</Text>
            </TouchableOpacity>
          </View>
          <ScrollView
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={Onrefresh} />
            }
          >
            <View style={styles.content}>
              <TouchableOpacity style={styles.photo} onPress={pickImage}>
                {image && (
                  <Image
                    style={styles.photoImage}
                    source={{
                      uri: image,
                    }}
                  />
                )}
                <View style={styles.photoIcon}>
                  <CameraIcon size={24} color={theme.palette.secondaryOrange} />
                </View>
              </TouchableOpacity>
              <View style={styles.fomControl}>
                <Text style={styles.label}></Text>
                <View style={styles.input}>
                  <Select
                    onChange={(value) => {
                      setSpecie(value);
                      fetchBreeds(value);
                    }}
                    defaultValue={specie}
                    itens={species.map((item) => ({
                      label:
                        item.name === "Cachorro" ? "🐶 Cachorro" : "🐱 Gato",
                      value: item.id,
                    }))}
                  />
                </View>
              </View>
              <View style={styles.fomControl}>
                <Text style={styles.label}>Nome</Text>
                <TextInput
                  keyboardType="default"
                  placeholder="Qual nome do seu pet?"
                  style={styles.input}
                  placeholderTextColor={theme.palette.gray2}
                  onChangeText={(text) => {
                    setName(text);
                  }}
                  value={name}
                  // onLayout={onLayout}
                  // ref={textInputRef}
                />
              </View>
              <View style={styles.fomControl}>
                <Text style={styles.label}>Raça</Text>
                <View style={styles.input}>
                  <Select
                    onChange={(value) => {
                      setBreed(value);
                    }}
                    defaultValue={breed}
                    itens={breeds.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                  />
                </View>
              </View>

              <View style={styles.fomControl}>
                <Text style={styles.label}>Data de nascimento</Text>
                <View style={styles.input}>
                  <SelectDate
                    label="Quando ele nasceu ?"
                    value={date}
                    onChange={(date) => setDate(date)}
                  />
                </View>
              </View>
              <View style={styles.fomControl}>
                <Text style={styles.label}>Sexo</Text>
                <View style={styles.input}>
                  <Select
                    onChange={(value) => {
                      setSex(value);
                    }}
                    defaultValue={sex}
                    itens={[
                      { label: "Qual o sexo do seu pet ?", value: "" },
                      { label: "Macho", value: "M" },
                      { label: "Fêmea", value: "F" },
                    ]}
                  />
                </View>
              </View>
              <View style={styles.fomControl}>
                <Text style={styles.label}>Cor</Text>
                <View style={styles.input}>
                  <Select
                    onChange={(value) => {
                      setColorSelect(value);
                    }}
                    defaultValue={colorSelect}
                    itens={[
                      { label: "Qual a cor  do seu pet ?", value: "" },
                      { label: "Preto", value: "Preto" },
                      { label: "Branco", value: "Branco" },
                      { label: "Marrom", value: "Marrom" },
                      { label: "Caramelo", value: "Caramelo" },
                      { label: "Dourado", value: "Dourado" },
                      { label: "Cinza", value: "Cinza" },
                      { label: "Preto e branco", value: "Preto e branco" },
                      { label: "Marrom e branco", value: "Marrom e branco" },
                      { label: "Cinza e branco", value: "Cinza e branco" },
                      { label: "Creme", value: "Creme" },
                      { label: "Fulvo", value: "Fulvo" },
                      { label: "Tigrado", value: "Tigrado" },
                      { label: "Azul", value: "Azul" },
                      { label: "Ruão", value: "Ruão" },
                      { label: "Champanhe", value: "Champanhe" },
                      { label: "Chocolate", value: "Chocolate" },
                      { label: "Outra", value: "Outra" },
                    ]}
                  />
                </View>
                {colorSelect === "Outra" && (
                  <TextInput
                    placeholder="Qual cor?"
                    style={styles.input}
                    placeholderTextColor={theme.palette.gray2}
                    onChangeText={(text) => {
                      setColor(text);
                    }}
                    value={color}
                  />
                )}
              </View>
            </View>
          </ScrollView>
          <LoadingPage visible={loading} />
        </KeyboardAvoidingView>
      </TouchableWithoutFeedback>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  keyboardAvoidingContainer: {
    flex: 1,
  },
  selectInput: {
    width: 100,
  },
  container: {
    flex: 1,
    backgroundColor: theme.palette.littleOrange,
  },
  headerSuccessButton: {
    color: theme.palette.secondaryOrange,
    fontSize: 16,
    fontFamily: theme.fonts.nunito[600],
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingTop: Constants.statusBarHeight + 10,
    paddingBottom: 20,
  },
  headerTitle: {
    color: theme.palette.black,
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: theme.fonts.asap["700Italic"],
    marginRight: 20,
  },
  buttonBack: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerTextButton: {
    color: theme.palette.black,
    fontSize: 14,
    marginLeft: 8,
    fontFamily: theme.fonts.nunito[500],
  },
  photo: {
    width: 130,
    height: 130,
    borderRadius: 10,
    backgroundColor: theme.palette.gray3,
    marginBottom: 20,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    elevation: 5,
    position: "relative",
  },
  photoImage: {
    width: 130,
    height: 130,
    borderRadius: 10,
    resizeMode: "cover",
  },
  photoIcon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: theme.palette.white,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 5,
    right: 5,
  },
  content: {
    flex: 1,
    alignItems: "center",
  },
  fomControl: {
    width: "100%",
    marginBottom: 20,
  },
  label: {
    color: theme.palette.black,
    fontSize: 14,
    marginBottom: 8,
    fontFamily: theme.fonts.nunito[600],
    marginLeft: 20,
  },
  input: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    height: 50,
    backgroundColor: theme.palette.white,
    paddingHorizontal: 20,
    fontFamily: theme.fonts.nunito[600],
    borderBottomColor: theme.palette.gray3,
    borderBottomWidth: 1,
  },
});
