import React from "react";

import { Svg, Path } from "react-native-svg";
interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: number;
}
export const CameraIcon = (props: IconProps) => {
  return (
    <Svg
      width={props.size || 20}
      height={(18 / 20) * (props.size || 18)}
      viewBox="0 0 20 18"
      fill="none"
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0402 1.05144C14.0502 1.45344 14.3592 2.85344 14.7722 3.30344C15.1852 3.75344 15.7762 3.90644 16.1032 3.90644C17.8412 3.90644 19.2502 5.31544 19.2502 7.05244V12.8474C19.2502 15.1774 17.3602 17.0674 15.0302 17.0674H4.97024C2.63924 17.0674 0.750244 15.1774 0.750244 12.8474V7.05244C0.750244 5.31544 2.15924 3.90644 3.89724 3.90644C4.22324 3.90644 4.81424 3.75344 5.22824 3.30344C5.64124 2.85344 5.94924 1.45344 6.95924 1.05144C7.97024 0.649439 12.0302 0.649439 13.0402 1.05144Z"
        stroke={props.color || "#222222"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M15.4955 6.50012H15.5045"
        stroke={props.color || "#222222"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1789 10.1282C13.1789 8.37216 11.7559 6.94916 9.99992 6.94916C8.24392 6.94916 6.82092 8.37216 6.82092 10.1282C6.82092 11.8842 8.24392 13.3072 9.99992 13.3072C11.7559 13.3072 13.1789 11.8842 13.1789 10.1282Z"
        stroke={props.color || "#222222"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};
