import { StatusBar } from "expo-status-bar";
import React, { useCallback } from "react";
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Modal,
  SafeAreaView,
  Image,
  TextInput,
  RefreshControl,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { SearchIcon } from "../../assets/icons";
import { ServicesIcon } from "../../assets/icons/services";
import { PageHeader } from "../../components/PageHeader";
import { ProductCard } from "../../components/ProductCard";
import theme from "../../theme";
import { useService } from "../../hooks/useService";
import { useFocusEffect } from "@react-navigation/native";
import { ScrollView } from "react-native-gesture-handler";
import { usePets } from "../../hooks/usePets";

function currency(value: number) {
  return value.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
}

interface Props {
  navigation: any;
}

export const Services: React.FC<Props> = ({ navigation }) => {
  const [filterModalIsVisible, setFilterModalIsVisible] = React.useState(false);
  const [filterSearch, setFilterSearch] = React.useState("");
  const [searchvalue, setSearchValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [filterTags, setFilterTags] = React.useState<string[]>([]);
  const { fetchServices, services, fetchService } =
    useService();
  const { pets } = usePets();
  function onclick(service: string) {
    fetchService(service, pets[0].breed_detail.size.id)
    navigation.navigate("ServiceStack", {
      screen: "ServiceDatail",
      params: { serviceId: service },
    });
  }
  const updateServices = useCallback(() => {
    if (pets[0].breed_detail.specie) {
      setLoading(true);
      fetchServices(
        pets[0].breed_detail.specie.id,
        pets[0].breed_detail.size.id
      ).finally(() => setLoading(false));
    }
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      updateServices();
    }, [])
  );
  return (
    <ScrollView
      refreshControl={
        <RefreshControl refreshing={loading} onRefresh={updateServices} />
      }
      style={styles.container}
      showsVerticalScrollIndicator={false}
    >
      <StatusBar style="auto" />
      <PageHeader
        icon={<ServicesIcon width={30} color={theme.palette.black} />}
        title="Serviços disponíveis"
      >
        <>
          <TouchableOpacity
            onPress={() => setFilterModalIsVisible(true)}
            style={styles.searchButton}
          >
            <Text>{searchvalue ? searchvalue : "Buscar/Filtrar"}</Text>
            <SearchIcon size={20} />
          </TouchableOpacity>
          <FlatList
            data={[{ id: "1", title: "Por localidade" }]}
            renderItem={({ item }) => (
              <View style={styles.filterTag}>
                <Text style={styles.filterTagText}>{item.title}</Text>
              </View>
            )}
            keyExtractor={(item) => item.id}
            horizontal
            style={{ marginTop: 16 }}
          />
        </>
      </PageHeader>
      <View style={styles.cardContainer}>
        {services.map((service) => (
          <ProductCard
            image={service?.photo || undefined}
            key={service.id}
            onClick={() => onclick(service.id)}
            title={service.name}
            logo={service?.company_detail?.photo || undefined}
            price={currency(service.prices[0]?.price || 0)}
          />
        ))}
      </View>

      <Modal
        visible={filterModalIsVisible}
        onRequestClose={() => setFilterModalIsVisible(false)}
        animationType="slide"
      >
        <View style={styles.filterModalView}>
          <Image
            source={require("../../assets/filterTopBg.png")}
            style={styles.filterTopBg}
          />
          <SafeAreaView>
            <View style={styles.filterHeaderModal}>
              <TouchableOpacity onPress={() => setFilterModalIsVisible(false)}>
                <Ionicons name="chevron-back" size={32} color="black" />
              </TouchableOpacity>

              <TextInput
                placeholder="Digite o termo para buscar"
                style={styles.SearchFilterInput}
                value={filterSearch}
                onChangeText={(text) => setFilterSearch(text)}
                keyboardType="default"
                autoCapitalize="none"
                autoCorrect={false}
                returnKeyType="search"
                onSubmitEditing={() => {
                  setSearchValue(filterSearch);
                  setFilterModalIsVisible(false);
                }}
              />
            </View>
          </SafeAreaView>
        </View>
      </Modal>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  filterModalView: {
    flex: 1,
    backgroundColor: theme.palette.littleOrange,
    position: "relative",
  },
  filterHeaderModal: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 24,
    paddingVertical: 16,
    paddingBottom: 0,
    gap: 16,
  },
  SearchFilterInput: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 20,
    paddingBottom: 18,
    borderRadius: 20,
    color: theme.palette.gray1,
    backgroundColor: theme.palette.white,
    fontFamily: theme.fonts.nunito[600],
    fontSize: 14,
    lineHeight: 19,
    borderColor: theme.palette.gray3,
    borderWidth: 1,
  },
  filterTopBg: {
    width: (311 / 132) * 150,
    height: 150,
    position: "absolute",
    resizeMode: "stretch",
    right: 0,
    top: 0,
  },
  container: {
    flex: 1,
    backgroundColor: theme.palette.primaryOrange,
  },
  cardContainer: {
    flex: 1,
    padding: 10,
    paddingTop: 30,
    marginTop: -20,
    zIndex: -1,
    flexDirection: "row",
    backgroundColor: theme.palette.white,
    flexWrap: "wrap",
    gap: 10,
    minHeight: 600,
  },
  filterTag: {
    paddingHorizontal: 10,
    paddingVertical: 6,
    borderRadius: 10,
    backgroundColor: theme.palette.secondaryBlue,
  },
  filterTagText: {
    fontSize: 14,
    fontFamily: theme.fonts.nunito[600],
    color: theme.palette.white,
    lineHeight: 19,
  },
  searchButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 24,
    paddingVertical: 16,
    paddingBottom: 14,
    borderRadius: 16,
    marginTop: 16,
    width: "100%",
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.gray3,
    borderWidth: 1,
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowOffset: {
      width: 1,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    elevation: 5,
  },
});
