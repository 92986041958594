import { StatusBar } from "expo-status-bar";
import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Platform,
  Image,
} from "react-native";
import Constants from "expo-constants";
import theme from "../../theme";
import { WalletIcon } from "../../assets/icons";
import moment, { Moment } from "moment";
import { ButtonFooter } from "../../components/ButtonFooter";
import { ScreenFlowHeader } from "../../components/ScreenFlowHeader";
import { useService } from "../../hooks/useService";
import { currency } from "../../utils";
import { AlertPage } from "../../components/AlertPage";
import api from "../../services/api";
import { usePets } from "../../hooks/usePets";
import { useAuth } from "../../hooks/useAuth";
import { LoadingPage } from "../../components/LoadingPage";
interface Props {
  navigation: any;
}

export const ServicePayment: React.FC<Props> = ({ navigation }) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const daysInMonth = currentMonth.daysInMonth();
  const [isEnabled, setIsEnabled] = useState(false);
  const firstDayOfMonth = moment(currentMonth).startOf("month").format("d");
  const { selectedService, selectedDate } = useService();
  const [loading, setLoading] = useState(false);
  const { pets } = usePets();
  const { logOut } = useAuth();
  const [alert, setAlert] = useState(false);

  function handleSend() {
    setLoading(true);
    api
      .post("/v1/service/appointment/", {
        datetime: moment(selectedDate).format("YYYY-MM-DDTHH:mm:ss"),
        pet_id: pets[0].id,
        employee_id: selectedService?.employee_detail[0].id,
        service_id: selectedService?.id,
      })
      .then((response) => {
        setLoading(false);
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
          navigation.navigate("Home");
        }, 4000);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          logOut();
        }
      })
      .finally(() => {});
  }
  return (
    <View style={styles.container}>
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
      <ScreenFlowHeader
        title="Resumo do serviço"
        buttonAction={() => navigation.goBack()}
      />
      <ScrollView style={styles.content}>
        <View style={styles.professionalContainer}>
          <View style={styles.professionalPhotoContainer}>
            <Image
              source={{
                uri: selectedService?.company_detail?.photo || undefined,
              }}
              style={{ width: "100%", height: "100%", borderRadius: 15 }}
            />
          </View>
          <View style={styles.professionalInfoContainer}>
            <Text style={styles.professionalName}>
              {selectedService?.employee_detail[0].name}
            </Text>
            <Text style={styles.professionalJob}>
              {selectedService?.employee_detail[0].office}
            </Text>
          </View>
        </View>
        <View style={styles.valueContainer}>
          <Text style={styles.valueTitle}>Resumo de valores</Text>
          <View style={[styles.valueInfoContainer]}>
            <Text style={styles.textOpacity}>{selectedService?.name}</Text>
            <Text style={styles.textOpacity}>
              {currency(selectedService?.prices[0]?.price || 0)}
            </Text>
          </View>
          <View style={styles.valueInfoContainer}>
            <Text style={styles.textSemiBold}>Subtotal</Text>
            <Text style={styles.textSemiBold}>
              {currency(selectedService?.prices[0]?.price || 0)}
            </Text>
          </View>
          {/* <View style={styles.valueInfoContainer}>
            <Text style={styles.textOpacity}>Táxi pet</Text>
            <Text style={styles.textGreen}>Grátis</Text>
          </View> */}
          <View style={styles.valueInfoContainer}>
            <Text style={styles.textOpacity}>Taxa de serviço</Text>
            <Text style={styles.textOpacity}>R$ 0,99</Text>
          </View>
          <View style={styles.valueInfoContainer}>
            <Text style={styles.textBold}>Total</Text>
            <Text style={styles.textBold}>
              {currency((selectedService?.prices[0]?.price || 0) + 0.99)}
            </Text>
          </View>
        </View>
        <View style={styles.paymentContainer}>
          <Text style={styles.paymentTitle}>Pagamento</Text>
          <View style={styles.paymentInfoContainer}>
            <View style={styles.paymentMethodIcon}>
              <WalletIcon color={theme.palette.primaryOrange} />
            </View>
            <View style={styles.paymentMethodInfo}>
              <Text style={styles.textSemiBold}>Pagamento no local</Text>
              <Text style={styles.textOpacity}>Pague no local do serviço</Text>
            </View>
            <View style={styles.paymentMethodChange}>
              {/* <Text style={styles.textGreen}>Alterar</Text> */}
            </View>
          </View>
          {/* <View style={styles.paymentInfoContainer}>
            <View style={styles.paymentMethodInfo}>
              <Text style={styles.textSemiBold}>CPF na nota</Text>
              <Text style={styles.textOpacity}>123.123.123-12</Text>
            </View>
          </View> */}
        </View>
      </ScrollView>
      <LoadingPage visible={loading} />
      <AlertPage visible={alert} message="Serviço agendado com sucesso!" />
      <ButtonFooter
        buttonText="Agendar serviço"
        buttonAction={() => {
          handleSend();
        }}
      ></ButtonFooter>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: theme.palette.littleOrange,
    position: "relative",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 14,
    paddingHorizontal: 10,
    paddingTop: 10,
    // backgroundColor: theme.palette.littleOrange,
    ...Platform.select({
      android: {
        paddingTop: Constants.statusBarHeight + 10,
      },
    }),
    justifyContent: "space-between",
  },
  backButton: {},
  title: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.black,
    fontFamily: theme.fonts.nunito["700"],
    alignSelf: "center",
  },
  content: {
    paddingHorizontal: 14,
  },
  professionalContainer: {
    flexDirection: "row",
    paddingBottom: 12,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray3,
  },
  professionalPhotoContainer: {
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: theme.palette.gray2,
    marginRight: 5,
    overflow: "hidden",
  },
  professionalInfoContainer: {
    justifyContent: "center",
  },
  professionalName: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.black,
    fontFamily: theme.fonts.asap["700"],
  },
  professionalJob: {
    fontSize: 14,
    color: theme.palette.gray1,
    fontFamily: theme.fonts.nunito["400"],
  },
  totalContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  totalText: {
    fontSize: 14,
    color: theme.palette.gray1,
    fontFamily: theme.fonts.nunito[600],
  },
  totalPrice: {
    fontSize: 14,
    color: theme.palette.black,
    fontFamily: theme.fonts.nunito[700],
    fontWeight: "bold",
  },
  valueContainer: {
    paddingVertical: 2,
  },
  valueTitle: {
    fontSize: 15,
    color: theme.palette.black,
    fontFamily: theme.fonts.nunito[700],
    fontWeight: "bold",
    marginBottom: 10,
  },
  valueInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 4,
  },
  textSemiBold: {
    fontSize: 14,
    color: theme.palette.black,
    fontFamily: theme.fonts.nunito[600],
  },
  textBold: {
    fontSize: 14,
    color: theme.palette.black,
    fontFamily: theme.fonts.nunito[700],
    fontWeight: "bold",
  },
  textGreen: {
    fontSize: 14,
    color: theme.palette.green,
    fontFamily: theme.fonts.nunito[700],
    fontWeight: "bold",
  },
  textOpacity: {
    fontSize: 14,
    color: theme.palette.gray1,
    fontFamily: theme.fonts.nunito[400],
  },
  paymentContainer: {
    paddingVertical: 2,
    marginTop: 14,
  },
  paymentTitle: {
    fontSize: 18,
    color: theme.palette.black,
    fontFamily: theme.fonts.nunito[700],
    fontWeight: "bold",
    marginBottom: 10,
  },
  paymentInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  paymentMethodIcon: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: theme.palette.littleOrange,
    marginRight: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  paymentMethodInfo: {
    flex: 1,
  },
  paymentMethodChange: {
    alignItems: "flex-end",
  },
});
