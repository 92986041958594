import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { TouchableOpacity, Text, StyleSheet } from "react-native";
import theme from "../theme";

interface Props {
    buttonAction: () => void;
    buttonText: string;
    disabled?: boolean;
}

export const GradientButton: React.FC<Props> = (props) => {
  return (
    <TouchableOpacity
      style={[styles.button, {
        opacity: props.disabled ? 0.2 : 1,
      }]}
      onPress={() => props.buttonAction()}
      disabled={props.disabled}
    >
      <LinearGradient
        colors={[theme.palette.secondaryOrange, theme.palette.primaryOrange]}
        style={styles.buttonGradient}
        start={{ x: 1, y: 1 }}
        end={{ x: 0, y: 0 }}
      >
        <Text style={styles.buttonText}>{props.buttonText}</Text>
      </LinearGradient>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
    button: {
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 30,
      shadowColor: "#000",
      marginHorizontal: 40,
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.2,
      shadowRadius: 2,
      elevation: 5,
      overflow: "hidden",
      marginBottom: 20,
    },
    buttonGradient: {
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    buttonText: {
      color: "white",
      fontSize: 16,
      fontWeight: "600",
    },

  });
  