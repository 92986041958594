import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { Pet, PetBreed, PetSpecie } from "../types";
import api from "../services/api";
import { useAuth } from "./useAuth";
interface PetsProviderProps {
  children: ReactNode;
}

interface PetsContextData {
  pets: Pet[];
  getPets: () => Promise<Pet[]>;
  fetchSpecies: () => Promise<PetSpecie[]>;
  species: PetSpecie[];
  fetchBreeds: (specieId: string) => Promise<PetBreed[]>;
  breeds: PetBreed[];
  fetchPetById: (petId: string) => Promise<Pet>;
}

const PetsContext = createContext({} as PetsContextData);

function PetsProvider({ children }: PetsProviderProps) {
  const [pets, setPets] = useState<Pet[]>([]);
  const [species, setSpecies] = useState<PetSpecie[]>([]);
  const [breeds, setBreeds] = useState<PetBreed[]>([]);
  const { logOut } = useAuth();
  const getPets = useCallback(() => {
    return new Promise<Pet[]>((resolve, reject) => {
      api
        .get("/v1/pet/")
        .then((response) => {
          setPets(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            logOut();
          }
          reject(error.response);
        })
    });
  }, []);
  const fetchPetById = useCallback((petId: string) => {
    return new Promise<Pet>((resolve, reject) => {
      api
        .get(`/v1/pet/${petId}/`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            logOut();
          }
          reject(error.response);
        })
    });
  }, []);
  const fetchSpecies = useCallback(() => {
    return new Promise<PetSpecie[]>((resolve, reject) => {
      api
        .get("/v1/pet/species/list/")
        .then((response) => {
          setSpecies(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            logOut();
          }
          reject(error.response);
        })
    });
  }, []);

  const fetchBreeds = useCallback((specieId: string) => {
    return new Promise<PetBreed[]>((resolve, reject) => {
      api
        .get("/v1/pet/breeds/list/?specie_id=" + specieId)
        .then((response) => {
          setBreeds(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            logOut();
          }
          reject(error.response);
        })
    });
  }, []);

  return (
    <PetsContext.Provider
      value={{
        pets,
        getPets,
        fetchSpecies,
        species,
        fetchBreeds,
        breeds,
        fetchPetById,
      }}
    >
      {children}
    </PetsContext.Provider>
  );
}

function usePets() {
  const context = useContext(PetsContext);
  return context;
}

export { PetsProvider, usePets };
