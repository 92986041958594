import React from "react";

import { Svg, Path, Defs, LinearGradient, Stop } from "react-native-svg";
interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  selected?: boolean;
}
export const HomeIcon = (props: IconProps) => {
  return (
    <Svg
      width={props.width || 21}
      height={props.height || props.width || 21}
      viewBox="0 0 21 21"
      fill="none"
      //   xmlns="http://www.w3.org/2000/svg"
    >
      {!props.selected ? (
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.6671 13.763C12.8266 13.763 13.7703 14.7 13.7703 15.8518V18.8141C13.7703 19.0616 13.9687 19.26 14.223 19.2658H16.0585C17.505 19.2658 18.6809 18.1043 18.6809 16.6771V8.27561C18.6741 7.78446 18.4401 7.3222 18.0385 7.01499L11.6892 1.95135C10.8369 1.27626 9.64469 1.27626 8.78951 1.95328L2.48356 7.01307C2.06656 7.3299 1.83255 7.79216 1.82773 8.29198V16.6771C1.82773 18.1043 3.0036 19.2658 4.45008 19.2658H6.30296C6.56394 19.2658 6.77581 19.0587 6.77581 18.8045C6.77581 18.7486 6.78255 18.6928 6.79411 18.6398V15.8518C6.79411 14.7068 7.7321 13.7707 8.88293 13.763H11.6671ZM16.0585 20.7103H14.2056C13.1444 20.6853 12.3258 19.8523 12.3258 18.8141V15.8518C12.3258 15.4964 12.0301 15.2075 11.6671 15.2075H8.88774C8.53238 15.2095 8.23866 15.4993 8.23866 15.8518V18.8045C8.23866 18.8767 8.22903 18.946 8.2088 19.0115C8.1048 19.9649 7.29007 20.7103 6.30296 20.7103H4.45008C2.20717 20.7103 0.383179 18.9008 0.383179 16.6771V8.28524C0.392809 7.32798 0.833879 6.45162 1.59564 5.87476L7.88908 0.823636C9.27488 -0.274224 11.2058 -0.274224 12.5887 0.82171L18.9274 5.87765C19.6718 6.44487 20.1129 7.31931 20.1254 8.26501V16.6771C20.1254 18.9008 18.3014 20.7103 16.0585 20.7103Z"
          fill={props.color || "#B7B7B7"}
        />
      ) : (
        <>
          <Path
            d="M14.2056 20.7103H16.0585C18.3014 20.7103 20.1254 18.9008 20.1254 16.6771V8.26503C20.1128 7.31933 19.6718 6.44489 18.9273 5.87766L12.5886 0.821725C11.2057 -0.274209 9.27484 -0.274209 7.88903 0.823651L1.59559 5.87477C0.833834 6.45163 0.392763 7.32799 0.383133 8.28525V16.6771C0.383133 18.9008 2.20712 20.7103 4.45003 20.7103H6.30291C7.29002 20.7103 8.10475 19.9649 8.20876 19.0115C8.22898 18.9461 8.23861 18.8767 8.23861 18.8045V15.8518C8.23861 15.4993 8.53234 15.2095 8.8877 15.2075H11.667C12.0301 15.2075 12.3257 15.4965 12.3257 15.8518V18.8141C12.3257 19.8523 13.1443 20.6853 14.2056 20.7103Z"
            fill="#FF6300"
          />
          <Path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.667 13.763C12.8265 13.763 13.7703 14.7 13.7703 15.8518V18.8141C13.7703 19.0616 13.9687 19.26 14.2229 19.2658H16.0585C17.5049 19.2658 18.6808 18.1044 18.6808 16.6771V8.27562C18.6741 7.78447 18.44 7.32222 18.0385 7.01501L11.6892 1.95137C10.8369 1.27628 9.64464 1.27628 8.78947 1.95329L2.48351 7.01308C2.06652 7.32992 1.8325 7.79218 1.82769 8.29199V16.6771C1.82769 18.1044 3.00355 19.2658 4.45003 19.2658H6.30291C6.56389 19.2658 6.77576 19.0587 6.77576 18.8045C6.77576 18.7486 6.7825 18.6928 6.79406 18.6398V15.8518C6.79406 14.7068 7.73206 13.7707 8.88288 13.763H11.667ZM16.0585 20.7103H14.2056C13.1443 20.6853 12.3257 19.8523 12.3257 18.8141V15.8518C12.3257 15.4965 12.0301 15.2075 11.667 15.2075H8.8877C8.53234 15.2095 8.23861 15.4993 8.23861 15.8518V18.8045C8.23861 18.8767 8.22898 18.9461 8.20876 19.0115C8.10475 19.9649 7.29002 20.7103 6.30291 20.7103H4.45003C2.20712 20.7103 0.383133 18.9008 0.383133 16.6771V8.28525C0.392763 7.32799 0.833834 6.45163 1.59559 5.87477L7.88903 0.823651C9.27484 -0.274209 11.2057 -0.274209 12.5886 0.821725L18.9273 5.87766C19.6718 6.44489 20.1128 7.31933 20.1254 8.26503V16.6771C20.1254 18.9008 18.3014 20.7103 16.0585 20.7103Z"
            fill="url(#paint0_linear_575_31)"
          />
          <Defs>
            <LinearGradient
              id="paint0_linear_575_31"
              x1="10.2542"
              y1="1.52588e-05"
              x2="10.2542"
              y2="20.7103"
              gradientUnits="userSpaceOnUse"
            >
              <Stop stopColor="#FF6300" />
              <Stop offset="1" stopColor="#FF3800" />
            </LinearGradient>
          </Defs>
        </>
      )}
    </Svg>
  );
};
