import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { Select } from "../../../components/Select";
import { TextInputMask } from "react-native-masked-text";
import theme from "../../../theme";
import { OnboardingPage } from "../../../components/OnboardingPage";
import { RootStackParamList } from "../../../routes/auth.routes";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useOnboarding } from "../../../hooks/useOboarding";
type LoginScreenNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  "CreatePhoneInput"
>;
type LoginScreenProps = {
  navigation: LoginScreenNavigationProp;
};
const masks = [
  {
    label: "BR +55",
    value: "99 99999-9999",
  },
  {
    label: "US +1",
    value: "999 999-9999",
  },
];

export const CreatePhoneInput: React.FC<LoginScreenProps> = ({
  navigation,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mask, setMask] = useState(masks[0].value);
  const { sendPhone } = useOnboarding();
  const inputRef = React.useRef(null);
  return (
    <OnboardingPage
      title="Qual o seu número de telefone?"
      caption="Digite o seu número de telefone."
      headerButtonAction={() => navigation.goBack()}
      headerButtonIcon={
        <FontAwesome
          name="chevron-left"
          size={24}
          color={theme.palette.secondaryOrange}
        />
      }
      buttonAction={() =>
        sendPhone(
          (masks
            .find((masksw) => masksw.value === mask)
            ?.label.split(" ")[1] as string) + phoneNumber.replace(/\D/g, ""),
          () =>
            navigation.navigate({
              name: "IdicatedCreateScreen",
              key: "IdicatedCreateScreen",
            })
        )
      }
      buttonText="Próximo"
      disabled={phoneNumber.length < 10}
      inputElement={
        <View style={styles.selectContainer}>
          <View style={styles.selectInput}>
            <Select
              itens={masks}
              onChange={(value) => {
                setMask(value);
                setPhoneNumber("");
              }}
            />
          </View>
          <TextInputMask
            keyboardType="number-pad"
            style={styles.input}
            placeholder={mask}
            placeholderTextColor="rgba(0,0,0,0.2)"
            type={"custom"}
            options={{
              mask: mask,
            }}
            onChangeText={(text) => {
              setPhoneNumber(text);
            }}
            value={phoneNumber}
            ref={inputRef}
          />
        </View>
      }
    />
  );
};

const styles = StyleSheet.create({
  input: {
    height: 40,
    width: "100%",
    paddingHorizontal: 0,
    fontSize: 18,
    color: theme.palette.gray1,
  },
  selectContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: 10,
  },
  selectInput: {
    width: 100,
  },
});
