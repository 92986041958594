import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  ImageBackground,
  TouchableOpacity,
  Platform,
  NativeSyntheticEvent,
  NativeScrollEvent,
  Image,
} from "react-native";
import { Feather } from "@expo/vector-icons";
import Constants from "expo-constants";
import { Dimensions } from "react-native";
import theme from "../../theme";
import { CalendarIcon, LocationIcon, StarIcon } from "../../assets/icons";
import moment from "moment";
import { SelectablePreview } from "../../components/SelectablePreview";
import { ButtonFooter } from "../../components/ButtonFooter";
import { useService } from "../../hooks/useService";
import { useFocusEffect } from "@react-navigation/native";
import { usePets } from "../../hooks/usePets";
import { Service } from "../../types";
interface Props {
  navigation: any;
  route: any;
}

export const ServiceDetail: React.FC<Props> = ({ navigation, route }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollViewRef = React.useRef<ScrollView>(null);
  const { pets } = usePets();
  const { selectedService, selectedDate } = useService();
  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const position = event.nativeEvent.contentOffset.y;
    setScrollPosition(position);
  };
  const handleButtonClick = () => {
    scrollViewRef.current?.scrollTo({ x: 0, y: 800, animated: true });
  };
  return (
    <View style={styles.container}>
      <StatusBar style="light" />
      <ImageBackground
        style={styles.imageContainer}
        source={{
          uri: selectedService?.photo || undefined,
        }}
      ></ImageBackground>
      <View
        style={[
          styles.header,
          {
            backgroundColor:
              scrollPosition + 40 + 32 > Dimensions.get("window").width
                ? theme.palette.white
                : "transparent",
          },
        ]}
      >
        <TouchableOpacity
          style={[
            styles.backButton,
            {
              backgroundColor:
                scrollPosition + 40 + 32 > Dimensions.get("window").width
                  ? theme.palette.white
                  : theme.palette.littleOrange,
              shadowOpacity:
                scrollPosition + 40 + 32 > Dimensions.get("window").width
                  ? 0
                  : 0.2,
            },
          ]}
          onPress={() => navigation.goBack()}
        >
          <Feather
            name="chevron-down"
            size={30}
            color={theme.palette.secondaryOrange}
          />
        </TouchableOpacity>
      </View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={230}
        onScroll={handleScroll}
        ref={scrollViewRef}
      >
        <View style={styles.imageBg}></View>
        <View style={styles.content}>
          <Text style={styles.title}>{selectedService?.name}</Text>
          <Text style={styles.category}>{selectedService?.category_detail.name}</Text>
          <Text style={styles.description}>{selectedService?.description}</Text>
          {/* <View style={styles.locationContainer}>
            <LocationIcon size={16} color={theme.palette.primaryOrange} />
            <Text style={styles.location}>2,5km de você</Text>
          </View> */}
          <View style={styles.aboutProfessional}>
            <Text style={styles.professionalTitle}>Sobre o profissional</Text>
            <View style={styles.professionalItem}>
              <View style={styles.professionalPhoto}>
                <Image
                  source={{
                    uri:
                      selectedService?.company_detail?.photo || undefined,
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                    resizeMode: "cover",
                    borderRadius: 12,
                  }}
                />
              </View>
              <View style={styles.professionalInfo}>
                <Text style={styles.professionalName}>
                  {selectedService?.employee_detail[0].name}
                </Text>
                <Text style={styles.professionalLabel}>
                  {selectedService?.employee_detail[0].office}
                </Text>
                <View style={styles.professionalRate}>
                  <StarIcon filled color={theme.palette.yellow} size={16} />
                  <StarIcon filled color={theme.palette.yellow} size={16} />
                  <StarIcon filled color={theme.palette.yellow} size={16} />
                  <StarIcon filled color={theme.palette.yellow} size={16} />
                  <StarIcon filled color={theme.palette.gray3} size={16} />
                  <Text style={styles.rateNote}>4.2</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.formArea}>
            <Text style={styles.formTitle}>Escolha o melhor horário</Text>
            <SelectablePreview
              icon={<CalendarIcon />}
              title={
                selectedDate
                  ? moment(selectedDate, "YYYY-MM-DD HH:mm").format("dddd")
                  : "Selecione uma data"
              }
              headerText={
                selectedDate
                  ? moment(selectedDate, "YYYY-MM-DD HH:mm").format(
                      "DD MMMM YYYY"
                    )
                  : ""
              }
              subText={
                selectedDate
                  ? moment(selectedDate, "YYYY-MM-DD HH:mm").format("HH:mm")
                  : ""
              }
              buttonText="Alterar"
              buttonAction={() => navigation.navigate("ServiceSelectDate")}
            />
          </View>
        </View>
      </ScrollView>
      <ButtonFooter
        buttonAction={() =>
          !selectedDate
            ? handleButtonClick()
            : navigation.navigate("ServiceResum")
        }
        buttonText="Continuar com o agendamento"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.palette.white,
    position: "relative",
  },
  imageContainer: {
    height: Dimensions.get("window").width,
    width: Dimensions.get("window").width,
    flexDirection: "row",
    backgroundColor: theme.palette.littleBlue,
    paddingHorizontal: 16,
    paddingTop: 16,
    ...Platform.select({
      android: {
        paddingTop: Constants.statusBarHeight + 16,
      },
    }),
    position: "absolute",
  },
  imageBg: {
    height: Dimensions.get("window").width - 32 - 40,
  },
  content: {
    backgroundColor: theme.palette.white,
  },
  backButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: theme.palette.littleOrange,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5,
  },
  header: {
    padding: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    color: theme.palette.black,
    paddingHorizontal: 16,
    paddingTop: 12,
    fontFamily: theme.fonts.asap["700Italic"],
  },
  category: {
    fontSize: 14,
    color: theme.palette.gray1,
    paddingHorizontal: 16,
    paddingTop: 4,
    fontFamily: theme.fonts.nunito[400],
  },
  description: {
    fontSize: 14,
    color: theme.palette.gray2,
    paddingHorizontal: 16,
    paddingTop: 4,
    fontFamily: theme.fonts.nunito[400],
  },
  location: {
    fontSize: 14,
    color: theme.palette.primaryOrange,

    fontFamily: theme.fonts.nunito[700],
  },
  locationContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingTop: 10,
    gap: 8,
  },
  aboutProfessional: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  professionalTitle: {
    fontSize: 15,
    fontWeight: "bold",
    color: theme.palette.black,
    fontFamily: theme.fonts.asap["700Italic"],
  },
  professionalItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 12,
  },
  professionalPhoto: {
    width: 55,
    height: 55,
    borderRadius: 12,
    backgroundColor: theme.palette.gray3,
  },
  professionalInfo: {
    marginLeft: 16,
  },
  professionalName: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.black,
    fontFamily: theme.fonts.nunito[600],
  },
  professionalLabel: {
    fontSize: 12,
    color: theme.palette.gray1,
    fontFamily: theme.fonts.nunito[400],
  },
  professionalRate: {
    flexDirection: "row",
    alignItems: "flex-end",
    gap: 4,
  },
  rateNote: {
    fontSize: 14,
    color: theme.palette.gray1,
    fontFamily: theme.fonts.nunito[400],
    marginLeft: 8,
    lineHeight: 16,
    verticalAlign: "bottom",
  },
  formArea: {
    paddingHorizontal: 16,
    paddingTop: 16,
    marginBottom: 16,
  },
  formTitle: {
    fontSize: 15,
    fontWeight: "bold",
    color: theme.palette.black,
    fontFamily: theme.fonts.asap["700Italic"],
    marginBottom: 16,
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
    justifyContent: "center",
  },
  modalContainer: {
    backgroundColor: theme.palette.white,
    overflow: "hidden",
    marginHorizontal: 16,
    borderRadius: 8,
    paddingBottom: 16,
  },
  modalOverlayClick: {
    flex: 1,
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  modalConfirmButton: {
    width: "80%",
    alignSelf: "center",
    color: theme.palette.white,
    height: 48,
    borderRadius: 8,
    backgroundColor: theme.palette.primaryOrange,
    justifyContent: "center",
    alignItems: "center",
  },
  modalConfirmButtonText: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.white,
    fontFamily: theme.fonts.asap[700],
  },
  calendarHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.littleBlue,
    padding: 8,
  },
  calendarHeaderTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.secondaryBlue,
    fontFamily: theme.fonts.asap[700],
  },
  calendarWeekdaysList: {
    backgroundColor: theme.palette.littleBlue,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
    paddingBottom: 8,
  },
  calendarWeekday: {
    width: Dimensions.get("window").width / 7 - 4,
    justifyContent: "center",
    alignItems: "center",
  },
  calendarWeekdayText: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.secondaryBlue,
    fontFamily: theme.fonts.asap[700],
  },
  calendarDaysList: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  calendarDay: {
    width: Dimensions.get("window").width / 7 - 5,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 2,
  },
  calendarDayDisabled: {
    paddingVertical: 2,
    opacity: 0.3,
    width: Dimensions.get("window").width / 7 - 5,
    justifyContent: "center",
    alignItems: "center",
  },
  calendarDayText: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.white,
    fontFamily: theme.fonts.asap[700],
  },
  calendarDayButton: {
    width: 28,
    height: 28,
    borderRadius: 16,
    backgroundColor: theme.palette.secondaryBlue,
    justifyContent: "center",
    alignItems: "center",
  },
  calendarDayButtonSelected: {
    width: 28,
    height: 28,
    borderRadius: 16,
    backgroundColor: theme.palette.secondaryOrange,
    justifyContent: "center",
    alignItems: "center",
  },
});
