import { StatusBar } from "expo-status-bar";
import React, { useEffect, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
  TouchableOpacity,
  FlatList,
  ScrollView,
  RefreshControl,
} from "react-native";
import theme from "../../theme";
import { useAuth } from "../../hooks/useAuth";
import { usePets } from "../../hooks/usePets";
import { CalendarIcon } from "../../assets/icons";
import api from "../../services/api";
import moment from "moment";
import { Pet } from "../../types";

interface Props {
  navigation: any;
  route: any;
}
export interface Specie {
  id: string;
  name: "Cachorro" | "Gato";
}
interface Schedule {
  pet_id: string;
  provider_id: string;
  date: string;
  updated_at: string;
  id: string;
  service_id: string;
  created_at: string;
  status: string;
  pet: {
    name: string;
    id: string;
    breed: {
      id: string;
      name: string;
      specie: Specie;
    };
    tutor: {
      id: string;
      first_name: string;
    };
  };
  provider: {
    name: string;
    id: string;
  };
  service: {
    id: string;
    title: string;
  };
}
export const Home: React.FC<Props> = ({ navigation, route }) => {
  const { user, logOut } = useAuth();
  const { pets, getPets } = usePets();
  const [schedules, setSchedules] = React.useState<any[]>([]);
  const [refreshing, setRefreshing] = React.useState(false);
  const hasUpdated = useRef(false);
  function getSchedules(petId: string) {
    setRefreshing(true);
    api
      .get("/v1/service/appointment/" + petId, {
        params: {
          start_date: moment().format("YYYY-MM-DD"),
          end_date: moment().add(60, "days").format("YYYY-MM-DD"),
        },
      })
      .then((response) => {
        setSchedules(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          logOut();
        }
      })
      .finally(() => {
        setRefreshing(false);
      });
  }
  const updateHome = async () => {
    getPets().then((p) => {
      const pets = p as Pet[];
      if (pets.length > 0) {
        getSchedules(pets[0].id);
      } else {
        setTimeout(() => {
          navigation.navigate("CreatePet");
        }, 600);
      }
    });
  };

  useEffect(() => {
    if (!hasUpdated.current) {
      updateHome();
      hasUpdated.current = true;
    }
  }, []);

  return (
    <ScrollView
      style={styles.container}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={updateHome} />
      }
    >
      <ImageBackground
        source={require("../../assets/homebg.png")}
        style={styles.background}
      >
        <View style={styles.homeHeaderContainer}>
          <View style={styles.profilesContainer}>
            <View style={styles.profile}>
              <Text style={styles.profileTitle}>Seu Perfil</Text>
              <ImageBackground
                source={{
                  uri: user?.tutor?.photo || undefined,
                }}
                style={styles.profilePhoto}
              />
              <View style={styles.profileInfoSelect}>
                <Text style={styles.profileName}>
                  {user?.first_name}{" "}
                  {
                    user?.last_name?.split(" ")[
                      user?.last_name?.split(" ").length - 1
                    ]
                  }
                </Text>
                <Text style={styles.profileLabel}>Tutor</Text>
              </View>
            </View>
            <View style={styles.profile}>
              <Text style={styles.profileTitle}>Seu Pet</Text>
              <View style={styles.profilePhoto}>
                <ImageBackground
                  source={{
                    uri: pets[0]?.photo || undefined,
                  }}
                  style={styles.profilePhoto}
                />
              </View>
              {pets.length === 0 ? (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("CreatePet");
                  }}
                  style={styles.profileInfoSelect}
                >
                  <Text style={styles.profileName}>Adicionar Pet</Text>
                  <Text style={styles.profileLabel}></Text>
                </TouchableOpacity>
              ) : (
                <View style={styles.profileInfoSelect}>
                  <Text style={styles.profileName}>
                    {pets[0].name.split(" ")[0]}
                  </Text>
                  <Text style={styles.profileLabel}>{pets[0].breed_detail.name}</Text>
                </View>
              )}
            </View>
          </View>
        </View>
        {/* <Filters/> */}
        {/* <UpdatePetScreen/> */}
      </ImageBackground>
      <ImageBackground
        source={require("../../assets/calendarhomebg.png")}
        style={styles.calendarContainer}
      >
        <View style={styles.calendarHeader}>
          <CalendarIcon size={30} color={theme.palette.black} />
          <Text style={styles.calendarTitle}>Agenda pet</Text>
        </View>
        <FlatList
          style={styles.calendarList}
          horizontal
          data={schedules}
          renderItem={({ item }) => (
            <View style={styles.calendarItem}>
              <View style={styles.calendarItemHeader}>
                <Text style={styles.calendarItemDate}>
                  {moment(item?.datetime).format("DD MMM")}
                </Text>
                <Text style={styles.calendarItemHour}>
                  {moment(item?.datetime).format("HH:mm")}
                </Text>
              </View>
              <View style={styles.calendarItemInfo}>
                <Text style={styles.calendarItemTitle}>{item.status}</Text>
                <Text style={styles.calendarItemTitle}>
                  {item?.service?.name}
                </Text>
              </View>
            </View>
          )}
          keyExtractor={(item) => item.id}
        />
      </ImageBackground>
      <StatusBar style="auto" />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  background: {
    width: "100%",
    height: 350,
    resizeMode: "contain",
    backgroundColor: theme.palette.white,
  },
  container: {
    flex: 1,
    backgroundColor: theme.palette.primaryOrange,
  },
  homeHeaderContainer: {
    flex: 1,
    alignItems: "center",
  },
  profilesContainer: {
    flexDirection: "row",
    width: "100%",
    paddingTop: 44,
  },
  profile: {
    width: "50%",
    alignItems: "center",
  },
  profileTitle: {
    fontSize: 25,
    fontWeight: "600",
    color: theme.palette.white,
    marginBottom: 16,
    fontFamily: theme.fonts.asap["700Italic"],
  },
  profilePhoto: {
    width: 120,
    height: 120,
    borderRadius: 10,
    backgroundColor: theme.palette.primaryBlue,
    marginBottom: 20,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    elevation: 5,
    overflow: "hidden",
  },
  profileInfoSelect: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    width: "80%",
    paddingHorizontal: 16,
    paddingVertical: 11,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    elevation: 5,
  },
  profileName: {
    fontSize: 16,
    fontWeight: "800",
    fontFamily: theme.fonts.nunito[800],
    color: theme.palette.black,
    textTransform: "capitalize",
  },
  profileLabel: {
    fontSize: 14,
    fontFamily: theme.fonts.nunito[400],
    color: theme.palette.black,
  },
  calendarContainer: {
    flex: 1,
    paddingLeft: 25,
    resizeMode: "contain",

    backgroundColor: theme.palette.white,
    minHeight: 400,
  },
  calendarHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 40,
    gap: 10,
    marginTop: 60,
  },
  calendarTitle: {
    fontSize: 22,
    fontWeight: "800",
    fontFamily: theme.fonts.asap["700Italic"],
  },
  calendarList: {
    height: "auto",
    flexDirection: "row",
    maxHeight: 110,
  },
  calendarItem: {
    width: 120,
    height: 100,
    backgroundColor: theme.palette.littleOrange,
    borderRadius: 10,
    marginRight: 10,
    padding: 12,
    justifyContent: "space-between",
  },
  calendarItemHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    justifyContent: "space-between",
  },
  calendarItemDate: {
    fontSize: 14,
    fontWeight: "700",
    fontFamily: theme.fonts.nunito[700],
    color: theme.palette.black,
  },
  calendarItemHour: {
    fontSize: 14,
    fontWeight: "400",
    fontFamily: theme.fonts.nunito[400],
    color: theme.palette.black,
  },
  calendarItemInfo: {
    width: "100%",
  },
  calendarItemTitle: {
    fontSize: 14,
    fontWeight: "400",
    fontFamily: theme.fonts.nunito[400],
    color: theme.palette.black,
  },
});
