import React from "react";

import { Svg, Path, Mask, G } from "react-native-svg";
interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: number;
}
export const LogoutIcon = (props: IconProps) => {
  return (
    <Svg
      width={props.size || 21}
      height={(20 / 21) * (props.size || 20)}
      viewBox="0 0 20 21"
      fill="none"
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.31924 20.0001H4.43324C1.98924 20.0001 0.000244141 18.0111 0.000244141 15.5651V4.43612C0.000244141 1.99012 1.98924 0.00012207 4.43324 0.00012207H9.30824C11.7542 0.00012207 13.7442 1.99012 13.7442 4.43612V5.36812C13.7442 5.78212 13.4082 6.11812 12.9942 6.11812C12.5802 6.11812 12.2442 5.78212 12.2442 5.36812V4.43612C12.2442 2.81612 10.9272 1.50012 9.30824 1.50012H4.43324C2.81624 1.50012 1.50024 2.81612 1.50024 4.43612V15.5651C1.50024 17.1841 2.81624 18.5001 4.43324 18.5001H9.31924C10.9312 18.5001 12.2442 17.1881 12.2442 15.5761V14.6331C12.2442 14.2191 12.5802 13.8831 12.9942 13.8831C13.4082 13.8831 13.7442 14.2191 13.7442 14.6331V15.5761C13.7442 18.0161 11.7582 20.0001 9.31924 20.0001Z"
        fill={props.color || "#222222"}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.787 10.7501H7.74604C7.33204 10.7501 6.99604 10.4141 6.99604 10.0001C6.99604 9.58612 7.33204 9.25012 7.74604 9.25012H19.787C20.201 9.25012 20.537 9.58612 20.537 10.0001C20.537 10.4141 20.201 10.7501 19.787 10.7501Z"
        fill={props.color || "#222222"}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8593 13.6661C16.6673 13.6661 16.4743 13.5931 16.3283 13.4451C16.0363 13.1511 16.0373 12.6771 16.3303 12.3851L18.7243 10.0001L16.3303 7.61612C16.0373 7.32412 16.0353 6.85012 16.3283 6.55612C16.6203 6.26212 17.0943 6.26212 17.3883 6.55412L20.3163 9.46912C20.4583 9.60912 20.537 9.80112 20.537 10.0001C20.537 10.1991 20.4583 10.3911 20.3163 10.5311L17.3883 13.4471C17.2423 13.5931 17.0503 13.6661 16.8593 13.6661Z"
        fill={props.color || "#222222"}
      />
    </Svg>
  );
};
