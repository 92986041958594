import React, { useCallback, useState } from "react";
import { FontAwesome } from "@expo/vector-icons";
import theme from "../../../theme";
import { OnboardingPage } from "../../../components/OnboardingPage";
import { RootStackParamList } from "../../../routes/auth.routes";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useOnboarding } from "../../../hooks/useOboarding";
import api from "../../../services/api";
type LoginScreenNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  "EmailCreateScreen"
>;
type LoginScreenProps = {
  navigation: LoginScreenNavigationProp;
};
export const IdicatedCreateScreen: React.FC<LoginScreenProps> = ({
  navigation,
}) => {
  const [indicatedCode, setIndicatedCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { sendIndicatedCode } = useOnboarding();
  const validateCode = useCallback(() => {
    setLoading(true);
    setError("");
    api
      .post("/v1/auth/verify/user/code", { code: indicatedCode })
      .then(() => {
        sendIndicatedCode(indicatedCode, () =>
          navigation.navigate({
            name: "PasswordCreateScreen",
            key: "PasswordCreateScreen",
          })
        );
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [indicatedCode]);
  return (
    <OnboardingPage
      title="Você foi indicado por alguém ?"
      caption="Conta para a gente o código de quem te indicou."
      headerButtonAction={() => navigation.goBack()}
      headerButtonIcon={
        <FontAwesome
          name="chevron-left"
          size={24}
          color={theme.palette.secondaryOrange}
        />
      }
      buttonAction={() =>
        indicatedCode.length > 0
          ? validateCode()
          : sendIndicatedCode(indicatedCode, () =>
              navigation.navigate({
                name: "PasswordCreateScreen",
                key: "PasswordCreateScreen",
              })
            )
      }
      buttonText={indicatedCode.length > 0 ? "Próximo" : "Não tenho código"}
      value={indicatedCode}
      alertText={error}
      loading={loading}
      onChangeText={(text) => {
        setError("");
        setIndicatedCode(text);
      }}
    />
  );
};
