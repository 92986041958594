import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { TabBar } from "../../src/components/TabBar";
import { Header } from "../components/Header";
import { Home } from "../screens";
import { createDrawerNavigator } from "@react-navigation/drawer";
import UserProfileDrawer from "../components/Dreawer";
import { Services } from "../screens/Services";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ServiceDetail } from "../screens/ServiceDetail";
import { ServiceResum } from "../screens/ServiceResum";
import { ServicePayment } from "../screens/ServicePayment";
import { ServiceDateSelect } from "../screens/ServiceDateSelect";
import { EditProfile } from "../screens/Edit/Profile";
import { UpdatePetScreen } from "../screens/Edit/Pet";
import { PetCard } from "../screens/PetCard";
import { PetsProvider } from "../hooks/usePets";
import { CreatePetOnboardScreen } from "../screens/Onboarding/CreatePet/Index";
import { CreatePetPhotoOnboardScreen } from "../screens/Onboarding/CreatePetPhoto/Index";
import { ServiceProvider } from "../hooks/useService";
const Tab = createBottomTabNavigator();

const HomeTabs = () => {
  return (
    <Tab.Navigator
      tabBar={(props) => <TabBar {...props} />}
      initialRouteName="Home"
      screenOptions={{
        header: (props) => <Header title="Home" {...props} />,
      }}
    >
      <Tab.Screen
        name="Home"
        options={{
          title: "Início",
        }}
        initialParams={{
          title: "Início",
        }}
        component={Home}
      />
      <Tab.Screen
        options={{
          title: "Serviços",
        }}
        name="Services"
        initialParams={{
          title: "Serviços",
        }}
        component={Services}
      />
      {/* <Tab.Screen
        options={{
          title: "Calendário",
        }}
        name="Calendar"
        initialParams={{
          title: "Calendário",
        }}
        component={Calendar}
      /> */}
      {/* <Tab.Screen name="Dashboard" component={Dashboard} /> */}
      {/* <Tab.Screen name="Notas" component={Notes} /> */}
      {/* <Tab.Screen name="Boletos" component={Bills} />
      <Tab.Screen name="Assinaturas" component={Documents} /> */}
    </Tab.Navigator>
  );
};
const Stack = createNativeStackNavigator();

export function ServiceStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="ServiceDetail" component={ServiceDetail} />
      <Stack.Screen name="ServiceSelectDate" component={ServiceDateSelect} />
      <Stack.Screen name="ServiceResum" component={ServiceResum} />
      <Stack.Screen name="ServicePayment" component={ServicePayment} />
    </Stack.Navigator>
  );
}
export function StackList() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="AppTabs" component={HomeTabs} />
      <Stack.Group
        screenOptions={{
          presentation: "modal",
        }}
      >
        <Stack.Screen name="ServiceStack" component={ServiceStack} />
      </Stack.Group>
    </Stack.Navigator>
  );
}
export function PetStackList() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="PetCard" component={PetCard} />
      <Stack.Screen name="UpdatePetScreen" component={UpdatePetScreen} />
      {/* <Stack.Screen name="PetCard" component={Filters} /> */}
    </Stack.Navigator>
  );
}

const Drawer = createDrawerNavigator();

export function AppDrawer() {
  return (
    <Drawer.Navigator
      initialRouteName="LoadSplash"
      screenOptions={{
        drawerPosition: "right",
        headerShown: false,
        drawerHideStatusBarOnOpen: false,
        lazy: true,
        drawerType: "front",
      }}
      drawerContent={(props) => {
        return <UserProfileDrawer {...props} />;
      }}
    >
      <Drawer.Screen name="AppStack" component={StackList} />
      <Drawer.Screen name="CreatePet" component={CreatePetOnboardScreen} />
      <Drawer.Screen
        name="CreatePetPhoto"
        component={CreatePetPhotoOnboardScreen}
      />
      <Drawer.Screen name="EditProfile" component={EditProfile} />
      <Drawer.Screen name="MyPetScreen" component={PetStackList} />
    </Drawer.Navigator>
  );
}

export function AppRoutes() {
  return (
    <PetsProvider>
      <ServiceProvider>
        <AppDrawer />
      </ServiceProvider>
    </PetsProvider>
  );
}
