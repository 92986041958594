import React from "react";

import { Svg, Path, Mask, G } from "react-native-svg";
interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: number;
}
export const MailIcon = (props: IconProps) => {
  return (
    <Svg
      width={props.size || 22}
      height={(20 / 22) * (props.size || 20)}
      viewBox="0 0 22 20"
      fill="none"
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.777 11.4649C10.108 11.4649 9.44098 11.2439 8.88298 10.8019L4.39798 7.18587C4.07498 6.92587 4.02498 6.45287 4.28398 6.13087C4.54498 5.80987 5.01698 5.75887 5.33898 6.01787L9.81998 9.62987C10.383 10.0759 11.176 10.0759 11.743 9.62587L16.179 6.01987C16.501 5.75687 16.973 5.80687 17.235 6.12887C17.496 6.44987 17.447 6.92187 17.126 7.18387L12.682 10.7959C12.12 11.2419 11.448 11.4649 10.777 11.4649Z"
        fill="black"
      />
      <Mask
        id="mask0_33437_4893"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="20"
      >
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 0.00012207H21.4999V19.5001H0V0.00012207Z"
          fill="white"
        />
      </Mask>
      <G mask="url(#mask0_33437_4893)">
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.839 18.0001H15.659C15.661 17.9981 15.669 18.0001 15.675 18.0001C16.816 18.0001 17.828 17.5921 18.604 16.8171C19.505 15.9201 20 14.6311 20 13.1881V6.32012C20 3.52712 18.174 1.50012 15.659 1.50012H5.841C3.326 1.50012 1.5 3.52712 1.5 6.32012V13.1881C1.5 14.6311 1.996 15.9201 2.896 16.8171C3.672 17.5921 4.685 18.0001 5.825 18.0001H5.839ZM5.822 19.5001C4.279 19.5001 2.901 18.9401 1.837 17.8801C0.652 16.6981 0 15.0321 0 13.1881V6.32012C0 2.71712 2.511 0.00012207 5.841 0.00012207H15.659C18.989 0.00012207 21.5 2.71712 21.5 6.32012V13.1881C21.5 15.0321 20.848 16.6981 19.663 17.8801C18.6 18.9391 17.221 19.5001 15.675 19.5001H15.659H5.841H5.822Z"
          fill="black"
        />
      </G>
    </Svg>
  );
};
