import React, { useState } from "react";
import { FontAwesome } from "@expo/vector-icons";
import theme from "../../../theme";
import { OnboardingPage } from "../../../components/OnboardingPage";
import { RootStackParamList } from "../../../routes/auth.routes";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useOnboarding } from "../../../hooks/useOboarding";
import { emailValidator } from "../../../assets/utils";
import { useAuth } from "../../../hooks/useAuth";
type LoginScreenNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  "EmailLoginScreen"
>;
type LoginScreenProps = {
  navigation: LoginScreenNavigationProp;
};
export const EmailLoginScreen: React.FC<LoginScreenProps> = ({
  navigation,
}) => {
  const [email, setEmail] = useState("");
  const { setEmailLogin } = useAuth();

  return (
    <OnboardingPage
      title="Qual o seu email?"
      caption="Digite seu email cadastrado para entrar."
      headerButtonAction={() => navigation.goBack()}
      headerButtonIcon={
        <FontAwesome
          name="chevron-left"
          size={24}
          color={theme.palette.secondaryOrange}
        />
      }
      buttonAction={() =>
        navigation.navigate({
          name: "PasswordLoginScreen",
          key: "PasswordLoginScreen",
        })
      }
      buttonText="Próximo"
      value={email}
      onChangeText={(text) => {
        setEmail(text);
        setEmailLogin(text);
      }}
      disabled={!emailValidator(email)}
      textInputProps={{
        keyboardType: "email-address",
        autoCapitalize: "none",
        autoCorrect: false,
      }}
    />
  );
};
