import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  SafeAreaView,
  TextInput,
  TouchableHighlight,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
import { FontAwesome } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import { Select } from "../../components/Select";
import { TextInputMask } from "react-native-masked-text";
import theme from "../../theme";

const masks = [
  {
    label: "BR +55",
    value: "99 99999-9999",
  },
  {
    label: "US +1",
    value: "999 999-9999",
  },
];

export const LoginPhoneInput = ({ navigation }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mask, setMask] = useState(masks[0].value);
  return (
    <LinearGradient
      colors={[theme.palette.white, theme.palette.littleOrange]}
      style={styles.container}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 1 }}
    >
      <SafeAreaView style={styles.safeAreaContainer}>
        <KeyboardAvoidingView
          style={styles.keyboardAvoidingContainer}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
          <View style={styles.header}>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <FontAwesome
                name="chevron-left"
                size={24}
                color={theme.palette.secondaryOrange}
              />
            </TouchableOpacity>
          </View>
          <View style={styles.body}>
            <Text style={styles.appTitle}>Qual o seu número de telefone?</Text>
            <Text style={styles.caption}>
              Nós iremos enviar um código de confirmação para o seu celular.
            </Text>
            <View style={styles.selectContainer}>
              <View style={styles.selectInput}>
                <Select
                  itens={masks}
                  onChange={(value) => {
                    setMask(value);
                    setPhoneNumber("");
                  }}
                />
              </View>
              <TextInputMask
                keyboardType="number-pad"
                style={styles.input}
                placeholder={mask}
                placeholderTextColor="rgba(0,0,0,0.2)"
                type={"custom"}
                options={{
                  mask: mask,
                }}
                onChangeText={(text) => {
                  setPhoneNumber(text);
                }}
                value={phoneNumber}
              />
            </View>
          </View>
          <TouchableOpacity
            style={styles.button}
            onPress={() => navigation.navigate("LoginPhoneConfirmation")}
          >
            <LinearGradient
              colors={[
                theme.palette.secondaryOrange,
                theme.palette.primaryOrange,
              ]}
              style={styles.buttonGradient}
              start={{ x: 1, y: 1 }}
              end={{ x: 0, y: 0 }}
            >
              <Text style={styles.buttonText}>Avançar</Text>
            </LinearGradient>
          </TouchableOpacity>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  safeAreaContainer: {
    flex: 1,
  },
  keyboardAvoidingContainer: {
    flex: 1,
    // justifyContent: "space-between",
  },
  header: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    marginTop: 20,
    paddingHorizontal: 20,
    ...Platform.select({
      android: {
        marginTop: 60,
      },
    }),
  },
  body: {
    paddingHorizontal: 40,
    flex: 1,
  },
  caption: {
    color: theme.palette.gray1,
    fontSize: 14,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15,
    ...Platform.select({
      android: {
        fontSize: 16,
      },
    }),
  },
  appTitle: {
    fontSize: 20,
    color: theme.light.colors.text,
    fontWeight: "bold",
    marginBottom: 10,
    marginTop: 24,
    ...Platform.select({
      android: {
        fontSize: 22,
      },
    }),
  },
  input: {
    height: 40,
    width: "100%",
    paddingHorizontal: 0,
    fontSize: 18,
    color: theme.palette.gray1,
  },
  button: {
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
    shadowColor: "#000",
    marginHorizontal: 40,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 5,
    overflow: "hidden",
    marginBottom: 20,
  },
  buttonGradient: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
  },
  selectContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: 10,
  },
  selectInput: {
    width: 100,
  },
});
