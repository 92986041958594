import { Modal, StyleSheet, View, Text } from "react-native";
import theme from "../theme";
import { BoxCheckedIcon } from "../assets/icons";

interface AlertPageProps {
  visible: boolean;
  message: string;
}

export const AlertPage: React.FC<AlertPageProps> = ({ visible, message }) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={() => {
        console.log("Modal has been closed.");
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <BoxCheckedIcon size={20} color={theme.palette.green} />
          <Text>{message}</Text>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    flexDirection: "row",
    gap: 10,
    padding: 20,
    alignItems: "center",
  },
});
