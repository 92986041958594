import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Login } from "../screens/";
import { LoginPhoneInput } from "../screens/PhoneLogin";
import { LoginPhoneConfirmation } from "../screens/PhoneConfirmation";
import { EmailCreateScreen } from "../screens/Onboarding/EmailCreate";
import { PasswordCreateScreen } from "../screens/Onboarding/Password";
import { NameCreateScreen } from "../screens/Onboarding/NameCreate";
import { CreatePhoneInput } from "../screens/Onboarding/PhoneCreate";
import { IdicatedCreateScreen } from "../screens/Onboarding/Indicated";
import { CreatePetOnboardScreen } from "../screens/Onboarding/CreatePet/Index";
import { CreatePetPhotoOnboardScreen } from "../screens/Onboarding/CreatePetPhoto/Index";
import { OnboardingProvider } from "../hooks/useOboarding";
import { PasswordLoginScreen } from "../screens/EmailLogin/password";
import { EmailLoginScreen } from "../screens/EmailLogin/email";

export type RootStackParamList = {
  Login: {
    name: string;
  };
  LoginPhoneInput: {
    name: string;
  };
  LoginPhoneConfirmation: {
    name: string;
  };
  modal: {
    name: string;
  };
  EmailLoginScreen: {
    name: string;
  };
  EmailCreateScreen: {
    name: string;
  };
  PasswordCreateScreen: {
    name: string;
  };
  OnboardStack: {
    name: string;
  };
  NameCreateScreen: {
    name: string;
  };
  CreatePhoneInput: {
    name: string;
  };
  IdicatedCreateScreen: {
    name: string;
  };
  CreatePetOnboardScreen: {
    name: string;
  };
  CreatePetPhotoOnboardScreen: {
    name: string;
  };
  PasswordLoginScreen: {
    name: string;
  };
  LoginEmailStack: {
    name: string;
  };
};
const { Navigator, Screen, Group } =
  createNativeStackNavigator<RootStackParamList>();

function OnboardStack() {
  return (
    <Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Group>
        <Screen
          options={{
            headerShown: false,
            animationDuration: 400,
          }}
          component={NameCreateScreen}
          name="NameCreateScreen"
        />
        <Screen
          options={{
            headerShown: false,
            animationDuration: 400,
          }}
          component={EmailCreateScreen}
          name="EmailCreateScreen"
        />
        <Screen
          options={{
            headerShown: false,
            animationDuration: 400,
          }}
          component={CreatePhoneInput}
          name="CreatePhoneInput"
        />
        <Screen
          options={{
            headerShown: false,
            animationDuration: 400,
          }}
          component={PasswordCreateScreen}
          name="PasswordCreateScreen"
        />
        <Screen
          options={{
            headerShown: false,
            animationDuration: 400,
          }}
          component={IdicatedCreateScreen}
          name="IdicatedCreateScreen"
        />
      </Group>
    </Navigator>
  );
}

function LoginEmailStack() {
  return (
    <Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Group>
        <Screen
          options={{
            headerShown: false,
            animationDuration: 400,
          }}
          component={EmailLoginScreen}
          name="EmailLoginScreen"
        />
        <Screen
          options={{
            headerShown: false,
            animationDuration: 400,
          }}
          component={PasswordLoginScreen}
          name="PasswordLoginScreen"
        />
      </Group>
    </Navigator>
  );
}

export function AuthRoutes() {
  return (
    <OnboardingProvider>
      <Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        <Group>
          <Screen
            options={{
              headerShown: false,
              animation: "slide_from_bottom",
              animationDuration: 400,
            }}
            component={Login}
            name="Login"
          />
          <Screen
            options={{
              headerShown: false,
              animationDuration: 400,
            }}
            component={LoginPhoneInput}
            name="LoginPhoneInput"
          />
          <Screen
            options={{
              headerShown: false,
              animation: "slide_from_left",
              animationDuration: 400,
            }}
            component={LoginPhoneConfirmation}
            name="LoginPhoneConfirmation"
          />
          <Screen
            options={{
              headerShown: false,
              animation: "slide_from_left",
              animationDuration: 400,
            }}
            component={OnboardStack}
            name="OnboardStack"
          />
          <Screen
            options={{
              headerShown: false,
              animation: "slide_from_left",
              animationDuration: 400,
            }}
            component={LoginEmailStack}
            name="LoginEmailStack"
          />
          <Screen
            options={{
              headerShown: false,
              animationDuration: 400,
            }}
            component={CreatePetOnboardScreen}
            name="CreatePetOnboardScreen"
          />
          <Screen
            options={{
              headerShown: false,
              animationDuration: 400,
            }}
            component={CreatePetPhotoOnboardScreen}
            name="CreatePetPhotoOnboardScreen"
          />
        </Group>
        <Group
          screenOptions={{
            presentation: "modal",
          }}
        >
          <Screen
            options={{
              headerShown: false,
              animation: "slide_from_left",
              animationDuration: 400,
            }}
            component={LoginPhoneConfirmation}
            name="modal"
          />
        </Group>
      </Navigator>
    </OnboardingProvider>
  );
}
