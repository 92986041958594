import React from "react";
import { TouchableOpacity, View, Text, StyleSheet } from "react-native";
import theme from "../theme";

interface Props {
  icon: React.ReactNode;
  title: string;
  headerText?: string;
  subText?: string;
  buttonText?: string;
  buttonAction?: () => void;
}

export const SelectablePreview: React.FC<Props> = ({
  icon,
  title,
  headerText,
  subText,
  buttonText,
  buttonAction,
}) => {
  return (
    <View style={styles.adressContainer}>
      <View style={styles.adressIcon}>{icon}</View>
      <View style={styles.adressInfo}>
        <Text style={styles.adressTitle}>{headerText}</Text>
        <Text style={styles.adressText}>{title}</Text>
        <Text style={styles.adressComplement}>{subText}</Text>
      </View>
      <View>
        <TouchableOpacity style={styles.adressButton} onPress={buttonAction}>
          <Text style={styles.adressButtonText}>{buttonText || "Alterar"}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  adressContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  adressIcon: {
    width: 30,
    height: 30,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    backgroundColor: theme.palette.gray3,
  },
  adressInfo: {
    flex: 1,
  },
  adressTitle: {
    fontSize: 13,
    color: theme.palette.gray1,
    fontFamily: theme.fonts.nunito[600],
  },
  adressText: {
    fontSize: 15,
    color: theme.palette.black,
    fontFamily: theme.fonts.nunito[700],
    fontWeight: "bold",
  },
  adressComplement: {
    fontSize: 14,
    color: theme.palette.gray1,
    fontFamily: theme.fonts.nunito[400],
  },
  adressButton: {},
  adressButtonText: {
    fontSize: 14,
    color: theme.palette.secondaryOrange,
    fontFamily: theme.fonts.nunito[700],
    fontWeight: "bold",
  },
});
