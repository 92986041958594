import{Defs, G, LinearGradient, Path, Stop, Svg} from 'react-native-svg';

export default function BepetLogoNegativo({width=154, height=58}: {width?:number, height?: number}) {
    return (
        <Svg width={width} height={height} viewBox="0 0 154 58" fill="none">
            <G filter="url(#filter0_d_1664_820)">
                <G opacity="0.1">
                    <Path opacity="0.1" d="M123.184 27.9073C119.11 27.9073 115.986 29.1084 113.677 31.3772C111.368 33.6459 110.281 37.2493 110.281 42.1872C110.281 46.5913 111.368 49.9277 113.677 52.4634C115.986 54.8656 119.517 56.2002 124.135 56.2002C125.221 56.2002 126.579 56.0667 128.073 55.7998C129.567 55.5329 130.926 55.1325 132.012 54.4652C133.099 53.9314 133.642 53.1306 133.642 52.3299C133.642 51.9295 133.506 51.5292 133.234 50.8619C132.963 50.1946 132.555 49.6608 132.148 49.1269C131.74 48.5931 131.333 48.3262 130.926 48.3262C130.654 48.3262 130.382 48.4597 129.703 48.7266C128.753 49.1269 127.938 49.3938 127.123 49.5273C126.308 49.7942 125.357 49.9277 124.406 49.9277C122.505 49.9277 121.147 49.5273 120.06 48.7266C118.974 47.9258 118.43 46.4578 118.295 44.4559H131.469C133.234 44.4559 134.593 43.1214 134.593 41.3864C134.593 37.3827 133.642 34.1798 131.74 31.6441C129.839 29.1084 127.123 27.9073 123.184 27.9073ZM118.43 39.3846C118.974 35.9147 120.604 34.0463 123.184 34.0463C124.406 34.0463 125.493 34.5801 126.036 35.5143C126.715 36.4485 126.987 37.7831 126.987 39.3846H118.43Z" fill="#222222" />
                    <Path opacity="0.1" d="M104.577 21.7683C101.997 20.1668 99.0087 19.3661 95.7491 19.3661H84.6121C83.7972 19.3661 83.2539 19.4995 82.9823 19.8999C82.7107 20.1668 82.439 20.7007 82.439 21.5014V54.5987C82.439 55.6664 83.5256 56.3337 85.6986 56.3337H87.4642C89.6373 56.3337 90.7238 55.7998 90.7238 54.5987V42.4541H96.2923C97.6505 42.4541 99.4161 42.1872 101.182 41.5199C103.083 40.9861 104.713 39.785 106.207 38.0501C107.701 36.3151 108.516 33.9129 108.516 30.8434C108.38 26.3058 107.158 23.3698 104.577 21.7683ZM98.6012 34.4467C97.6505 35.114 96.564 35.3809 95.3416 35.3809H90.7238V26.1724H95.3416C96.564 26.1724 97.6505 26.5728 98.6012 27.24C99.5519 27.9073 99.9594 29.1084 99.9594 30.8434C99.9594 32.5783 99.5519 33.7794 98.6012 34.4467Z" fill="#222222" />
                    <Path opacity="0.1" d="M152.249 52.33C152.113 51.6627 151.977 51.1288 151.706 50.595C151.434 50.0612 151.162 49.7943 150.891 49.7943C150.755 49.7943 150.483 49.7943 150.212 49.9277C149.94 50.0612 149.668 50.0612 149.532 50.0612C148.853 50.0612 148.31 49.9277 148.038 49.5274C147.767 49.127 147.631 48.5932 147.631 47.7924V34.4467H150.212C150.755 34.4467 151.162 34.1798 151.434 33.7794C151.705 33.3791 151.841 32.7118 151.841 31.7776V30.8434C151.841 29.9092 151.705 29.2419 151.434 28.8415C151.162 28.4412 150.755 28.1742 150.212 28.1742H147.631V23.2363C147.631 22.1687 146.68 21.5014 144.915 21.5014H143.149C141.383 21.5014 140.433 22.0352 140.297 23.2363L139.618 28.1742H138.803C138.26 28.1742 137.852 28.4412 137.581 28.8415C137.309 29.2419 137.173 29.9092 137.173 30.8434V31.7776C137.173 32.7118 137.309 33.3791 137.581 33.7794C137.852 34.1798 138.26 34.4467 138.803 34.4467H139.618V47.659C139.618 53.2642 142.198 56.2002 147.359 56.2002C150.755 56.2002 152.52 55.3995 152.52 53.6645C152.52 53.3976 152.385 52.9972 152.249 52.33Z" fill="#222222" />
                    <Path opacity="0.1" d="M66.0052 27.9073C61.9307 27.9073 58.8069 29.1084 56.498 31.3772C54.1891 33.6459 53.1026 37.2493 53.1026 42.1872C53.1026 46.5913 54.1891 49.9277 56.498 52.4634C58.8069 54.8656 62.3381 56.2002 66.9559 56.2002C68.0424 56.2002 69.4006 56.0667 70.8946 55.7998C72.3886 55.5329 73.7467 55.1325 74.8333 54.4652C75.9198 53.9314 76.4631 53.1306 76.4631 52.3299C76.4631 51.9295 76.3272 51.5292 76.0556 50.8619C75.784 50.1946 75.3765 49.6608 74.9691 49.1269C74.5616 48.5931 74.1542 48.3262 73.7467 48.3262C73.4751 48.3262 73.2035 48.4597 72.5244 48.7266C71.5737 49.1269 70.7587 49.3938 69.9438 49.5273C69.1289 49.7942 68.1782 49.9277 67.2275 49.9277C65.3261 49.9277 63.9679 49.5273 62.8814 48.7266C61.7948 47.9258 61.2516 46.4578 61.1158 44.4559H74.29C76.0556 44.4559 77.4138 43.1214 77.4138 41.3864C77.4138 37.3827 76.4631 34.1798 74.5616 31.6441C72.6602 29.1084 69.9439 27.9073 66.0052 27.9073ZM61.1158 39.3846C61.659 35.9147 63.2888 34.0463 65.8693 34.0463C67.0917 34.0463 68.1782 34.5801 68.7215 35.5143C69.4006 36.4485 69.6722 37.7831 69.6722 39.3846H61.1158Z" fill="#222222" />
                    <Path opacity="0.1" d="M42.6447 36.8489V36.582C44.2745 35.9147 45.6327 34.8471 46.7192 33.3791C47.8057 31.911 48.349 30.3096 48.349 28.5746C48.349 25.6386 47.3983 23.3698 45.361 21.7683C43.3238 20.1668 40.2 19.3661 35.8538 19.3661H23.6303C22.9513 19.3661 22.2722 19.4995 22.0005 19.8999C21.7289 20.1668 21.4573 20.7007 21.4573 21.5014V54.0649C21.4573 54.8656 21.5931 55.3995 22.0005 55.6664C22.2722 55.9333 22.8154 56.2002 23.6303 56.2002H36.3971C40.8791 56.2002 44.1387 55.266 46.4476 53.3976C48.6206 51.5292 49.843 48.9935 49.843 45.5236C49.843 43.5218 49.1639 41.6534 47.8057 40.0519C46.4476 38.317 44.6819 37.2493 42.6447 36.8489ZM29.7421 26.1724H35.039C38.2986 26.1724 39.9284 27.507 39.9284 30.0426C39.9284 31.2438 39.5209 32.178 38.5702 32.8452C37.7553 33.646 36.5329 33.9129 34.9031 33.9129H29.7421V26.1724ZM40.0642 48.0593C39.2493 48.8601 38.0269 49.127 36.3971 49.127H29.6063V40.7192H35.4464C37.3478 40.7192 38.706 41.1196 39.6567 41.7868C40.6074 42.4541 41.1507 43.5218 41.1507 44.8564C41.4223 46.3244 40.8791 47.392 40.0642 48.0593Z" fill="#222222" />
                    <Path opacity="0.1" d="M7.87558 40.4523C10.951 40.4523 13.4441 38.0025 13.4441 34.9806C13.4441 31.9586 10.951 29.5089 7.87558 29.5089C4.80019 29.5089 2.3071 31.9586 2.3071 34.9806C2.3071 38.0025 4.80019 40.4523 7.87558 40.4523Z" fill="#222222" />
                    <Path opacity="0.1" d="M6.65323 24.0372C9.72862 24.0372 12.2217 21.5874 12.2217 18.5654C12.2217 15.5435 9.72862 13.0937 6.65323 13.0937C3.57784 13.0937 1.08475 15.5435 1.08475 18.5654C1.08475 21.5874 3.57784 24.0372 6.65323 24.0372Z" fill="#222222" />
                    <Path opacity="0.1" d="M33.545 12.2929C36.6204 12.2929 39.1135 9.84311 39.1135 6.82116C39.1135 3.79921 36.6204 1.34943 33.545 1.34943C30.4696 1.34943 27.9765 3.79921 27.9765 6.82116C27.9765 9.84311 30.4696 12.2929 33.545 12.2929Z" fill="#222222" />
                    <Path opacity="0.1" d="M16.7037 12.8267C19.7791 12.8267 22.2722 10.3769 22.2722 7.35497C22.2722 4.33302 19.7791 1.88324 16.7037 1.88324C13.6283 1.88324 11.1352 4.33302 11.1352 7.35497C11.1352 10.3769 13.6283 12.8267 16.7037 12.8267Z" fill="#222222" />
                </G>
            </G>
            <Path d="M122.369 27.1066C118.295 27.1066 115.171 28.3077 112.862 30.5765C110.553 32.8453 109.467 36.4486 109.467 41.3865C109.467 45.7906 110.553 49.127 112.862 51.6627C115.171 54.0649 118.702 55.3995 123.32 55.3995C124.406 55.3995 125.765 55.266 127.259 54.9991C128.753 54.7322 130.111 54.3318 131.197 53.6646C132.284 53.1307 132.827 52.33 132.827 51.5292C132.827 51.1289 132.691 50.7285 132.42 50.0612C132.148 49.3939 131.741 48.8601 131.333 48.3263C130.926 47.7924 130.518 47.5255 130.111 47.5255C129.839 47.5255 129.567 47.659 128.888 47.9259C127.938 48.3263 127.123 48.5932 126.308 48.7266C125.493 48.9936 124.542 49.127 123.591 49.127C121.69 49.127 120.332 48.7266 119.245 47.9259C118.159 47.1252 117.616 45.6571 117.48 43.6553H130.654C132.42 43.6553 133.778 42.3207 133.778 40.5858C133.778 36.5821 132.827 33.3791 130.926 30.8434C129.024 28.3077 126.308 27.1066 122.369 27.1066ZM117.616 38.5839C118.159 35.114 119.789 33.2456 122.369 33.2456C123.591 33.2456 124.678 33.7795 125.221 34.7137C125.9 35.6479 126.172 36.9824 126.172 38.5839H117.616Z" fill="white" />
            <Path d="M103.762 20.9676C101.182 19.3661 98.1938 18.5654 94.9342 18.5654H83.7972C82.9823 18.5654 82.439 18.6988 82.1674 19.0992C81.8958 19.3661 81.6241 19.8999 81.6241 20.7007V53.798C81.6241 54.8656 82.7107 55.5329 84.8837 55.5329H86.6493C88.8224 55.5329 89.9089 54.9991 89.9089 53.798V41.6534H95.4774C96.8356 41.6534 98.6012 41.3865 100.367 40.7192C102.268 40.1854 103.898 38.9843 105.392 37.2493C106.886 35.5144 107.701 33.1122 107.701 30.0427C107.565 25.6386 106.343 22.5691 103.762 20.9676ZM97.7863 33.646C96.8356 34.3133 95.7491 34.5802 94.5267 34.5802H89.9089V25.3717H94.5267C95.7491 25.3717 96.8356 25.772 97.7863 26.4393C98.737 27.1066 99.1445 28.3077 99.1445 30.0427C99.1445 31.7776 98.737 32.9787 97.7863 33.646Z" fill="white" />
            <Path d="M151.434 51.5292C151.298 50.8619 151.162 50.3281 150.891 49.7942C150.619 49.2604 150.347 48.9935 150.076 48.9935C149.94 48.9935 149.668 48.9935 149.397 49.1269C149.125 49.2604 148.853 49.2604 148.718 49.2604C148.039 49.2604 147.495 49.1269 147.224 48.7266C146.952 48.3262 146.816 47.7924 146.816 46.9916V33.6459H149.397C149.94 33.6459 150.347 33.379 150.619 32.9787C150.891 32.5783 151.026 31.911 151.026 30.9768V30.0426C151.026 29.1084 150.891 28.4411 150.619 28.0408C150.347 27.6404 149.94 27.3735 149.397 27.3735H146.816V22.4356C146.816 21.3679 145.865 20.7006 144.1 20.7006H142.47C140.704 20.7006 139.754 21.2344 139.618 22.4356L138.939 27.3735H138.124C137.581 27.3735 137.173 27.6404 136.902 28.0408C136.63 28.4411 136.494 29.1084 136.494 30.0426V30.9768C136.494 31.911 136.63 32.5783 136.902 32.9787C137.173 33.379 137.581 33.6459 138.124 33.6459H138.939V46.8582C138.939 52.4634 141.519 55.3994 146.68 55.3994C150.076 55.3994 151.841 54.5987 151.841 52.8637C151.706 52.5968 151.706 52.1964 151.434 51.5292Z" fill="white" />
            <Path d="M65.1902 27.1066C61.1157 27.1066 57.9919 28.3077 55.6831 30.5765C53.3742 32.8453 52.1518 36.4486 52.1518 41.3865C52.1518 45.7906 53.2384 49.127 55.5472 51.6627C57.8561 54.0649 61.3874 55.3995 66.0051 55.3995C67.0917 55.3995 68.4498 55.266 69.9438 54.9991C71.4378 54.7322 72.796 54.3318 73.8825 53.6646C74.969 53.1307 75.5123 52.33 75.5123 51.5292C75.5123 51.1289 75.3765 50.7285 75.1049 50.0612C74.8332 49.3939 74.4258 48.8601 74.0183 48.3263C73.6109 47.7924 73.2034 47.5255 72.796 47.5255C72.5243 47.5255 72.2527 47.659 71.5736 47.9259C70.6229 48.3263 69.808 48.5932 68.9931 48.7266C68.1782 48.9936 67.2275 49.127 66.2768 49.127C64.3753 49.127 63.0172 48.7266 61.9306 47.9259C60.8441 47.1252 60.3008 45.6571 60.165 43.6553H73.3392C75.1049 43.6553 76.463 42.3207 76.463 40.5858C76.463 36.5821 75.5123 33.3791 73.6109 30.8434C71.9811 28.4412 69.1289 27.1066 65.1902 27.1066ZM60.3008 38.5839C60.8441 35.114 62.4739 33.2456 65.0544 33.2456C66.2768 33.2456 67.3633 33.7795 67.9066 34.7137C68.5857 35.6479 68.8573 36.9824 68.8573 38.5839H60.3008Z" fill="white" />
            <Path d="M41.8298 36.0482V35.7813C43.4596 35.114 44.8178 34.0464 45.9043 32.5783C46.9908 31.1103 47.5341 29.5088 47.5341 27.7739C47.5341 24.8378 46.5834 22.5691 44.5461 20.9676C42.5089 19.3661 39.3851 18.5654 35.039 18.5654H22.9513C22.2722 18.5654 21.5931 18.6988 21.3215 19.0992C21.0498 19.3661 20.7782 19.8999 20.7782 20.7007V53.2642C20.7782 54.0649 20.914 54.5987 21.3215 54.8656C21.5931 55.1326 22.1364 55.3995 22.9513 55.3995H35.718C40.2 55.3995 43.4596 54.4653 45.7685 52.5969C47.9415 50.7285 49.1639 48.1928 49.1639 44.7229C49.1639 42.7211 48.4848 40.8527 47.1266 39.2512C45.6327 37.5163 43.867 36.4486 41.8298 36.0482ZM28.9272 25.3717H34.2241C37.4837 25.3717 39.1135 26.7062 39.1135 29.2419C39.1135 30.443 38.706 31.3772 37.7553 32.0445C36.9404 32.8453 35.718 33.1122 34.0882 33.1122H28.9272V25.3717ZM39.2493 47.2586C38.4344 48.0593 37.212 48.3263 35.5822 48.3263H28.7914V40.0519H34.6315C36.5329 40.0519 37.8911 40.4523 38.8418 41.1196C39.7925 41.7869 40.3358 42.8545 40.3358 44.1891C40.6074 45.5237 40.2 46.5913 39.2493 47.2586Z" fill="white" />
            <Path d="M7.06071 39.6516C10.1361 39.6516 12.6292 37.2018 12.6292 34.1799C12.6292 31.1579 10.1361 28.7081 7.06071 28.7081C3.98531 28.7081 1.49222 31.1579 1.49222 34.1799C1.49222 37.2018 3.98531 39.6516 7.06071 39.6516Z" fill="url(#paint0_linear_1664_820)" />
            <Path d="M5.83835 23.2364C8.91374 23.2364 11.4068 20.7867 11.4068 17.7647C11.4068 14.7427 8.91374 12.293 5.83835 12.293C2.76296 12.293 0.269867 14.7427 0.269867 17.7647C0.269867 20.7867 2.76296 23.2364 5.83835 23.2364Z" fill="url(#paint1_linear_1664_820)" />
            <Path d="M32.7301 11.4922C35.8055 11.4922 38.2986 9.04239 38.2986 6.02044C38.2986 2.99848 35.8055 0.548706 32.7301 0.548706C29.6547 0.548706 27.1616 2.99848 27.1616 6.02044C27.1616 9.04239 29.6547 11.4922 32.7301 11.4922Z" fill="url(#paint2_linear_1664_820)" />
            <Path d="M16.0246 12.026C19.1 12.026 21.5931 9.57621 21.5931 6.55425C21.5931 3.5323 19.1 1.08252 16.0246 1.08252C12.9492 1.08252 10.4561 3.5323 10.4561 6.55425C10.4561 9.57621 12.9492 12.026 16.0246 12.026Z" fill="url(#paint3_linear_1664_820)" />
            <Defs>
                {/* <filter id="filter0_d_1664_820" x="0.584747" y="0.849426" width="153.436" height="56.9843" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="0.5" dy="0.5" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.4 0 0 0 0 0.4 0 0 0 0 0.4 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1664_820" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1664_820" result="shape" />
                </filter> */}
                <LinearGradient id="paint0_linear_1664_820" x1="7.0607" y1="28.7081" x2="7.0607" y2="39.6516" gradientUnits="userSpaceOnUse">
                    <Stop stopColor="#03E2FF" />
                    <Stop offset="1" stopColor="#35BEE6" />
                </LinearGradient>
                <LinearGradient id="paint1_linear_1664_820" x1="5.83835" y1="12.293" x2="5.83835" y2="23.2364" gradientUnits="userSpaceOnUse">
                    <Stop stopColor="#03E2FF" />
                    <Stop offset="1" stopColor="#35BEE6" />
                </LinearGradient>
                <LinearGradient id="paint2_linear_1664_820" x1="32.7301" y1="0.548706" x2="32.7301" y2="11.4922" gradientUnits="userSpaceOnUse">
                    <Stop stopColor="#03E2FF" />
                    <Stop offset="1" stopColor="#35BEE6" />
                </LinearGradient>
                <LinearGradient id="paint3_linear_1664_820" x1="16.0246" y1="1.08252" x2="16.0246" y2="12.026" gradientUnits="userSpaceOnUse">
                    <Stop stopColor="#03E2FF" />
                    <Stop offset="1" stopColor="#35BEE6" />
                </LinearGradient>
            </Defs>
        </Svg>
    )
}