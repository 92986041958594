import Reacr, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  Platform,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import { RootStackParamList } from "../../routes/auth.routes";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import theme from "../../theme";
import BepetLogoNegativo from "../../assets/bepet-logo-negativo";
import LoginShapeTop from "../../assets/login_shape";
import { SignInSocialButton } from "../../components/signSocialButton";
import GoogleLogo from "../../assets/google_logo";
import { MailIcon } from "../../assets/icons";

// defina o tipo de navegação
type LoginScreenNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  "Login"
>;

// defina o tipo das props
type LoginScreenProps = {
  navigation: LoginScreenNavigationProp;
};

export const Login = ({ navigation }: LoginScreenProps) => {
  const [userInfo, setUserInfo] = useState(null);
  const handleLogin = (provider: string) => {
    // Implemente a lógica de login aqui
    if (provider === "google") {
      // promptAsync();
    }
    if (provider === "facebook") {
      console.log("facebook");
    }
    if (provider === "apple") {
      console.log("apple");
    }
    if (provider === "phone") {
      navigation.navigate({
        name: "LoginPhoneInput",
        key: "LoginPhoneInput",
      });
    }
  };

  return (
    <View style={style.Container}>
      <View style={style.Header}>
        <LinearGradient
          style={style.HomeLinearGradient}
          colors={[theme.palette.primaryOrange, theme.palette.secondaryOrange]}
        />
        <View style={style.TitleWrapper}>
          <BepetLogoNegativo width={220} height={100} />
          <Text style={style.Title}>Bem-estar entre {"\n"} pessoas e pets</Text>
        </View>
        <View>
          <Text style={style.SignTitle}>
            Faça seu login com {"\n"} uma das contas abaixo
          </Text>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate({
                name: "OnboardStack",
                key: "OnboardStack",
              });
            }}
          >
            <Text style={style.SignText}>Ou crie uma conta agora mesmo</Text>
          </TouchableOpacity>
        </View>
      </View>

      <View style={style.Footer}>
        <LinearGradient
          style={style.HomeLinearGradient}
          colors={[theme.palette.primaryBlue, theme.palette.secondaryBlue]}
        />
        <View style={style.FooterWrapper}>
          {/* <SignInSocialButton
            onPress={() =>
              navigation.navigate({
                key: "CreatePetOnboardScreen",
                name: "CreatePetOnboardScreen",
              })
            }
            svg={GoogleLogo}
            title="Entrar com Google"
          /> */}
          <SignInSocialButton
            svg={<MailIcon size={22} />}
            onPress={() =>
              navigation.navigate({
                key: "LoginEmailStack",
                name: "LoginEmailStack",
              })
            }
            title="Entrar com e-mail"
          />
          {/* <SignInSocialButton
            onPress={() =>
              navigation.navigate({
                key: "LoginPhoneInput",
                name: "LoginPhoneInput",
              })
            }
            title="Entrar com telefone"
          /> */}
          {/* <SignInSocialButton svg={FcebookSvg} title="Entrar com Facebook" /> */}
          {/* <SignInSocialButton svg={AppleSvg} title="Entrar com Apple" /> */}
        </View>
      </View>
      <View style={style.TopShape}>
        <LoginShapeTop />
      </View>
    </View>
  );
};

const style = StyleSheet.create({
  Container: {
    flex: 1,
    position: "relative",
  },
  SignText: {
    color: theme.palette.white,
    fontFamily: theme.fonts.nunito[600],
    fontWeight: "700",
    fontSize: 15,
    textAlign: "center",
    marginTop: 15,
    height: 30,
    textDecorationLine: "underline",
    marginBottom: 40,
  },
  HomeLinearGradient: {
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  TopShape: {
    width: "100%",
    top: 0,
    alignItems: "flex-end",
    position: "absolute",
    right: 0,
  },
  Header: {
    width: "100%",
    height: "70%",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "relative",
  },
  TitleWrapper: {
    alignItems: "center",
  },
  Title: {
    color: theme.palette.white,
    fontFamily: theme.fonts.asap["700Italic"],
    fontWeight: "700",
    fontSize: 30,
    textAlign: "center",
    marginTop: 5,
  },
  SignTitle: {
    color: theme.palette.white,
    fontFamily: theme.fonts.nunito[600],
    fontWeight: "700",
    fontSize: 16,
    textAlign: "center",
    marginTop: 80,
    marginBottom: 10,
  },
  Footer: {
    width: "100%",
    height: "30%",
    position: "relative",
  },
  FooterWrapper: {
    marginTop: -28,
    paddingVertical: 0,
    paddingHorizontal: 32,
    justifyContent: "space-between",
  },
});
