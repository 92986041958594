import { Picker } from "@react-native-picker/picker";
import React from "react";
import { Platform } from "react-native";
import { View, Text, StyleSheet, Modal, TouchableOpacity } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import theme from "../theme";
import { Keyboard } from "react-native";
import DateTimePicker, {
  DateTimePickerAndroid,
} from "@react-native-community/datetimepicker";
import { CalendarIcon } from "../assets/icons";

interface SelectProps {
  onChange?: (value: Date) => void;
  value: Date | undefined;
  label?: string;
}

export const SelectDate: React.FC<SelectProps> = ({
  label,
  value,
  onChange,
}) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const selectRef = React.useRef<TouchableOpacity>(null);
  function handleChange(value: Date) {
    onChange && onChange(value);
  }
  return (
    <>
      {Platform.OS === "ios" && (
        <TouchableOpacity
          style={Platform.OS === "ios" ? styles.selectButton : {}}
          onPress={() => {
            setModalVisible(!modalVisible);
            Keyboard.dismiss();
          }}
        >
          <>
            <Text
              style={[
                styles.selectText,
                {
                  color: value ? theme.palette.black : theme.palette.gray2,
                },
              ]}
            >
              {value?.toDateString() || label || "Selecione uma data"}
            </Text>
            <CalendarIcon
              size={16}
              color={value ? theme.palette.black : theme.palette.gray2}
            />
          </>
        </TouchableOpacity>
      )}
      {Platform.OS === "android" && (
        <>
          <TouchableOpacity
            onPress={() => {
              DateTimePickerAndroid.open({
                value: value || new Date(),
                is24Hour: true,
                onChange: (event, date) => {
                  if (date) {
                    handleChange(date);
                  }
                },
              });
              Keyboard.dismiss();
            }}
            style={styles.picker}
          >
            <Text
              style={[
                styles.selectText,
                {
                  color: value ? theme.palette.black : theme.palette.gray2,
                },
              ]}
            >
              {value?.toDateString() || label || "Selecione uma data"}
            </Text>
            <CalendarIcon
              size={16}
              color={value ? theme.palette.black : theme.palette.gray2}
            />
          </TouchableOpacity>
        </>
      )}
      {Platform.OS === "web" && (
        <input
          placeholder={label || "Selecione uma data"}
          style={styles.picker}
          type={"date"}
          onChange={(event) => {
            if (event.target.value) {
              handleChange(new Date(event.target.value));
            }
          }}
        />
      )}

      {Platform.OS === "ios" && (
        <Modal
          transparent
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}
          animationType="fade"
        >
          <View style={styles.modalOverlay}>
            <TouchableOpacity
              style={styles.outside}
              onPress={() => {
                setModalVisible(!modalVisible);
              }}
            ></TouchableOpacity>
            <View style={styles.content}>
              <Text style={styles.labelSelect}>
                {label || "Selecione uma data"}
              </Text>
              <DateTimePicker
                testID="dateTimePicker"
                value={value || new Date()}
                mode={"date"}
                is24Hour={true}
                display="spinner"
                maximumDate={new Date()}
                themeVariant="light"
                onChange={(event, date) => {
                  if (date) {
                    handleChange(date);
                  }
                }}
              />
            </View>
          </View>
        </Modal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.2)",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  content: {
    backgroundColor: "#fff",
    width: "100%",
  },
  labelSelect: {
    fontFamily: theme.fonts.nunito[600],
    fontSize: 16,
    color: theme.palette.black,
    padding: 10,
    paddingLeft: 20,
  },
  outside: {
    flex: 1,
    width: "100%",
  },
  selectButton: {
    padding: 10,
    paddingLeft: 0,
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    flexDirection: "row",
  },
  selectText: {
    fontFamily: theme.fonts.nunito[500],
    fontSize: 16,
    marginRight: 10,
  },
  picker: {
    width: "100%",
    height: 40,
    backgroundColor: "transparent",
    borderWidth: 0,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectPicker: {
    // color: "#fff",
    backgroundColor: "transparent",
    fontSize: 16,
    marginRight: 10,
    borderWidth: 0,
    width: "100%",
    ...Platform.select({
      android: {
        opacity: 0,
        position: "absolute",
      },
    }),
  },
});
