import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  GestureResponderEvent,
  Platform,
} from "react-native";
import {
  RouteProp,
  TabNavigationState,
  NavigationHelpers,
} from "@react-navigation/native";
import { AntDesign, FontAwesome5, FontAwesome } from "@expo/vector-icons";
import { HomeIcon } from "../assets/icons/home";
import { ServicesIcon } from "../assets/icons/services";
import { CalendarIcon } from "../assets/icons/calendar";
import theme from "../theme";

type RootTabParamList = {
  [key: string]:
    | {
        title: string;
      }
    | undefined
    | object;
};

type Props = {
  state: TabNavigationState<RootTabParamList>;
  navigation: NavigationHelpers<Record<string, object | undefined>>;
};

const icons = (props: { focused: boolean }) => [
  <HomeIcon key={1} selected={props.focused} />,
  <ServicesIcon key={2} selected={props.focused} height={21} />,
  <CalendarIcon key={3} size={21} color={theme.palette.gray2} />,
  <FontAwesome5 key={4} name="file-invoice-dollar" size={30} />,
  <FontAwesome5 key={5} name="file-signature" size={30} />,
];

export const TabBar: React.FC<Props> = ({ state, navigation }) => {
  return (
    <View style={styles.container}>
      {state.routes.map((route: any, index) => {
        return (
          <TouchableOpacity
            key={route.key}
            accessibilityRole="button"
            onPress={() => {
              navigation.navigate(route.name);
            }}
            style={styles.iconButton}
            accessibilityState={state.index === index ? { selected: true } : {}}
          >
            <Text>
              {icons({
                focused: state.index === index,
              })[index] || route.name}
            </Text>
            <Text
              style={
                state.index === index
                  ? styles.iconTextSelected
                  : styles.iconText
              }
            >
              {route.params?.title || route.name}
            </Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.palette.white,
    flexDirection: "row",
    padding: 13,
    paddingBottom: 18,
    justifyContent: "center",
    gap: 20,
    elevation: 5,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: -1 },
    shadowOpacity: 0.1,
    shadowRadius: 17,
    ...Platform.select({
      ios: {
        paddingBottom: 25,
      },
      web: {
        paddingBottom: 22,
      },
    }),
  },
  iconButton: {
    alignItems: "center",
    margin: 0,
    width: 80,
    backgroundColor: theme.palette.white,
  },
  iconText: {
    color: theme.palette.gray1,
    fontWeight: "400",
    fontFamily: theme.fonts.nunito[400],
  },
  iconTextSelected: {
    color: theme.palette.secondaryBlue,
    fontWeight: "400",
    fontFamily: theme.fonts.nunito[800],
  },
});
