import React from "react";

import { Svg, Path, Mask, G } from "react-native-svg";
interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: number;
}
export const BackIcon = (props: IconProps) => {
  return (
    <Svg
      width={props.size || 26}
      height={(47 / 26) * (props.size || 47)}
      viewBox="0 0 26 47"
      fill="none"
    >
      <Path
        d="M24.7296 0.656056C25.5248 1.45127 25.5971 2.69566 24.9465 3.57255L24.7296 3.82377L5.40863 23.1458L24.7296 42.4678C25.5248 43.263 25.5971 44.5074 24.9465 45.3843L24.7296 45.6355C23.9344 46.4307 22.69 46.503 21.8131 45.8524L21.5619 45.6355L0.656042 24.7296C-0.139177 23.9344 -0.211471 22.69 0.439163 21.8131L0.656042 21.5619L21.5619 0.656056C22.4366 -0.218685 23.8549 -0.218685 24.7296 0.656056Z"
        fill={props.color || "#222222"}
      />
    </Svg>
  );
};
