import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Service } from "../types";
import api from "../services/api";
import { useAuth } from "./useAuth";
interface ServiceProviderProps {
  children: ReactNode;
}

interface ServiceContextData {
  fetchServices: (specie: string, size: string) => Promise<Service[]>;
  services: Service[];
  loadingService: boolean;
  chooseService: (serviceId: string) => void;
  selectedService: Service | null;
  selectedDate: string;
  setSelectedDate: (date: string) => void;
  cancelService: (next: () => void) => void;
  fetchService: (id: string, size: string) => Promise<Service>;
}

const ServiceContext = createContext({} as ServiceContextData);

function ServiceProvider({ children }: ServiceProviderProps) {
  const { logOut } = useAuth();

  const [services, setServices] = useState<Service[]>([]);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [loadingService, setLoadingService] = useState(true);
  const [selectedDate, setSelectedDate] = useState("");
  function chooseService(serviceId: string) {
    const service = services.find((service) => service.id === serviceId);
    if (service) {
      setSelectedService(service);
    }
  }
  const fetchServices = useCallback((specie: string, size: string) => {
    return new Promise<Service[]>((resolve, reject) => {
      api
        .get(`v1/service/?specie_id=${specie}&pet_size=${size}`)
        .then((response) => {
          setServices(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.status === 401) {
            logOut();
          }
          reject(error);
        });
    });
  }, []);
  const fetchService = useCallback((id: string, size: string) => {
    return new Promise<Service>((resolve, reject) => {
      setLoadingService(true);
      setSelectedService(null);
      api
        .get(`v1/service/${id}/?pet_size=${size}`)
        .then((response) => {
          setSelectedService(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.status === 401) {
            logOut();
          }
          reject(error);
        })
        .finally(() => {
          setLoadingService(false);
        });
    });
  }, []);

  function cancelService(next: () => void) {
    setSelectedService(null);
    setSelectedDate("");
    next && next();
  }

  return (
    <ServiceContext.Provider
      value={{
        chooseService,
        fetchServices,
        services,
        loadingService,
        selectedService,
        cancelService,
        selectedDate,
        setSelectedDate,
        fetchService,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
}

function useService() {
  const context = useContext(ServiceContext);
  return context;
}

export { ServiceProvider, useService };
