import React from "react";
import { StyleSheet} from "react-native";
import theme from "../../theme";
import { LinearGradient } from "expo-linear-gradient";
import BepetLogoNegativo from "../../assets/bepet-logo-negativo";

interface LoadSplashProps {
}

export const LoadSplash: React.FC<LoadSplashProps> = () => {
  return (
    <LinearGradient
      colors={[theme.palette.primaryOrange, theme.palette.secondaryOrange]}
      style={styles.container}
    >
      <BepetLogoNegativo />
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.palette.orangeGradient,
    alignItems: "center",
    justifyContent: "center",
  },
  photo: {
    width: 200,
    height: 200,
    resizeMode: "contain",
    alignSelf: "center",
    marginTop: 200,
  },
});
