import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import api from "../services/api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { User } from "../types/user";

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextData {
  user: User | null;
  loadingAuth: boolean;
  logOut: () => void;
  createAccount: (userCreate: userCreate) => void;
  loginEmail: (password: string) => void;
  setEmailLogin: (email: string) => void;
  getUser: () => Promise<User>;
  loginEmailError: string;
  setLoginEmailError: (error: string) => void;
  createAccountError: string;
  setCreateAccountError: (error: string) => void;
  createAccountLoading: boolean;
  splashLoading: boolean;
}
interface userCreate {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  code: string;
}

const AuthContext = createContext({} as AuthContextData);

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [emailLogin, setEmailLogin] = useState<string>("");
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loginEmailError, setLoginEmailError] = useState("");
  const [createAccountLoading, setCreateAccountLoading] = useState(false);
  const [createAccountError, setCreateAccountError] = useState("");
  const [splashLoading, setSplashLoading] = useState(true);
  const hasUpdate = useRef(false);
  function logOut() {
    setUser(null);
    AsyncStorage.removeItem("@bepet-user-data");
  }
  const storeLoginData = async (value: {
    accessToken: string;
    refreshToken: string;
  }) => {
    return new Promise((resolve, reject) => {
      AsyncStorage.setItem("@bepet-user-data", JSON.stringify(value))
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  async function loadStorageData() {
    setSplashLoading(true);
    const storageUser = await AsyncStorage.getItem("@bepet-user-data");
    if (storageUser) {
      const userLogged = JSON.parse(storageUser);
      getUser().finally(() => {
        setSplashLoading(false);
      });
    } else {
      setSplashLoading(false);
    }
  }

  const getUser = useCallback(async () => {
    return new Promise<User>((resolve, reject) => {
      api
        .get("/v1/customer/")
        .then((response) => {
          setUser(response.data);
          resolve(response.data as User);
        })
        .catch((error) => {
          reject(error);
          if (error.response.status === 401 || error.response.status === 404) {
            logOut();
          }
        });
    });
  }, []);

  useEffect(() => {
    if (!hasUpdate.current) {
      loadStorageData();
      hasUpdate.current = true;
    }
  }, []);

  function createAccount(userCreate: userCreate) {
    setCreateAccountError("");
    setCreateAccountLoading(true);
    api
      .post("v1/auth/register/", userCreate)
      .then((response) => {
        storeLoginData(response.data).then(() => {
          loadStorageData();
        });
      })
      .catch((error) => {
        console.log(error.response.data.detail);
        if (error?.response?.data?.detail) {
          setCreateAccountError(error?.response?.data?.detail || "Erro ao criar conta!");
        }
      })
      .finally(() => {
        setCreateAccountLoading(false);
      });
  }

  function loginEmail(password: string) {
    setLoadingAuth(true);
    setLoginEmailError("");
    api
      .post("/v1/auth/login", {
        login: emailLogin,
        login_type: "1",
        password: password,
      })
      .then((response) => {
        storeLoginData(response.data).then(() => {
          loadStorageData();
        });
      })
      .catch((error) => {
        console.log(error);
        setLoginEmailError(error.response.data.detail);
        if (error.response.status === 401) {
          setLoginEmailError("Email ou senha inválidos!");
        }
        if (error.response.status === 404) {
          setLoginEmailError("Email inxistente, por favor crie uma conta!");
        }
      })
      .finally(() => {
        setLoadingAuth(false);
      });
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        logOut,
        loadingAuth,
        createAccount,
        loginEmail,
        setEmailLogin,
        getUser,
        loginEmailError,
        setLoginEmailError,
        createAccountError,
        setCreateAccountError,
        createAccountLoading,
        splashLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export { AuthProvider, useAuth };
