import * as Svg from 'react-native-svg';

export default function LoginShapeTop() {
	return (
		<Svg.Svg width="325" height="80" viewBox="0 0 325 80" fill="none">
			<Svg.Path d="M66.534 33.9307C20.5522 33.039 4.22628 11.8884 1.39877e-05 7.62939e-06L325 6.44542e-05L325 76.5316C294.148 81.3613 249.554 84.3615 206.024 62.8138C162.493 41.2661 124.011 35.0452 66.534 33.9307Z" fill="url(#paint0_linear_453_14327)" />
			<Svg.Defs>
				<Svg.LinearGradient id="paint0_linear_453_14327" x1="193.359" y1="39.8195" x2="235.141" y2="-11.3304" gradientUnits="userSpaceOnUse">
					<Svg.Stop stopColor="#03E2FF" />
					<Svg.Stop offset="1" stopColor="#35BEE6" />
				</Svg.LinearGradient>
			</Svg.Defs>
		</Svg.Svg>
	)
}