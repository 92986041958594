import React, { useCallback } from "react";
import { StatusBar } from "expo-status-bar";
import { AuthProvider } from "./src/hooks/useAuth";
import { Routes } from "./src/routes";
import "react-native-gesture-handler";
import * as SplashScreen from "expo-splash-screen";
import { View } from "react-native";
import "moment/locale/pt-br";

import { Asap_700Bold_Italic, Asap_700Bold } from "@expo-google-fonts/asap";
import { useFonts } from "expo-font";

import {
  Nunito_800ExtraBold,
  Nunito_400Regular,
  Nunito_600SemiBold,
  Nunito_500Medium,
  Nunito_700Bold,
} from "@expo-google-fonts/nunito";

export default function App() {
  const [fontsLoaded] = useFonts({
    Asap_700Bold_Italic,
    Nunito_800ExtraBold,
    Nunito_400Regular,
    Nunito_600SemiBold,
    Nunito_500Medium,
    Asap_700Bold,
    Nunito_700Bold,
  });
  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
      <AuthProvider>
        <Routes />
        <StatusBar style="auto" />
      </AuthProvider>
    </View>
  );
}
