import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Platform,
  FlatList,
  ActivityIndicator,
} from "react-native";
import theme from "../../theme";
import moment from "moment";
import { ButtonFooter } from "../../components/ButtonFooter";
import { ScreenFlowHeader } from "../../components/ScreenFlowHeader";
import { MomentService } from "../../services/moment";
import api from "../../services/api";
import { useService } from "../../hooks/useService";
import { useAuth } from "../../hooks/useAuth";
interface Props {
  navigation: any;
}

export const ServiceDateSelect: React.FC<Props> = ({ navigation }) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [times, setTimes] = useState<string[]>([]);
  const { getMoment } = MomentService;
  const { selectedService, setSelectedDate: setDate } = useService();
  const [loadingTimes, setLoadingTimes] = useState(false);
  const [days, setDays] = useState<{
    date: string;
    day: number;
  }[]>([]);
  const { logOut } = useAuth();
  const getTimes = (date: string) => {
    setSelectedDate(date);
    setLoadingTimes(true);
    api
      .get(
        `/v1/service/times/${selectedService?.employee_detail[0].id}/?date=${date}&service_id=${selectedService?.id}`
      )
      .then((response) => {
        setTimes(response.data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingTimes(false);
      });
  };
  useEffect(() => {
    api
      .get(`/v1/service/dates/${selectedService?.employee_detail[0].id}/`)
      .then((response) => {
        setDays(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.status === 401) {
          logOut();
        }
      });
  }, []);
  return (
    <View style={styles.container}>
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
      <ScreenFlowHeader
        title="Selecione um horário"
        buttonAction={() => navigation.goBack()}
      />
      <FlatList
        showsHorizontalScrollIndicator={false}
        style={styles.calendarList}
        data={days.map((day) => getMoment(day.date))}
        renderItem={({ item }) => (
          <View style={styles.calendarContainer}>
            <Text style={styles.calendarMonth}>
              {item.format("D") === "1" && item.format("MMMM")}
            </Text>
            <TouchableOpacity
              style={[
                styles.calendarItem,
                {
                  borderColor:
                    item.format("YYYY-MM-DD") === selectedDate
                      ? theme.palette.primaryOrange
                      : theme.palette.gray3,
                },
              ]}
              onPress={() => getTimes(item.format("YYYY-MM-DD"))}
            >
              <Text style={styles.calendarWeek}>
                {item.format("d/MM/YYYY") === getMoment().format("d/MM/YYYY")
                  ? "Hoje"
                  : item.format("ddd.")}
              </Text>
              <Text
                style={[
                  styles.calendarDay,
                  {
                    color:
                      item.format("YYYY-MM-DD") === selectedDate
                        ? theme.palette.primaryOrange
                        : theme.palette.black,
                  },
                ]}
              >
                {item.format("DD")}
              </Text>
            </TouchableOpacity>
          </View>
        )}
        horizontal={true}
        keyExtractor={(item) => item.format("YYYY-MM-DD")}
      />
      <Text style={styles.scheduleTitle}>Horário</Text>
      {times.length === 0 && !selectedDate && (
        <Text style={styles.scheduleItemNoTimes}>Selecione uma data</Text>
      )}
      {times.length === 0 && selectedDate && !loadingTimes && (
        <Text style={styles.scheduleItemNoTimes}>
          Não há horários disponíveis para essa data
        </Text>
      )}
      {loadingTimes && (
        <ActivityIndicator size="large" color={theme.palette.primaryOrange} />
      )}
      <FlatList
        style={{ flex: 1 }}
        data={times}
        keyExtractor={(item) => item}
        renderItem={({ item }) => (
          <TouchableOpacity
            style={styles.scheduleItem}
            key={item}
            onPress={() => setSelectedTime(item)}
          >
            <Text style={styles.scheduleItemText}>
              {item} -{" "}
              {moment(item, "HH:mm")
                .add(selectedService?.duration, "minutes")
                .format("HH:mm")}
            </Text>
            <View
              style={[
                styles.scheduleItemIcon,
                {
                  backgroundColor:
                    item === selectedTime
                      ? theme.palette.primaryOrange
                      : theme.palette.gray3,
                },
              ]}
            >
              {item === selectedTime && (
                <View style={styles.itemTimeSelected}></View>
              )}
            </View>
          </TouchableOpacity>
        )}
      />

      <ButtonFooter
        disabled={selectedTime === ""}
        buttonText="Confirmar"
        buttonAction={() => {
          setDate(selectedDate + " " + selectedTime);
          navigation.goBack();
        }}
      ></ButtonFooter>
    </View>
  );
};

const styles = StyleSheet.create({
  scheduleItemNoTimes: {
    color: theme.palette.gray1,
    fontSize: 14,
    fontFamily: theme.fonts.nunito[400],
    textAlign: "center",
    marginTop: 20,
  },
  itemTimeSelected: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.white,
  },
  container: {
    flex: 1,
    // backgroundColor: theme.palette.littleOrange,
    position: "relative",
  },
  calendarList: {
    padding: 20,
    flexDirection: "row",
    flexGrow: 0,
  },
  calendarMonth: {
    color: theme.palette.primaryBlue,
    fontSize: 13,
    fontWeight: "bold",
    marginBottom: 6,
    fontFamily: theme.fonts.asap["700Italic"],
  },
  calendarContainer: {
    marginRight: 10,
  },
  calendarItem: {
    borderRadius: 4,
    width: 60,
    height: 60,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: theme.palette.gray3,
  },
  calendarWeek: {
    color: theme.palette.gray1,
    fontSize: 13,
    fontFamily: theme.fonts.nunito[500],
  },
  calendarDay: {
    color: theme.palette.black,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: theme.fonts.asap["700Italic"],
  },

  scheduleTitle: {
    color: theme.palette.black,
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 6,
    fontFamily: theme.fonts.asap["700Italic"],
    marginLeft: 24,
  },
  scheduleItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 4,
    padding: 12,
    marginBottom: 10,
    marginHorizontal: 24,
  },
  scheduleItemText: {
    color: theme.palette.black,
    fontSize: 14,
    fontFamily: theme.fonts.nunito[500],
  },
  scheduleItemIcon: {
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: theme.palette.gray3,
    alignItems: "center",
    justifyContent: "center",
  },
});
