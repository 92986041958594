import React, { useState } from "react";
import { FontAwesome } from "@expo/vector-icons";
import theme from "../../../theme";
import { OnboardingPage } from "../../../components/OnboardingPage";
import { RootStackParamList } from "../../../routes/auth.routes";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useOnboarding } from "../../../hooks/useOboarding";
type LoginScreenNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  "NameCreateScreen"
>;
type LoginScreenProps = {
  navigation: LoginScreenNavigationProp;
};

export const NameCreateScreen: React.FC<LoginScreenProps> = ({
  navigation,
}) => {
  const [name, setName] = useState("");
  const { sendName } = useOnboarding();
  return (
    <OnboardingPage
      title="Como você se chama?"
      caption="Por favor, digite seu nome completo."
      headerButtonAction={() => navigation.goBack()}
      headerButtonIcon={
        <FontAwesome
          name="chevron-left"
          size={24}
          color={theme.palette.secondaryOrange}
        />
      }
      buttonAction={() =>
        sendName(name, () =>
          navigation.navigate({
            name: "EmailCreateScreen",
            key: "EmailCreateScreen",
          })
        )
      }
      buttonText="Próximo"
      value={name}
      onChangeText={(text) => setName(text)}
      disabled={name.split(" ").length < 2 || name.split(' ')[0].length < 3}
    />
  );
};
