import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { View, Text, ImageBackground, StyleSheet } from "react-native";
import theme from "../theme";

interface Props {
  title: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}

export const PageHeader: React.FC<Props> = ({ title, children, icon }) => {
  return (
    <View>
      <ImageBackground
        source={require("../assets/headerdetail.png")}
        style={styles.datailbg}
      />
      <LinearGradient
        colors={[theme.palette.white, theme.palette.littleOrange]}
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1 }}
        style={styles.header}
      >
        <View style={styles.headerTitle}>
          {icon && icon}
          <Text style={styles.headerTitleText}>{title}</Text>
        </View>
        {children && children}
      </LinearGradient>
      <ImageBackground
        source={require("../assets/larangicabottomdetail.png")}
        style={styles.datailbg}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  datailbg: {
    width: "100%",
    height: 24,
    resizeMode: "stretch",
    zIndex: 3,
  },
  headerTitle: {
    flexDirection: "row",
    alignItems: "center",
    gap: 23,
  },
  headerTitleText: {
    fontSize: 30,
    fontWeight: "bold",
    fontFamily: theme.fonts.asap["700Italic"],
    color: theme.palette.black,
  },
  header: {
    paddingHorizontal: 26,
    paddingVertical: 32,
    paddingBottom: 16,
    width: "100%",
    marginTop: -10,
    zIndex: 2,
  },
});
