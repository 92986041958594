import { StyleSheet } from "react-native";


import theme from "../../theme";

export const style = StyleSheet.create({
  Button: {
    height: 56,
    backgroundColor: theme.palette.white,
    borderRadius: 28,
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 16,
    // marginTop: -28,
  },
  Text: {
    flex: 1,
    textAlign: "center",
    fontFamily: theme.fonts.nunito[600],
    fontWeight: "600",
    fontSize: 14,
  },
  ImageContainer: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 55,

    padding: 16,
    borderColor: theme.palette.gray3,
    borderRightWidth: 1,
  },
});
