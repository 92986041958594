import React from "react";

import { Svg, Path, Mask, G } from "react-native-svg";
interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: number;
  filled?: boolean;
}
export const StarIcon = (props: IconProps) => {
  return props.filled ? (
    <Svg
      width={props.size || 21}
      height={(20 / 21) * (props.size || 20)}
      viewBox="0 0 21 20"
      fill="none"
    >
      <Path
        d="M15.9184 12.3202C15.6594 12.5712 15.5404 12.9342 15.5994 13.2902L16.4884 18.2102C16.5634 18.6272 16.3874 19.0492 16.0384 19.2902C15.6964 19.5402 15.2414 19.5702 14.8684 19.3702L10.4394 17.0602C10.2854 16.9782 10.1144 16.9342 9.93939 16.9292H9.66839C9.57439 16.9432 9.48239 16.9732 9.39839 17.0192L4.96839 19.3402C4.74939 19.4502 4.50139 19.4892 4.25839 19.4502C3.66639 19.3382 3.27139 18.7742 3.36839 18.1792L4.25839 13.2592C4.31739 12.9002 4.19839 12.5352 3.93939 12.2802L0.328388 8.78024C0.0263875 8.48724 -0.0786125 8.04724 0.0593875 7.65024C0.193388 7.25424 0.535388 6.96524 0.948388 6.90024L5.91839 6.17924C6.29639 6.14024 6.62839 5.91024 6.79839 5.57024L8.98839 1.08024C9.04039 0.980244 9.10739 0.888244 9.18839 0.810244L9.27839 0.740244C9.32539 0.688244 9.37939 0.645244 9.43939 0.610244L9.54839 0.570244L9.71839 0.500244H10.1394C10.5154 0.539244 10.8464 0.764244 11.0194 1.10024L13.2384 5.57024C13.3984 5.89724 13.7094 6.12424 14.0684 6.17924L19.0384 6.90024C19.4584 6.96024 19.8094 7.25024 19.9484 7.65024C20.0794 8.05124 19.9664 8.49124 19.6584 8.78024L15.9184 12.3202Z"
        fill={props.color || "#222222"}
      />
    </Svg>
  ) : (
    <Svg
      width={props.size || 21}
      height={(20 / 21) * (props.size || 20)}
      viewBox="0 0 21 20"
      fill="none"
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1043 2.17713L12.9317 5.82788C13.1108 6.18628 13.4565 6.4348 13.8573 6.4923L17.9453 7.08074C18.9554 7.22656 19.3573 8.45067 18.6263 9.15206L15.6702 11.9926C15.3797 12.2719 15.2474 12.6734 15.3162 13.0678L16.0138 17.0779C16.1856 18.07 15.1298 18.8268 14.227 18.3575L10.5732 16.4628C10.215 16.2769 9.78602 16.2769 9.42679 16.4628L5.773 18.3575C4.87023 18.8268 3.81439 18.07 3.98724 17.0779L4.68385 13.0678C4.75257 12.6734 4.62033 12.2719 4.32982 11.9926L1.37368 9.15206C0.642715 8.45067 1.04464 7.22656 2.05466 7.08074L6.14265 6.4923C6.54354 6.4348 6.89028 6.18628 7.06937 5.82788L8.89574 2.17713C9.34765 1.27445 10.6523 1.27445 11.1043 2.17713Z"
        stroke={props.color || "#222222"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};
