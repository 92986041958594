import React, { useCallback, useState } from "react";
import { FontAwesome } from "@expo/vector-icons";
import theme from "../../../theme";
import { OnboardingPage } from "../../../components/OnboardingPage";
import { RootStackParamList } from "../../../routes/auth.routes";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useOnboarding } from "../../../hooks/useOboarding";
import { emailValidator } from "../../../assets/utils";
import api from "../../../services/api";
type LoginScreenNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  "EmailCreateScreen"
>;
type LoginScreenProps = {
  navigation: LoginScreenNavigationProp;
};
export const EmailCreateScreen: React.FC<LoginScreenProps> = ({
  navigation,
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { sendEmail } = useOnboarding();
  const [loading, setLoading] = useState(false);
  const validateEmail = useCallback(() => {
    setLoading(true);
    setError("");
    api
      .post("/v1/auth/verify/user/email", { email: email })
      .then(() => {
        sendEmail(email, () =>
          navigation.navigate({
            name: "CreatePhoneInput",
            key: "CreatePhoneInput",
          })
        );
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [email]);

  return (
    <OnboardingPage
      title="Qual o seu email?"
      caption="Digite o seu melhor email para criar sua conta."
      headerButtonAction={() => navigation.goBack()}
      headerButtonIcon={
        <FontAwesome
          name="chevron-left"
          size={24}
          color={theme.palette.secondaryOrange}
        />
      }
      buttonAction={() => validateEmail()}
      buttonText="Próximo"
      value={email}
      onChangeText={(text) => setEmail(text)}
      disabled={!emailValidator(email)}
      alertText={error}
      textInputProps={{
        keyboardType: "email-address",
        autoCapitalize: "none",
        autoCorrect: false,
      }}
      loading={loading}
    />
  );
};
