import React from "react";
import { View, Text, TouchableHighlight, StyleSheet, Platform } from "react-native";
import theme from "../theme";

interface Props {
  buttonText: string;
  buttonAction?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
}

export const ButtonFooter: React.FC<Props> = ({
  buttonText,
  buttonAction,
  children,
  disabled,
}: Props) => {
  return (
    <View style={styles.footer}>
      {children}
      <TouchableHighlight
        disabled={disabled}
        style={[styles.button, {
          opacity: disabled ? 0.5 : 1,
        }]}
        underlayColor={theme.palette.secondaryOrange}
        onPress={() => buttonAction && buttonAction()}
      >
        <Text style={styles.buttonText}>{buttonText}</Text>
      </TouchableHighlight>
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    paddingHorizontal: 14,
    paddingVertical: 20,
    borderTopWidth: 1,
    borderTopColor: theme.palette.gray3,
    backgroundColor: theme.palette.white,
    ...Platform.select({
      ios: {
        paddingBottom: 42,
      },
    }),
  },
  button: {
    paddingVertical: 14,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primaryOrange,
  },
  buttonText: {
    fontSize: 14,
    color: theme.palette.white,
    fontFamily: theme.fonts.nunito[700],
    fontWeight: "bold",
  },
});
