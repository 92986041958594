import React from "react";

import { Svg, Path } from "react-native-svg";
interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: number;
}
export const HelpIcon = (props: IconProps) => {
  return (
    <Svg
      width={props.size || 57}
      height={(60 / 57) * (props.size || 60)}
      viewBox="0 0 57 60"
      fill="none"
    >
      <Path
        d="M51.3416 1.4724C51.2154 1.40928 51.1523 1.34616 51.0262 1.21993C44.4666 -2.50411 37.4024 3.1135 32.2935 7.15313C30.5274 8.54175 27.8153 10.6878 26.9322 10.814C25.86 10.8772 12.3623 10.9403 11.5424 10.9403C8.64102 11.0665 6.30732 11.1296 3.59517 13.3388C0.315366 16.0529 0 21.4181 0 26.0889V27.6037V28.4243V29.2448V30.7597C0 35.4936 0.315366 40.7957 3.59517 43.5098C6.30732 45.7821 8.64102 45.8452 11.5424 45.9083C11.6685 45.9083 12.2993 45.9083 13.2454 45.9083V57.2051C13.2454 58.5937 14.3807 59.7298 15.7683 59.7298C17.1559 59.7298 18.2912 58.5937 18.2912 57.2051V45.9714C22.1387 45.9714 26.3646 46.0345 26.9322 46.0977C27.8783 46.2239 30.5274 48.3068 32.2935 49.7586C36.204 52.8514 41.3129 56.8279 46.3588 56.8279C47.8725 56.8279 49.4494 56.4492 50.9631 55.5655C51.0893 55.5024 51.2154 55.4393 51.2785 55.3131C56.3874 51.2734 56.6397 43.6991 56.6397 28.3612V26.9726C56.6397 12.5814 56.2613 5.38579 51.3416 1.4724ZM11.7316 40.4169C9.01946 40.2907 8.32566 40.2907 7.06419 39.2177C5.67658 38.0815 5.48737 33.7894 5.48737 30.5703V29.2448V28.4243V27.6037V26.2782C5.48737 23.0592 5.67658 18.7671 7.06419 17.5678C8.32566 16.5579 9.01946 16.4948 11.7316 16.3685C11.8578 16.3685 12.4254 16.3685 13.2454 16.3685V40.4169C12.4254 40.4801 11.8578 40.4169 11.7316 40.4169ZM51.1523 29.7498C51.1523 42.563 50.7739 48.6224 48.0618 50.9578C44.7819 52.5989 39.7361 48.6224 35.6994 45.4034C32.4196 42.8155 29.8967 40.7957 27.3107 40.6063C26.68 40.5432 22.2648 40.5432 18.2281 40.4801V16.3685C22.2018 16.3685 26.68 16.3054 27.3107 16.2423C29.8967 16.0529 32.4196 14.0331 35.6994 11.4452C39.1054 8.73111 43.142 5.51203 46.3588 5.51203C46.9895 5.51203 47.5572 5.63827 48.0618 5.89075C50.837 8.28927 51.1523 14.6643 51.1523 28.4243V29.7498Z"
        fill={props.color || "#222222"}
      />
    </Svg>
  );
};
