import React from "react";

import { Svg, Path } from "react-native-svg";
interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: number;
}
export const CalendarIcon = (props: IconProps) => {
  return (
    <Svg
      width={props.size || 20}
      height={(22 / 20) * (props.size || 20)}
      viewBox="0 0 20 22"
      fill="none"
      //   xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.6665 8.90442H0.8425C0.4285 8.90442 0.0925 8.56842 0.0925 8.15442C0.0925 7.74042 0.4285 7.40442 0.8425 7.40442H18.6665C19.0805 7.40442 19.4165 7.74042 19.4165 8.15442C19.4165 8.56842 19.0805 8.90442 18.6665 8.90442Z"
        fill={props.color || "#222222"}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2012 12.8097C13.7872 12.8097 13.4472 12.4737 13.4472 12.0597C13.4472 11.6457 13.7782 11.3097 14.1922 11.3097H14.2012C14.6152 11.3097 14.9512 11.6457 14.9512 12.0597C14.9512 12.4737 14.6152 12.8097 14.2012 12.8097Z"
        fill={props.color || "#222222"}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.7637 12.8097C9.3497 12.8097 9.0097 12.4737 9.0097 12.0597C9.0097 11.6457 9.3407 11.3097 9.7547 11.3097H9.7637C10.1777 11.3097 10.5137 11.6457 10.5137 12.0597C10.5137 12.4737 10.1777 12.8097 9.7637 12.8097Z"
        fill={props.color || "#222222"}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.3169 12.8097C4.9029 12.8097 4.5619 12.4737 4.5619 12.0597C4.5619 11.6457 4.8939 11.3097 5.3079 11.3097H5.3169C5.7309 11.3097 6.0669 11.6457 6.0669 12.0597C6.0669 12.4737 5.7309 12.8097 5.3169 12.8097Z"
        fill={props.color || "#222222"}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2012 16.6964C13.7872 16.6964 13.4472 16.3604 13.4472 15.9464C13.4472 15.5324 13.7782 15.1964 14.1922 15.1964H14.2012C14.6152 15.1964 14.9512 15.5324 14.9512 15.9464C14.9512 16.3604 14.6152 16.6964 14.2012 16.6964Z"
        fill={props.color || "#222222"}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.7637 16.6964C9.3497 16.6964 9.0097 16.3604 9.0097 15.9464C9.0097 15.5324 9.3407 15.1964 9.7547 15.1964H9.7637C10.1777 15.1964 10.5137 15.5324 10.5137 15.9464C10.5137 16.3604 10.1777 16.6964 9.7637 16.6964Z"
        fill={props.color || "#222222"}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.3169 16.6964C4.9029 16.6964 4.5619 16.3604 4.5619 15.9464C4.5619 15.5324 4.8939 15.1964 5.3079 15.1964H5.3169C5.7309 15.1964 6.0669 15.5324 6.0669 15.9464C6.0669 16.3604 5.7309 16.6964 5.3169 16.6964Z"
        fill={props.color || "#222222"}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7935 4.79112C13.3795 4.79112 13.0435 4.45512 13.0435 4.04112V0.750122C13.0435 0.336122 13.3795 0.00012207 13.7935 0.00012207C14.2075 0.00012207 14.5435 0.336122 14.5435 0.750122V4.04112C14.5435 4.45512 14.2075 4.79112 13.7935 4.79112Z"
        fill={props.color || "#222222"}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.7153 4.79112C5.3013 4.79112 4.9653 4.45512 4.9653 4.04112V0.750122C4.9653 0.336122 5.3013 0.00012207 5.7153 0.00012207C6.1293 0.00012207 6.4653 0.336122 6.4653 0.750122V4.04112C6.4653 4.45512 6.1293 4.79112 5.7153 4.79112Z"
        fill={props.color || "#222222"}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.521 3.07922C2.928 3.07922 1.5 4.46222 1.5 6.97322V16.0222C1.5 18.5882 2.928 20.0002 5.521 20.0002H13.979C16.572 20.0002 18 18.6142 18 16.0982V6.97322C18.004 5.73822 17.672 4.77822 17.013 4.11822C16.335 3.43822 15.29 3.07922 13.988 3.07922H5.521ZM13.979 21.5002H5.521C2.116 21.5002 0 19.4012 0 16.0222V6.97322C0 3.64522 2.116 1.57922 5.521 1.57922H13.988C15.697 1.57922 17.11 2.09122 18.075 3.05822C19.012 3.99922 19.505 5.35222 19.5 6.97522V16.0982C19.5 19.4302 17.384 21.5002 13.979 21.5002Z"
        fill={props.color || "#222222"}
      />
    </Svg>
  );
};
