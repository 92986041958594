import React, { useState } from "react";
import { FontAwesome } from "@expo/vector-icons";
import theme from "../../../theme";
import { OnboardingPage } from "../../../components/OnboardingPage";
import { RootStackParamList } from "../../../routes/auth.routes";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useOnboarding } from "../../../hooks/useOboarding";
import { useAuth } from "../../../hooks/useAuth";
type LoginScreenNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  "PasswordCreateScreen"
>;
type LoginScreenProps = {
  navigation: LoginScreenNavigationProp;
};
export const PasswordCreateScreen: React.FC<LoginScreenProps> = ({
  navigation,
}) => {
  const [password, setPassword] = useState("");
  const { sendPassword } = useOnboarding();
  const { createAccountError, setCreateAccountError, createAccountLoading } = useAuth();
  return (
    <OnboardingPage
      title="Crie uma senha"
      caption="Agora vamos criar uma senha bem segura para você acessar o app."
      headerButtonAction={() => {
        setCreateAccountError("");
        navigation.goBack();
      }}
      headerButtonIcon={
        <FontAwesome
          name="chevron-left"
          size={24}
          color={theme.palette.secondaryOrange}
        />
      }
      buttonAction={() =>
        sendPassword(password, () =>
          navigation.navigate({
            name: "PasswordCreateScreen",
            key: "PasswordCreateScreen",
          })
        )
      }
      textInputProps={{
        keyboardType: "visible-password",
        secureTextEntry: true,
      }}
      disabled={password.length < 8}
      buttonText="Criar conta"
      value={password}
      onChangeText={(text) => setPassword(text)}
      alertText={createAccountError}
      loading={createAccountLoading}
    />
  );
};
