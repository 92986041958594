import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { useAuth } from "../hooks/useAuth";
import theme from "../theme";
import {
  ConfigIcon,
  DogIcon,
  LogoutIcon,
  UserIcon,
  WalletIcon,
  HelpIcon,
} from "../assets/icons";
import { DrawerContentComponentProps } from "@react-navigation/drawer";

const UserProfileDrawer: React.FC<DrawerContentComponentProps> = ({
  navigation,
}) => {
  const { logOut } = useAuth();
  return (
    <LinearGradient
      colors={[theme.palette.primaryOrange, theme.palette.secondaryOrange]}
      style={styles.container}
    >
      <View style={styles.menu}>
        <TouchableOpacity
          style={styles.menuItem}
          onPress={() => {
            navigation.navigate("EditProfile");
          }}
        >
          <UserIcon size={23} color={theme.palette.littleOrange} />
          <Text style={styles.menuItemText}>Meu perfil</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.menuItem}
          onPress={() => {
            navigation.navigate("MyPetScreen");
          }}
        >
          <DogIcon size={23} color={theme.palette.littleOrange} />
          <Text style={styles.menuItemText}>Meu pet</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.menuItem}>
          <WalletIcon size={23} color={theme.palette.littleOrange} />
          <Text style={styles.menuItemText}>Carteira</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.menuItem}>
          <ConfigIcon size={23} color={theme.palette.littleOrange} />
          <Text style={styles.menuItemText}>Configurações</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.menuItem}>
          <HelpIcon size={23} color={theme.palette.littleOrange} />
          <Text style={styles.menuItemText}>Ajuda</Text>
        </TouchableOpacity>
        <View style={styles.bottomContainer}>
          <TouchableOpacity style={styles.logoutButton} onPress={logOut}>
            <LogoutIcon size={20} color="#fff" />
            <Text style={styles.logoutButtonText}>Sair</Text>
          </TouchableOpacity>
          <Text style={styles.vButton}>Versão 0.0.1</Text>
        </View>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.palette.primaryOrange,
    paddingTop: 50,
    paddingHorizontal: 20,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 50,
  },
  profileImage: {
    backgroundColor: "#fff",
    borderRadius: 35,
    width: 60,
    height: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  userInfo: {
    marginLeft: 20,
  },
  userName: {
    fontWeight: "bold",
    fontSize: 18,
    color: "#fff",
    marginBottom: 5,
  },
  userCpf: {
    fontSize: 16,
    color: "#fff",
  },
  menu: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  menuItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  menuItemText: {
    color: "#fff",
    marginLeft: 20,
    fontSize: 18,
  },
  logoutButton: {
    flexDirection: "row",
    alignItems: "center",
  },
  bottomContainer: {
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    bottom: 30,
    left: 20,
  },
  logoutButtonText: {
    marginLeft: 10,
    fontWeight: "bold",
    color: "#fff",
    fontSize: 18,
  },
  vButton: {
    marginLeft: 50,
    color: "#fff",
    fontSize: 18,
    opacity: 0.5,
  },
});

export default UserProfileDrawer;
