import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  Image,
} from "react-native";
import { CartAddIcon } from "../assets/icons";
import theme from "../theme";
const CARD_WIDTH = (Dimensions.get("window").width - 10 * 3) / 2;

interface Props {
  title: string;
  price: string;
  onClick?: () => void;
  image: string | undefined;
  logo: string | undefined;
}

export const ProductCard: React.FC<Props> = ({
  title,
  price,
  onClick,
  image,
  logo,
}) => {
  return (
    <TouchableOpacity onPress={onClick} style={stylesCard.card}>
      <View style={stylesCard.photoCardContainer}>
        <Image
          source={{
            uri: image,
          }}
          style={{
            width: "100%",
            height: "100%",
            resizeMode: "cover",
          }}
        />
      </View>
      <View style={stylesCard.infoCard}>
        <View style={stylesCard.logo}>
          <Image
            source={{
              uri: logo,
            }}
            style={{
              width: "100%",
              height: "100%",
              resizeMode: "cover",
            }}
          />
        </View>
        <Text style={stylesCard.title}>{title}</Text>
      </View>
      <View style={stylesCard.priceContainer}>
        <Text>{price}</Text>
        <View>
          <CartAddIcon size={31} />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const stylesCard = StyleSheet.create({
  container: {
    flex: 1,
  },
  logo: {
    width: 40,
    height: 40,
    borderRadius: 10,
    backgroundColor: theme.palette.gray3,
    overflow: "hidden",
  },
  card: {
    width: CARD_WIDTH,
    height: 270,
    borderRadius: 10,
    backgroundColor: "white",
    borderColor: theme.palette.gray3,
    borderWidth: 1,
    overflow: "hidden",
  },
  infoCard: {
    padding: 10,
    gap: 10,
    flexDirection: "row",
    width: CARD_WIDTH,
  },
  photoCardContainer: {
    width: "100%",
    height: 160,
    borderTopRadius: 10,
    backgroundColor: theme.palette.gray3,
  },
  title: {
    fontSize: 13,
    color: theme.palette.black,
    fontWeight: "bold",
    fontFamily: theme.fonts.nunito[600],
    width: CARD_WIDTH - 70,
  },
  priceContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 22,
    paddingVertical: 12,
    borderTopColor: theme.palette.gray3,
    borderTopWidth: 1,
    marginTop: "auto",
  },
  priceText: {
    fontSize: 13,
    fontFamily: theme.fonts.nunito[400],
    color: theme.palette.black,
  },
});
