import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from "react-native";
import Constants from "expo-constants";
import { Feather } from "@expo/vector-icons";
import theme from "../theme";

interface Props {
  title?: string;
  buttonAction?: () => void;
}

export const ScreenFlowHeader: React.FC<Props> = ({
  title,
  buttonAction,
}: Props) => {
  return (
    <View style={styles.header}>
      <TouchableOpacity
        style={styles.backButton}
        onPress={() => buttonAction && buttonAction()}
      >
        <Feather
          name="chevron-left"
          size={24}
          color={theme.palette.secondaryOrange}
        />
      </TouchableOpacity>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.balance}></View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 14,
    paddingHorizontal: 10,
    paddingTop: 14,
    // backgroundColor: theme.palette.littleOrange,
    ...Platform.select({
      android: {
        paddingTop: Constants.statusBarHeight + 10,
      },
    }),
    justifyContent: "space-between",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.black,
    fontFamily: theme.fonts.nunito["700"],
    alignSelf: "center",
  },
  backButton: {
    width: 24,
    height: 24,
  },
  balance: {
    width: 24,
    height: 24,
  },
});
